import axios from "axios";
import dictionary from "../dictionary";

const ACCESS_TOKEN = localStorage.getItem("projectm@user");
const apiHost = dictionary.apiHost;

const WHITELIST = [
  "login",
  // ,"/signup", "/forgot-password"
];

const apiCall = async (endpoint, data) => {
  const isWhitelisted = WHITELIST.includes(endpoint);
  // If not whitelisted and no access token is available, do nothing (no request, no error)
  if (!isWhitelisted && !ACCESS_TOKEN) {
    let connError = {
      status: "conn",
      message: "Unauthorized access! Please log in.",
      details: {
        message: "Login required to access this resource.",
        content: false,
      },
    };
    return connError;
  }

  try {
    let response = await axios.post(apiHost + endpoint, data, {
      headers: {
        Authorization: ACCESS_TOKEN ? "Bearer " + ACCESS_TOKEN : undefined,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    let connError = {
      status: "conn",
      message: "Server connection error occurred!",
      details: { message: "Contacting server....", content: false },
    };
    return connError;
  }
};

export default apiCall;
