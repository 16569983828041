import React, { useEffect, useState, useContext } from "react";
import ajaxProject from "../../util/remote/ajaxProject";
import Loader from "../../Components/Common/Loader";
import toast, { Toaster } from "react-hot-toast";
import { Link, useParams } from "react-router-dom";
//new
import ajaxTeam from "../../util/remote/ajaxTeam";
import Select from "react-select";
import UserContext from "../../Context/UserContext";

function Hardware(props) {
  const [status, setStatus] = useState("");
  const [installationDate, setInstallationDate] = useState("");
  const [crossa, setCross] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pole, setPole] = useState(false);
  /////new
  const [teamList, setTeamList] = useState(false);
  const [teamId, setTeamId] = useState("");
  const { userList } = useContext(UserContext);
  const [user, setUser] = useState("");

  const { id, cross } = useParams();

  const handleCrossDetails = async (e) => {
    e.preventDefault();
    if (id > 0 && status.length > 0) {
      if (status === "INSTALLED" && installationDate.length < 1) {
        toast.error("Select the installation date to proceed!");
      } else {
        setLoading(true);
        const server_response = await ajaxProject.updateHardwareStatus(
          cross,
          status,
          installationDate,
          teamId,
          user
        );
        setLoading(false);

        if (server_response.status === "OK") {
          toast.success(server_response.message);
          setStatus("");
          setInstallationDate("");

          props.navi(`/installations/create/${id}`);
        }
      }
    } else {
      toast.error("Please fill in all required fields!");
    }
  };

  ///new
  useEffect(() => {
    getTeams();
  }, []);

  const getTeams = async () => {
    const server_response = await ajaxTeam.getTeamList();

    if (server_response.status === "OK") {
      setTeamList(server_response.details);
    }
  };

  useEffect(() => {
    getPole();
  }, [id]);

  const getPole = async () => {
    setLoading(true);
    const server_response = await ajaxProject.getPoleInfo(id);
    setLoading(false);

    if (server_response.status === "OK") {
      setPole(server_response.details);
    } else {
      setPole(false);
    }
  };

  useEffect(() => {
    getPoleHardware();
  }, [pole]);

  const getPoleHardware = async () => {
    if (pole) {
      setLoading(true);
      const server_response = await ajaxProject.getPoleHardware(pole.pole_id);
      setLoading(false);

      if (server_response.status === "OK") {
        let crossa = server_response.details.filter(function (crossing) {
          return crossing.id == cross;
        });
        crossa = crossa[0];
        setCross(crossa);
        setStatus(crossa.final_hardware_used);
        setInstallationDate(crossa.install_date);
      } else {
        setCross(false);
      }
    }
  };

  return (
    <form onSubmit={(e) => handleCrossDetails(e)}>
      <Toaster />
      {loading && <Loader />}
      {crossa && (
        <>
          <br />
          <Link
            onClick={() => props.navi(-1)}
            className="btn btn-primary btn-sm mb-2">
            Back To Previous Page
          </Link>
          <h6>
            <small className="text-muted">Estimated Hardware : </small>
            {crossa.estimated_hardware}
          </h6>
          <br />
          <div className="form-group mt-3">
            <div className="mb-4 col-md-12">
              <label>
                <b>Final Used</b>
              </label>
              <input
                type="number"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                placeholder="final hardware used"
                className="form-control"
              />
            </div>

            <div className="mb-4 col-md-12">
              <label>
                <b>Install Date</b>
              </label>
              <input
                type="date"
                value={installationDate}
                onChange={(e) => setInstallationDate(e.target.value)}
                className="form-control"
              />
            </div>
            {/* ////new */}
            <div className="mb-4 col-md-12">
              <label htmlFor="">
                <b>Installed By:</b>
              </label>
              <Select
                onChange={(e) => setTeamId(e.team_id)}
                getOptionLabel={(option) => option.team_name}
                getOptionValue={(option) => option.team_id}
                isSearchable
                options={Array.isArray(teamList) ? teamList : []}
                value={
                  Array.isArray(teamList) &&
                  teamList.find((value) => value.team_id === teamId)
                }
              />
            </div>
            <div className="mb-4 col-md-12">
              <label htmlFor="">
                <b>Approved By:</b>
              </label>
              <Select
                onChange={(e) => setUser(e.id)}
                getOptionLabel={(option) =>
                  `${option.first_name} ${option.last_name}`
                }
                getOptionValue={(option) =>
                  `${option.first_name} ${option.last_name}`
                }
                isSearchable
                options={userList}
                value={
                  Array.isArray(userList) &&
                  userList.find((value) => value.id === user)
                }
              />
            </div>
          </div>
          <div className="mt-2 mb-2">
            <button type="submit" className="btn btn-success w-100">
              Update
            </button>
          </div>
        </>
      )}
    </form>
  );
}

export default Hardware;
