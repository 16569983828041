import { useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";

const BudgetExpenditureCatUpdater = (props) => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [data, setData] = useState(props.item);
  const [cost_allocations, setCost_allocations] = useState(
    props.cost_allocations
  );

  const [cost_item, setCost_item] = useState("");
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setDate(today);
  }, []);

  const handleAdd = async (e, controls) => {
    e.preventDefault();
    if (
      cost_item.trim() === "" ||
      (data && data.cat_balance && data.cat_balance.cat_balance < amount)
    ) {
      toast.error("Please complete the form or ensure balance is sufficient.");
    } else {
      setLoading(true);

      var data = {
        transID: props.expense,
        cost_item: cost_item,
        amount: amount,
        description: description,
        date: date,
      };
      const server_response =
        await ajaxAccounting.setBudegetExpenditure_costAllocation(data);

      setLoading(false);
      if (server_response.status === "OK") {
        controls.close();
        toast.success(server_response.message);
        props.function();
      } else {
        toast.error(server_response.message);
      }
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            disabled={loading}
            className={`btn ripple btn-success`}
            onClick={(e) => handleAdd(e, controls)}>
            Save changes
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Update budgetExpense cat"
      id="model-new-ledger"
      size="lg"
      footer={RenderFooter}>
      {" "}
      <h6 className="text-center ">
        {" "}
        <span>
          Add CAC For Budget Account:{" "}
          <u>
            <span style={{ color: "red" }}>{data && data.ledger_name}</span>
          </u>
        </span>
      </h6>
      <div className="mb-4 col-md-12">
        <ul
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            borderBottom: "dotted 1px grey",
            backgroundColor: "transparent",
          }}>
          <li>
            <span>
              Total Budget: Ugx <u>{data && data.amount.amount_c}</u>
            </span>
          </li>
          <li>
            <span>
              Total CAC : Ugx <u>{data && data.amount_used.amount_used_c}</u>
            </span>
          </li>
          <li>
            <span>
              Un_allocated : Ugx <u>{data && data.cat_balance.cat_balance_c}</u>
            </span>
          </li>
        </ul>
      </div>
      <div className="mb-4 col-md-12">
        <label htmlFor="">
          select cost allocation item:<span className="tx-danger">*</span>
        </label>
        <Select
          onChange={(e) => setCost_item(e.id)}
          getOptionLabel={(option) => option.item}
          getOptionValue={(option) => option.id}
          isSearchable
          options={Array.isArray(cost_allocations) ? cost_allocations : [""]}
          value={
            Array.isArray(cost_allocations) &&
            cost_allocations.find((value) => value.id === cost_item)
          }
        />
      </div>{" "}
      <div className="mb-4 col-md-12">
        <label htmlFor="">
          Amount
          <span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="text"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4 col-md-12">
        <label htmlFor="">
          Date
          <span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4 col-md-12">
        <label htmlFor="">
          Description
          <span style={{ color: "red" }}>*</span>
        </label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="form-control"></textarea>
      </div>
    </SystemModal>
  );
};

export default BudgetExpenditureCatUpdater;
