import React, { useContext, useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import Select from "react-select";
import AuthContext from "../../Context/AuthContext";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import ajaxProject from "../../util/remote/ajaxProject";
import ajaxClient from "../../util/remote/ajaxClient";

function InvoiceCreate(props) {
  const { userId } = useContext(AuthContext);
  const [projectId, setProjectId] = useState("");
  const [lopno, setLpoNo] = useState("");
  const [refno, setRefNo] = useState("");
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([
    { item: "", quantity: "", rate: "", unit: "", amount: "" },
  ]);
  const [clientID, setClientID] = useState("");
  const [date, setDate] = useState("");
  const [invoicables, setInvoicables] = useState([]);
  const [projects, setProjects] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [duplicateError, setDuplicateError] = useState("");

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setDate(today);
    getInvoicables();
    getProjects();
  }, []);

  const [clients, setClients] = useState("");
  const getClients = async () => {
    const server_response = await ajaxAccounting.getClientLedgers();
    if (server_response.status === "OK") {
      setClients(server_response.details);
    } else {
      setClients("404");
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  const calculateTotalAmount = () => {
    return rows.reduce(
      (total, row) => total + (parseFloat(row.amount) || 0),
      0
    );
  };

  const allRowsFilled = () => {
    return rows.every((row) => row.item && row.quantity > 0 && row.rate > 0);
  };

  const handleInvoiceAddition = async (e) => {
    e.preventDefault();
    if (userId > 0 && date && projectId && rows.length > 0 && allRowsFilled()) {
      setLoading(true);
      const outputData = rows.map(({ item, quantity, rate, amount }) => ({
        LedgerID_Debited: item,
        quantity: quantity,
        rate: rate,
        amount: amount,
      }));
      const data = {
        project_id: projectId,
        LedgerID_credited: clientID,
        ref_no: refno,
        lpo_no: lopno,
        date_added: date,
        details: outputData,
        added_by: userId,
      };

      const server_response = await ajaxAccounting.createInvoice(data);
      setLoading(false);

      if (server_response.status === "OK") {
        toast.success(server_response.message);
        resetForm();
        props.function();
        // Optionally reset the form here
      } else {
        toast.error(server_response.message);
      }
    } else {
      setErrorMessage("Complete all fields required.");
      toast.error("Complete all fields and try again");
    }
  };

  const getInvoicables = async () => {
    const server_response = await ajaxAccounting.getAllSalesInvoices();
    if (server_response.status === "OK") {
      setInvoicables(server_response.details);
    } else {
      toast.error(server_response.message);
    }
  };

  const getProjects = async () => {
    const server_response = await ajaxProject.getProjectsMinnimalDataCliented();
    if (server_response.status === "OK") {
      setProjects(server_response.details);
    }
  };

  const handleAddRow = () => {
    const lastRow = rows[rows.length - 1];
    if (!lastRow.item || !lastRow.quantity || !lastRow.rate) {
      toast.error("Please complete the current row before adding a new one.");
      return;
    }
    setRows([
      ...rows,
      { item: "", quantity: "", rate: "", unit: "", amount: "" },
    ]);
    setErrorMessage("");
    setDuplicateError("");
  };

  const handleDeleteRow = (index) => {
    const updatedRows = rows.filter((_, idx) => idx !== index);
    setRows(
      updatedRows.length === 0
        ? [{ item: "", quantity: "", rate: "", unit: "", amount: "" }]
        : updatedRows
    );
    setErrorMessage("");
    setDuplicateError("");
  };

  const handleItemChange = (index, selectedInvoicable) => {
    const updatedRows = [...rows];

    if (
      updatedRows.some(
        (row, idx) => idx !== index && row.item === selectedInvoicable.id
      )
    ) {
      setDuplicateError(
        "This item has already been entered in a previous row."
      );
      toast.error("This item has already been entered in a previous row.");
      return;
    }

    const selectedItem = invoicables.find(
      (invoicable) => invoicable.id === selectedInvoicable.id
    );
    updatedRows[index] = {
      ...updatedRows[index],
      item: selectedItem.id,
      unit: selectedItem.unit,
      rate: selectedItem.rate,
      amount: updatedRows[index].quantity * selectedItem.rate,
    };
    setRows(updatedRows);
    setErrorMessage("");
    setDuplicateError("");
  };

  const handleQuantityChange = (index, quantity) => {
    const updatedRows = [...rows];
    updatedRows[index].quantity = quantity;
    updatedRows[index].amount = quantity * updatedRows[index].rate;
    setRows(updatedRows);
    setErrorMessage("");
    setDuplicateError("");
  };

  const handleRateChange = (index, rate) => {
    const updatedRows = [...rows];
    updatedRows[index].rate = rate;
    updatedRows[index].amount = updatedRows[index].quantity * rate;
    setRows(updatedRows);
    setErrorMessage("");
    setDuplicateError("");
  };
  const resetForm = () => {
    setProjectId("");
    setLpoNo("");
    setRefNo("");
    setDate(new Date().toISOString().split("T")[0]);
    // setRows([{ item: "", quantity: "", rate: "", unit: "", amount: "" }]);

    setRows([{ item: null, quantity: "", rate: "", unit: "", amount: "" }]); // Reset 'item' to null

    setErrorMessage("");
    setDuplicateError("");
  };
  return (
    <>
      <Toaster />
      <div className="card custom-card">
        <div className="card-body">
          <h6 className="card-title mb-4">Record Invoice</h6>
          <form onSubmit={handleInvoiceAddition}>
            <div className="row">
              <div className="form-group col-md-6 col-lg-6 mt-1">
                <b>Select Client to Credit:</b>
                <br />
                <Select
                  onChange={(e) => setClientID(e.id)}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  options={clients}
                  value={
                    clientID
                      ? Array.isArray(clients) &&
                        clients.find((value) => value.id === clientID)
                      : ""
                  }
                />
              </div>
              <div className="mb-4 col-md-6">
                <label>
                  Select Project<span style={{ color: "red" }}>*</span>
                </label>
                <Select
                  onChange={(e) => setProjectId(e.id)}
                  getOptionLabel={(option) => option.project_name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  options={projects}
                  value={
                    projectId
                      ? projects.find((value) => value.id === projectId)
                      : ""
                  }
                />
              </div>
              <div className="mb-4 col-md-4">
                <label>
                  Ref NO:<span className="tx-danger">*</span>
                </label>
                <input
                  type="text"
                  value={refno}
                  onChange={(e) => setRefNo(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-4">
                <label>
                  LPO NO:<span className="tx-danger">*</span>
                </label>
                <input
                  type="text"
                  value={lopno}
                  onChange={(e) => setLpoNo(e.target.value)}
                  className="form-control"
                />
              </div>
              <div className="mb-4 col-md-4">
                <label>
                  Date:<span className="tx-danger">*</span>
                </label>
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  className="form-control "
                />
              </div>

              <div className="mb-4 col-md-12">
                <hr className="mg-b-40" />
                <table className="table table-hover text-nowrap mg-b-0">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th className="wd-25p">Invoice Ledger</th>
                      <th className="wd-15p">Unit</th>
                      <th className="wd-15p">Rate</th>
                      <th className="wd-15p">Quantity</th>
                      <th className="wd-15p">Amount</th>
                      <th>Operations</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((row, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Select
                            onChange={(e) => handleItemChange(index, e)}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            isSearchable
                            options={
                              Array.isArray(invoicables) ? invoicables : []
                            }
                            value={
                              row.item
                                ? Array.isArray(invoicables) &&
                                  invoicables.find(
                                    (value) => value.id === row.item
                                  )
                                : ""
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="form-control mb-4 col-md-12"
                            type="text"
                            value={row.unit}
                            readOnly
                          />
                        </td>
                        <td>
                          <input
                            className="form-control mb-4 col-md-12"
                            type="number"
                            value={row.rate}
                            onChange={(e) =>
                              handleRateChange(index, e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="form-control mb-4 col-md-12"
                            type="number"
                            min={1}
                            value={row.quantity}
                            onChange={(e) =>
                              handleQuantityChange(index, e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="form-control mb-4 col-md-12"
                            type="number"
                            value={row.amount}
                            readOnly
                          />
                        </td>
                        <td>
                          <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={() => handleDeleteRow(index)}
                            type="button">
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <th colSpan="5" className="text-end">
                        Total (no V.A.T):
                      </th>
                      <td>
                        <input
                          className="form-control"
                          type="number"
                          value={calculateTotalAmount()}
                          readOnly
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4">
                        <button
                          type="button"
                          onClick={handleAddRow}
                          className="btn btn-primary btn-sm mr-2"
                          disabled={loading}>
                          Add Row
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="mb-4 col-md-12">
              <button
                type="submit"
                style={{ width: "100%" }}
                className="btn btn-success"
                disabled={!allRowsFilled() || loading}>
                {loading ? "Adding..." : "Record Invoice"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default InvoiceCreate;
