import React, { useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import { Toaster, toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { useEffect } from "react";
import ajaxUser from "../../util/remote/ajaxUser";
import ajaxDailyOutput from "../../util/remote/ajaxDailyOutput";
import ajaxTask from "../../util/remote/ajaxTask";

function AddHSEReport() {
  const [location, setLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const [reportDate, setReportDate] = useState("");
  const [teamsUnderstand, setTeamsUnderstand] = useState("");
  const [teamsTrained, setTeamsTrained] = useState("");
  const [toolsCondition, setToolsCondition] = useState("");
  const [toolsPhoto, setToolsPhoto] = useState("");
  const [teamsDoc, setTeamsDoc] = useState("");
  const [otherParties, setOtherParties] = useState("");
  const [rightPpe, setRightPpe] = useState("");
  const [ppePhoto, setPpePhoto] = useState("");
  const [hazards, setHazards] = useState("");
  const [hazardsRemoved, setHazardsRemoved] = useState("");
  const [staffAssigned, setStaffAssigned] = useState("");
  const [staffList, setStaffList] = useState("");
  const [assessedBy, setAssessedBy] = useState("");
  const [designation, setDesignation] = useState("");
  const [hseList, setHseList] = useState(false);
  const [allocationInfo, setAllocationInfo] = useState(false);
  const { id } = useParams();

  const navigator = useNavigate();

  const getHses = async () => {
    const server_response = await ajaxUser.fetchUserList();
    if (server_response.status === "OK") {
      setHseList(server_response.details);
    }
  };

  const getAllocationInfo = async () => {
    const server_response = await ajaxTask.fetchTaskAllocationInfo(id);

    if (server_response.status === "OK") {
      setAllocationInfo(server_response.details);
    }
  };

  const changeToolsPhoto = (e) => {
    e.preventDefault();

    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const newItem = { file: e.target.result };

      setToolsPhoto(e.target.result);
    };
  };

  const changePPEPhoto = (e) => {
    e.preventDefault();

    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const newItem = { file: e.target.result };

      setPpePhoto(e.target.result);
    };
  };

  const handleAddReport = async (e) => {
    e.preventDefault();
    if (assessedBy > 0 && reportDate.length > 0) {
      const server_response = await ajaxDailyOutput.addHSEReport(
        id,
        location,
        reportDate,
        teamsUnderstand,
        teamsTrained,
        toolsCondition,
        toolsPhoto,
        teamsDoc,
        otherParties,
        rightPpe,
        ppePhoto,
        hazards,
        hazardsRemoved,
        staffAssigned,
        staffList,
        assessedBy,
        designation
      );

      setLoading(true);
      if (server_response.status === "OK") {
        toast.success(server_response.message);

        setTimeout(() => {
          setLoading(false);
        }, 500);
        setTimeout(() => {
          navigator(-1);
        }, 1500);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  useEffect(() => {
    getHses();
  }, []);

  useEffect(() => {
    getAllocationInfo(id);
  }, [id]);

  return (
    <AppContainer title="Add HSE Report">
      <Toaster />

      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="card custom-card">
            <div class="card-body">
              <div
                className="table-heade align-items-center"
                style={{ textAlign: "center" }}>
                <div>
                  <h5 className="card-title mb-3">RISK ASSESSMENT FORM </h5>
                </div>
              </div>

              <div class="row">
                {allocationInfo && (
                  <div class="col-lg-6">
                    <div class="row row-xs align-items-center mg-b-20">
                      <div class="col-md-3">
                        <label class="mg-b-0">Project Name:</label>
                      </div>
                      <div class="col-md-6 mg-t-5 mg-md-t-0">
                        <input
                          type="text"
                          value={allocationInfo.task?.project?.project_name}
                          readOnly
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div class="row row-xs align-items-center mg-b-20">
                      <div class="col-md-3">
                        <label class="mg-b-0">Task Assessed:</label>
                      </div>
                      <div class="col-md-6 mg-t-5 mg-md-t-0">
                        <input
                          type="text"
                          value={allocationInfo.task?.task_name}
                          readOnly
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div class="row row-xs align-items-center mg-b-20">
                      <div class="col-md-3">
                        <label class="mg-b-0">Location:</label>
                      </div>
                      <div class="col-md-6 mg-t-5 mg-md-t-0">
                        <input
                          type="text"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                          placeholder="Enter location of the project"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                )}
                {allocationInfo && (
                  <div class="col-lg-6">
                    <div class="row row-xs align-items-center mg-b-20">
                      <div class="col-md-3">
                        <label class="mg-b-0">Site Name:</label>
                      </div>
                      <div class="col-md-6 mg-t-5 mg-md-t-0">
                        <input
                          type="text"
                          value={allocationInfo.site}
                          readOnly
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div class="row row-xs align-items-center mg-b-20">
                      <div class="col-md-3">
                        <label class="mg-b-0">Task Date:</label>
                      </div>
                      <div class="col-md-6 mg-t-5 mg-md-t-0">
                        <input
                          type="text"
                          value={allocationInfo.task_date}
                          readOnly
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div class="row row-xs align-items-center mg-b-20">
                      <div class="col-md-3">
                        <label class="mg-b-0">Assessment Date:</label>
                      </div>
                      <div class="col-md-6 mg-t-5 mg-md-t-0">
                        <input
                          type="date"
                          value={reportDate}
                          onChange={(e) => setReportDate(e.target.value)}
                          placeholder="Enter the date"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <hr class="mg-b-20" />
              <p class="mb-1">
                <span class="font-weight-bold">
                  ATTENTION! FOR EACH QUESTION, SELECT THE CIRCLE WITH YOUR
                  APPROPRIATE ANSWER
                </span>
              </p>

              <p class="mt-1">
                <span class="font-weight-bold">1. </span> Do the teams clearly
                understand what is required?
              </p>
              <div class="row mg-t-10">
                <div class="col-lg-4">
                  <label class="rdiobox">
                    <input
                      name="teams_understand"
                      type="radio"
                      value="Yes"
                      onChange={(e) => setTeamsUnderstand(e.target.value)}
                      checked={teamsUnderstand === "Yes"}
                    />
                    <span>Yes</span>
                  </label>
                </div>
                <div class="col-lg-4 mg-t-20 mg-lg-t-0">
                  <label class="rdiobox">
                    <input
                      name="teams_understand"
                      type="radio"
                      value="No"
                      onChange={(e) => setTeamsUnderstand(e.target.value)}
                      checked={teamsUnderstand === "No"}
                    />{" "}
                    <span>No</span>
                  </label>
                </div>
                <div class="col-lg-4 mg-t-20 mg-lg-t-0">
                  <label class="rdiobox">
                    <input
                      name="teams_understand"
                      type="radio"
                      value="N/A"
                      onChange={(e) => setTeamsUnderstand(e.target.value)}
                      checked={teamsUnderstand === "N/A"}
                    />{" "}
                    <span>N/A</span>
                  </label>
                </div>
              </div>
              <p class="mt-1">
                <span class="font-weight-bold">2. </span> Are the teams trained
                to do the work and familiar with the equipment, tools and tasks
                assigned?
              </p>
              <div class="row mg-t-10">
                <div class="col-lg-4">
                  <label class="rdiobox">
                    <input
                      name="teams_trained"
                      type="radio"
                      value="Yes"
                      onChange={(e) => setTeamsTrained(e.target.value)}
                      checked={teamsTrained === "Yes"}
                    />{" "}
                    <span>Yes</span>
                  </label>
                </div>
                <div class="col-lg-4 mg-t-20 mg-lg-t-0">
                  <label class="rdiobox">
                    <input
                      name="teams_trained"
                      type="radio"
                      value="No"
                      onChange={(e) => setTeamsTrained(e.target.value)}
                      checked={teamsTrained === "No"}
                    />{" "}
                    <span>No</span>
                  </label>
                </div>
                <div class="col-lg-4 mg-t-20 mg-lg-t-0">
                  <label class="rdiobox">
                    <input
                      name="teams_trained"
                      type="radio"
                      value="N/A"
                      onChange={(e) => setTeamsTrained(e.target.value)}
                      checked={teamsTrained === "N/A"}
                    />{" "}
                    <span>N/A</span>
                  </label>
                </div>
              </div>
              <p class="mt-1">
                <span class="font-weight-bold">3. </span> Are the tools and
                equipment in a good and safe condition?
              </p>
              <div class="row mg-t-10">
                <div class="col-lg-4">
                  <label class="rdiobox">
                    <input
                      name="tools_condition"
                      type="radio"
                      value="Yes"
                      onChange={(e) => setToolsCondition(e.target.value)}
                      checked={toolsCondition === "Yes"}
                    />{" "}
                    <span>Yes</span>
                  </label>
                </div>
                <div class="col-lg-4 mg-t-20 mg-lg-t-0">
                  <label class="rdiobox">
                    <input
                      name="tools_condition"
                      type="radio"
                      value="No"
                      onChange={(e) => setToolsCondition(e.target.value)}
                      checked={toolsCondition === "No"}
                    />{" "}
                    <span>No</span>
                  </label>
                </div>
                <div class="col-lg-4 mg-t-20 mg-lg-t-0">
                  <label class="rdiobox">
                    <input
                      name="tools_condition"
                      type="radio"
                      value="N/A"
                      onChange={(e) => setToolsCondition(e.target.value)}
                      checked={toolsCondition === "N/A"}
                    />{" "}
                    <span>N/A</span>
                  </label>
                </div>
              </div>
              <p class="mt-1">Provide a photo of the tools and equipment</p>
              <input
                type="file"
                onChange={(e) => changeToolsPhoto(e)}
                className="form-control"
              />
              <p class="mt-1">
                <span class="font-weight-bold">4. </span> Do the teams have
                approved documentation or instructions for the tasks assigned?{" "}
              </p>
              <div class="row mg-t-10">
                <div class="col-lg-4">
                  <label class="rdiobox">
                    <input
                      name="teams_doc"
                      type="radio"
                      value="Yes"
                      onChange={(e) => setTeamsDoc(e.target.value)}
                      checked={teamsDoc === "Yes"}
                    />{" "}
                    <span>Yes</span>
                  </label>
                </div>
                <div class="col-lg-4 mg-t-20 mg-lg-t-0">
                  <label class="rdiobox">
                    <input
                      name="teams_doc"
                      type="radio"
                      value="No"
                      onChange={(e) => setTeamsDoc(e.target.value)}
                      checked={teamsDoc === "No"}
                    />{" "}
                    <span>No</span>
                  </label>
                </div>
                <div class="col-lg-4 mg-t-20 mg-lg-t-0">
                  <label class="rdiobox">
                    <input
                      name="teams_doc"
                      type="radio"
                      value="N/A"
                      onChange={(e) => setTeamsDoc(e.target.value)}
                      checked={teamsDoc === "N/A"}
                    />{" "}
                    <span>N/A</span>
                  </label>
                </div>
              </div>
              <p class="mt-1">
                <span class="font-weight-bold">5. </span> Are the other parties
                likely to be affected by the works informed?{" "}
              </p>
              <div class="row mg-t-10">
                <div class="col-lg-4">
                  <label class="rdiobox">
                    <input
                      name="other_parties"
                      type="radio"
                      value="Yes"
                      onChange={(e) => setOtherParties(e.target.value)}
                      checked={otherParties === "Yes"}
                    />{" "}
                    <span>Yes</span>
                  </label>
                </div>
                <div class="col-lg-4 mg-t-20 mg-lg-t-0">
                  <label class="rdiobox">
                    <input
                      name="other_parties"
                      type="radio"
                      value="No"
                      onChange={(e) => setOtherParties(e.target.value)}
                      checked={otherParties === "No"}
                    />{" "}
                    <span>No</span>
                  </label>
                </div>
                <div class="col-lg-4 mg-t-20 mg-lg-t-0">
                  <label class="rdiobox">
                    <input
                      name="other_parties"
                      type="radio"
                      value="N/A"
                      onChange={(e) => setOtherParties(e.target.value)}
                      checked={otherParties === "N/A"}
                    />{" "}
                    <span>N/A</span>
                  </label>
                </div>
              </div>
              <p class="mt-1">
                <span class="font-weight-bold">6. </span> Do the teams have the
                right PPE for the works ongoing?{" "}
              </p>
              <div class="row mg-t-10">
                <div class="col-lg-4">
                  <label class="rdiobox">
                    <input
                      name="right_ppe"
                      type="radio"
                      value="Yes"
                      onChange={(e) => setRightPpe(e.target.value)}
                      checked={rightPpe === "Yes"}
                    />{" "}
                    <span>Yes</span>
                  </label>
                </div>
                <div class="col-lg-4 mg-t-20 mg-lg-t-0">
                  <label class="rdiobox">
                    <input
                      name="right_ppe"
                      type="radio"
                      value="No"
                      onChange={(e) => setRightPpe(e.target.value)}
                      checked={rightPpe === "No"}
                    />{" "}
                    <span>No</span>
                  </label>
                </div>
                <div class="col-lg-4 mg-t-20 mg-lg-t-0">
                  <label class="rdiobox">
                    <input
                      name="right_ppe"
                      type="radio"
                      value="N/A"
                      onChange={(e) => setRightPpe(e.target.value)}
                      checked={rightPpe === "N/A"}
                    />{" "}
                    <span>N/A</span>
                  </label>
                </div>
              </div>
              <p class="mt-1">Provide a photo for the available PPE</p>
              <input
                type="file"
                onChange={(e) => changePPEPhoto(e)}
                className="form-control"
              />
              <p class="mt-4">
                <span class="font-weight-bold">
                  RISKS IDENTIFIED AND HAZARDS ASSESSED
                </span>
              </p>
              <p class="mt-1">
                <span class="font-weight-bold">7. </span> Are there any hazards
                identified within the working area?{" "}
              </p>
              <div class="row mg-t-10">
                <div class="col-lg-4">
                  <label class="rdiobox">
                    <input
                      name="hazards"
                      type="radio"
                      value="Yes"
                      onChange={(e) => setHazards(e.target.value)}
                      checked={hazards === "Yes"}
                    />{" "}
                    <span>Yes</span>
                  </label>
                </div>
                <div class="col-lg-4 mg-t-20 mg-lg-t-0">
                  <label class="rdiobox">
                    <input
                      name="hazards"
                      type="radio"
                      value="No"
                      onChange={(e) => setHazards(e.target.value)}
                      checked={hazards === "No"}
                    />{" "}
                    <span>No</span>
                  </label>
                </div>
              </div>
              <p class="mt-1">
                <span class="font-weight-bold">8. </span> Are all hazards
                identified controlled or removed?{" "}
              </p>
              <div class="row mg-t-10">
                <div class="col-lg-4">
                  <label class="rdiobox">
                    <input
                      name="hazards_identified"
                      type="radio"
                      value="Yes"
                      onChange={(e) => setHazardsRemoved(e.target.value)}
                      checked={hazardsRemoved === "Yes"}
                    />{" "}
                    <span>Yes</span>
                  </label>
                </div>
                <div class="col-lg-4 mg-t-20 mg-lg-t-0">
                  <label class="rdiobox">
                    <input
                      name="hazards_identified"
                      type="radio"
                      value="No"
                      onChange={(e) => setHazardsRemoved(e.target.value)}
                      checked={hazardsRemoved === "No"}
                    />{" "}
                    <span>No</span>
                  </label>
                </div>
              </div>
              <p class="mt-1">
                <span class="font-weight-bold">9. </span> Are all your staff
                assigned on this project inducted into the HSE guidelines?{" "}
              </p>
              <div class="row mg-t-10">
                <div class="col-lg-4">
                  <label class="rdiobox">
                    <input
                      name="staff_assigned"
                      type="radio"
                      value="Yes"
                      onChange={(e) => setStaffAssigned(e.target.value)}
                      checked={staffAssigned === "Yes"}
                    />{" "}
                    <span>Yes</span>
                  </label>
                </div>
                <div class="col-lg-4 mg-t-20 mg-lg-t-0">
                  <label class="rdiobox">
                    <input
                      name="staff_assigned"
                      type="radio"
                      value="No"
                      onChange={(e) => setStaffAssigned(e.target.value)}
                      checked={staffAssigned === "No"}
                    />{" "}
                    <span>No</span>
                  </label>
                </div>
              </div>
              <p class="mt-1">
                Attach the list of names of the staff involved in the
                implementation of the assigned tasks
              </p>
              <textarea
                name="staff_list"
                rows={4}
                cols={50}
                value={staffList}
                onChange={(e) => setStaffList(e.target.value)}
                className="form-control"
              />

              <div class="row">
                <div class="col-lg-6">
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3">
                      <label class="mt-3">Assessed By:</label>
                    </div>
                    <div class="col-md-6 mg-t-5 mg-md-t-9">
                      <Select
                        onChange={(e) => setAssessedBy(e.id)}
                        getOptionLabel={(option) =>
                          `${option.first_name} ${option.last_name}`
                        }
                        getOptionValue={(option) =>
                          `${option.first_name} ${option.last_name}`
                        }
                        isSearchable
                        options={Array.isArray(hseList) ? hseList : []}
                        value={
                          Array.isArray(hseList) &&
                          hseList.find((value) => value.id === assessedBy)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-2">
                      <label class="mt-3">Designation:</label>
                    </div>
                    <div class="col-md-6 mg-t-5 mg-md-t-9">
                      <input
                        type="text"
                        value={designation}
                        onChange={(e) => setDesignation(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer text-right">
              {loading && (
                <button disabled className="btn btn-success btn-sm">
                  Saving...
                </button>
              )}
              {!loading && (
                <button
                  onClick={handleAddReport}
                  className="btn btn-success btn-md">
                  Save HSE Report
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default AddHSEReport;
