import "./App.css";
import "./chat.css";

import React, { useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes as Switch,
  Navigate,
} from "react-router-dom";
import LoginPage from "./Pages/LoginPage";
import DashboardPage from "./Pages/DashboardPage";
import SuperProvider from "./Context/SuperProvider";
import ProjectsPage from "./Pages/ProjectsPage";
import ClientsPage from "./Pages/ClientsPage";
import RegionPage from "./Pages/RegionPage";
import TeamPage from "./Pages/TeamPage";
import Permissions from "./Pages/Permissions";
import ClientProfile from "./Pages/ClientProfile";
import ProjectProfile from "./Pages/ProjectProfile";
import UserProfile from "./Pages/UserProfile";
import RolesPermissions from "./Pages/RolesPermissions";
import Roles from "./Pages/Roles";
import IssueLogs from "./Pages/IssueLogs";
import IssueLogChat from "./Pages/IssueLogChat";
import UsersPage from "./Pages/UsersPage";
import TeamProfile from "./Pages/TeamProfile";
import PermissionGroup from "./Pages/PermissionGroup";
import ProfileUser from "./Pages/ProfileUser";
import SearchBar from "./Components/Common/SearchBar";
import DeliverableProfile from "./Pages/DeliverableProfile";
import Sample from "./Pages/Sample";
import CreateUsers from "./Components/Users/CreateUsers";
import CreateClients from "./Components/Client/CreateClients";
import VisitsPage from "./Pages/VisitsPage";
import AddTask from "./Pages/Tasks/AddTask";
import CreateDailyOutput from "./Pages/DailyOutput/CreateDailyOutput";
import ListDailyOutput from "./Pages/DailyOutput/ListDailyOutput";
import ListProducts from "./Pages/Product/ListProducts";
import CreateProduct from "./Pages/Product/CreateProduct";
import ListInventory from "./Pages/Product/ListInventory";
import CreateInventory from "./Pages/Product/CreateInventory";
import ListPayRate from "./Pages/PayRate/ListPayRate";
import CreatePayRate from "./Pages/PayRate/CreatePayRate";
import InventoryOutput from "./Pages/Product/InventoryOutput";
import CreatePayment from "./Pages/DailyOutput/CreatePayment";
import PaymentsPage from "./Pages/PaymentsPage";
import ProjectPoles from "./Pages/ProjectPoles";
import ListPolesProducts from "./Pages/ProductTracker/ListPolesProducts";
import Tasks from "./Pages/Tasks/Tasks";
import Installations from "./Pages/Installations/Installations";
import AddInstallations from "./Pages/Installations/AddInstallations";
import ListPoles from "./Pages/Installations/ListPoles";
import ProjectOTBs from "./Pages/ProjectOTBs";
import ListOTBsProducts from "./Pages/ProductTracker/ListOTBsProducts";
import ProjectJoints from "./Pages/ProjectJoints";
import ListJointsProducts from "./Pages/ProductTracker/ListJointsProducts";
import ProjectHardware from "./Pages/ProjectHardware";
import ListHardwareProducts from "./Pages/ProductTracker/ListHardwareProducts";
import ProjectCrosses from "./Pages/ProjectCrosses";
import ListCrossesProducts from "./Pages/ProductTracker/ListCrossesProducts";
import ProjectCables from "./Pages/ProjectCables";
import ListCablesProducts from "./Pages/ProductTracker/ListCablesProducts";
import UpdateCrosses from "./Pages/Installations/UpdateCrosses";
import UpdateJoints from "./Pages/Installations/UpdateJoints";
import UpdateOtbs from "./Pages/Installations/UpdateOtbs";
import UpdateHardware from "./Pages/Installations/UpdateHardware";
import UpdatePit from "./Pages/Installations/UpdatePit";
import UpdatePolePlanting from "./Pages/Installations/UpdatePolePlanting";
import UpdatePolePlumbing from "./Pages/Installations/UpdatePolePlumbing";
import UpdateImage from "./Pages/Installations/UpdateImage";
import InventoryReports from "./Pages/Reports/InventoryReports";
import PaymentReports from "./Pages/Reports/PaymentReports";
import RollcallReports from "./Pages/Reports/RollcallReports";
import InstallationsReports from "./Pages/Reports/InstallationsReports";
import UpdateVerification from "./Pages/Installations/UpdateVerification";
import CompleteInstallations from "./Pages/Installations/CompleteInstallations";
import TeamPaymentsPage from "./Pages/TeamPaymentsPage";
import ProjectTasks from "./Pages/ProjectTasks";
import MeetingRecords from "./Pages/MeetingRecords";
import ListDeliveryNotes from "./Pages/Product/ListDeliveryNotes";
import AddProducts from "./Pages/Product/AddProducts";
import ListNoteProducts from "./Pages/Product/ListNoteProducts";
import AddHSEReport from "./Components/Team/AddHSEReport";
import ViewHSEReport from "./Components/Team/ViewHSEReport";
import AcceptanceForm from "./Components/ProjectProfile/AcceptanceForm";
import RegisterMainTask from "./Pages/Tasks/RegisterMainTask";
import toast from "react-hot-toast";
import Stores from "./Pages/Stores";
import ChangeInventoryStore from "./Pages/Product/ChangeInventoryStore";
import LedgerTypes from "./Components/Accounts/LedgerTypes";
import Ledgers from "./Components/Accounts/Ledgers";
import CostALllocationCenter from "./Components/Accounts/CostALllocationCenter";
import BudgetExpenses from "./Components/Accounts/BudgetExpenses";
import PaymentVoucher from "./Components/Accounts/PaymentVoucher";
import ProjectBudgetExpenseReport from "./Components/Accounts/ProjectBudgetExpenseReport";
import Reciepts from "./Components/Accounts/Reciepts";
import Journals from "./Components/Accounts/Journals";
import Contra from "./Components/Accounts/Contra";
//import TrialBalances from "./Components/Accounts/TrialBalances";
import ProjectTrialBalanceReport from "./Components/Accounts/ProjectTrialBalanceReport";
import LedgerTrialBalanceReport from "./Components/Accounts/LedgerTrialBalanceReport";
import InvoicableCenter from "./Components/Accounts/InvoicableCenter";
import InvoiceCreate from "./Components/Accounts/InvoiceCreate";
import InvoicesPage from "./Components/Accounts/InvoicesPage";
import JournalsInventory from "./Components/Accounts/JournalsInventory";
import InvoiceInventory from "./Components/Accounts/InvoiceInventory";

function App(props) {
  const [loggedIn, setLoggedIn] = useState(true);

  function checkLogin() {
    if (!window.localStorage.getItem("projectm@user")) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }

  useEffect(() => {
    checkLogin();
  }, []);

  // Function to handle user logout
  const logoutUser = () => {
    setLoggedIn(false);
    localStorage.removeItem("projectm@user");
    window.location.reload();
  };

  useEffect(() => {
    checkLogin(); // Check login status on component mount
  }, []);

  useEffect(() => {
    let logoutTimer;

    // Function to start the logout timer for inactivity
    const startLogoutTimer = () => {
      logoutTimer = setTimeout(() => {
        logoutUser();
        toast.success("You have been logged out due to inactivity.");
      }, 1200000); // 20 minutes in milliseconds
    };

    // Function to reset the inactivity timer on user interaction
    const resetTimer = () => {
      clearTimeout(logoutTimer);
      startLogoutTimer();
    };

    startLogoutTimer();

    // Set up event listeners to reset the timer on user activity
    const activityEvents = ["mousemove", "keydown", "click"];
    activityEvents.forEach((event) =>
      window.addEventListener(event, resetTimer)
    );

    // Clean up event listeners and timer on component unmount
    return () => {
      activityEvents.forEach((event) =>
        window.removeEventListener(event, resetTimer)
      );
      clearTimeout(logoutTimer);
    };
  }, []);

  return (
    <SuperProvider>
      <Router forceRefresh={false}>
        <Switch>
          {!loggedIn && (
            <>
              <Route path="*" element={<LoginPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/"
                element={
                  !loggedIn ? (
                    <Navigate replace to="/login" />
                  ) : (
                    <DashboardPage />
                  )
                }
              />
            </>
          )}
          {loggedIn && (
            <>
              <Route
                path="/"
                element={
                  !loggedIn ? (
                    <Navigate replace to="/login" />
                  ) : (
                    <DashboardPage />
                  )
                }
              />
              <Route
                path="/login"
                element={loggedIn ? <Navigate replace to="/" /> : <LoginPage />}
              />
              <Route
                path="*"
                element={loggedIn ? <Navigate replace to="/" /> : <LoginPage />}
              />
              <Route path="/" element={<DashboardPage />} />
              {/* <RenderSecure > */}
              <Route path="/projects" element={<ProjectsPage />} />
              {/* </RenderSecure> */}
              <Route path="/clients" element={<ClientsPage />} />
              <Route path="/regions" element={<RegionPage />} />
              <Route path="/teams" element={<TeamPage />} />
              <Route path="/teams/team-profile/:id" element={<TeamProfile />} />
              <Route
                path="/teams/team-profile/:id/hse-report/:id"
                element={<AddHSEReport />}
              />
              <Route
                path="/teams/team-profile/:id/hse-report/view/:id"
                element={<ViewHSEReport />}
              />
              <Route
                path="/teams/team-profile/:id/pay/:id"
                element={<CreatePayment />}
              />
              <Route path="/permissions" element={<Permissions />} />
              <Route
                path="/clients/client-profile/:id"
                element={<ClientProfile />}
              />
              <Route
                path="/projects/project-profile/:id"
                element={<ProjectProfile />}
              />
              <Route
                path="/tasks/project-tasks/:id"
                element={<ProjectTasks />}
              />
              <Route path="/roles-permissions" element={<RolesPermissions />} />
              <Route path="/roles" element={<Roles />} />
              <Route path="/issue-logs" element={<IssueLogs />} />
              <Route path="/issue-logs-chat/:id" element={<IssueLogChat />} />
              <Route path="/visits" element={<VisitsPage />} />
              <Route path="/payments" element={<PaymentsPage />} />
              <Route path="/team-payments" element={<TeamPaymentsPage />} />
              <Route path="/users" element={<UsersPage />} />
              <Route path="/add-user" element={<CreateUsers />} />
              <Route path="/add-client" element={<CreateClients />} />
              <Route path="/users/user-profile/:id" element={<UserProfile />} />
              <Route
                path="/projects/project-profile/:id/sub-deliverables/:id"
                element={<DeliverableProfile />}
              />
              <Route path="/group" element={<PermissionGroup />} />
              <Route path="/profile-user" element={<ProfileUser />} />
              <Route path="/search" element={<SearchBar />} />
              <Route
                path="/projects/project-profile/:id/task/create"
                element={<AddTask />}
              />
              <Route
                path="/projects/project-profile/:id/task/:id"
                element={<ListDailyOutput />}
              />
              <Route
                path="/projects/project-profile/:id/task/:id/pay/:id"
                element={<CreatePayment />}
              />
              <Route
                path="/projects/project-profile/:id/rate/:id"
                element={<ListPayRate />}
              />
              <Route
                path="/projects/project-profile/:id/task/:id/daily/create"
                element={<CreateDailyOutput />}
              />
              <Route path="/products" element={<ListProducts />} />
              <Route path="/products/create" element={<CreateProduct />} />
              <Route path="/inventory" element={<ListInventory />} />
              <Route path="/inventory/create" element={<CreateInventory />} />
              <Route path="/inventory/output" element={<InventoryOutput />} />
              <Route
                path="/inventory/change_inventory_store"
                element={<ChangeInventoryStore />}
              />
              <Route path="/delivery-notes" element={<ListDeliveryNotes />} />
              <Route
                path="/delivery-notes/input/add/:id"
                element={<AddProducts />}
              />
              <Route
                path="/delivery-notes/products/list/:id"
                element={<ListNoteProducts />}
              />
              <Route
                path="/projects/project-profile/:id/rate/:id/rate/create"
                element={<CreatePayRate />}
              />
              {/* /** products tracker */}
              <Route path="/project/poles/:id" element={<ProjectPoles />} />
              <Route
                path="/project/poles/:id/list/:batch"
                element={<ListPolesProducts />}
              />
              <Route path="/project/crosses/:id" element={<ProjectCrosses />} />
              <Route
                path="/project/crosses/:id/list/:batch"
                element={<ListCrossesProducts />}
              />
              <Route path="/project/cables/:id" element={<ProjectCables />} />
              <Route
                path="/project/cables/:id/list/:batch"
                element={<ListCablesProducts />}
              />
              <Route path="/project/otbs/:id" element={<ProjectOTBs />} />
              <Route
                path="/project/otbs/:id/list/:batch"
                element={<ListOTBsProducts />}
              />
              <Route
                path="/project/acceptance-form/:id"
                element={<AcceptanceForm />}
              />
              <Route path="/project/joints/:id" element={<ProjectJoints />} />
              <Route
                path="/project/joints/:id/list/:batch"
                element={<ListJointsProducts />}
              />
              <Route
                path="/project/hardware/:id"
                element={<ProjectHardware />}
              />
              <Route
                path="/project/hardware/:id/list/:batch"
                element={<ListHardwareProducts />}
              />
              {/* /** products tracker end */}

              {/* Installations */}
              {/* <Route path="/installations" element={<Installations />} /> */}
              <Route
                path="/installations/pending/:projectID"
                element={<Installations />}
              />
              <Route
                path="/installations/complete/:projectID"
                element={<CompleteInstallations />}
              />
              {/* <Route
                path="/installations/pending"
                element={<Installations />}
              /> */}
              {/* <Route
                path="/installations/complete"
                element={<CompleteInstallations />}
              /> */}
              <Route path="/installations/list" element={<ListPoles />} />
              <Route
                path="/installations/create/:id"
                element={<AddInstallations />}
              />
              <Route
                path="/installations/create/:id/cross/:cross"
                element={<UpdateCrosses />}
              />
              <Route
                path="/installations/create/:id/joint/:cross"
                element={<UpdateJoints />}
              />
              <Route
                path="/installations/create/:id/otb/:cross"
                element={<UpdateOtbs />}
              />
              <Route
                path="/installations/create/:id/hardware/:cross"
                element={<UpdateHardware />}
              />
              <Route
                path="/installations/create/:id/pits"
                element={<UpdatePit />}
              />
              <Route
                path="/installations/create/:id/plant"
                element={<UpdatePolePlanting />}
              />
              <Route
                path="/installations/create/:id/plumb"
                element={<UpdatePolePlumbing />}
              />
              <Route
                path="/installations/create/:id/complete"
                element={<UpdateVerification />}
              />
              <Route
                path="/installations/create/:id/image/:number"
                element={<UpdateImage />}
              />

              {/* REPORTS */}
              <Route path="/reports/inventory" element={<InventoryReports />} />
              <Route path="/reports/payment" element={<PaymentReports />} />
              <Route path="/reports/rollcall" element={<RollcallReports />} />
              <Route
                path="/reports/installations"
                element={<InstallationsReports />}
              />
              {/* accountings routes */}
              <Route path="/accounts/ledger_types" element={<LedgerTypes />} />
              <Route path="/accounts/ledgers" element={<Ledgers />} />
              <Route
                path="/accounts/cost_allocation_center"
                element={<CostALllocationCenter />}
              />
              <Route
                path="/accounts/budget_expenses"
                element={<BudgetExpenses />}
              />
              <Route
                path="/accounts/payment/Vouchers"
                element={<PaymentVoucher />}
              />
              <Route path="/accounts/journal" element={<Journals />} />
              <Route
                path="/accounts/journal/inventory"
                element={<JournalsInventory />}
              />
              <Route path="/accounts/payment/reciepts" element={<Reciepts />} />
              <Route path="/accounts/contra" element={<Contra />} />
              <Route
                path="/accounts/project_budget_report"
                element={<ProjectBudgetExpenseReport />}
              />

              <Route
                path="/accounts/trial_balance"
                element={<ProjectTrialBalanceReport />}
              />
              <Route
                path="/accounts/getLedger_trial_report/:ledgerID/:projectID/:start_date/:end_date"
                element={<LedgerTrialBalanceReport />}
              />
              <Route
                path="/accounts/invoicables"
                element={<InvoicableCenter />}
              />
              <Route path="/accounts/Invoices" element={<InvoiceCreate />} />
              <Route
                path="/accounts/Invoices_works"
                element={<InvoicesPage />}
              />
              <Route
                path="/accounts/Invoices_works/Inventory"
                element={<InvoiceInventory />}
              />
              {/*end accountings routes */}
            </>
          )}
          <Route path="/sample" element={<Sample />} />
          <Route path="/tasks" element={<Tasks />} />
          <Route path="/task/register" element={<RegisterMainTask />} />
          <Route path="/meeting-records" element={<MeetingRecords />} />
          <Route path="/stores" element={<Stores />} />
        </Switch>
      </Router>
    </SuperProvider>
  );
}

export default App;
