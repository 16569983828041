import React, { useContext, useEffect, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import TableHeader from "../../Components/Common/TableHeader";
import Select from "react-select";
import ajaxReports from "../../util/remote/ajaxReports";
import ajaxProduct from "../../util/remote/ajaxProduct";
import ajaxClient from "../../util/remote/ajaxClient";
import Loader from "../../Components/Common/Loader";
import * as XLSX from "xlsx"; // Import xlsx library
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Toaster, toast } from "react-hot-toast";

function InventoryReports() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [client, setClient] = useState("");
  const [product, setProduct] = useState("");
  const [displayCard, setDisplayCard] = useState(false);

  const [inventoryList, setInventoryList] = useState(false);
  const [listInventory, setListInventory] = useState(false);
  const [productList, setProductList] = useState(false);
  const [clientList, setclientList] = useState(false);

  const [loading, setLoading] = useState(false);

  const resetValues = () => {
    setDisplayCard(false);
    setEndDate("");
    setStartDate("");
  };

  const searchInventory = async () => {
    // e.preventDefault();
    setDisplayCard(true);
    setLoading(true);
    const server_response = await ajaxReports.getInventoryList(
      startDate,
      endDate,
      product,
      client
    );
    setLoading(false);
    if (server_response.status === "OK") {
      if (server_response.details.length === 0) {
        // set the state to an empty array to show an empty table
        setInventoryList([]);
      } else {
        setInventoryList(server_response.details);
      }
    } else {
      setInventoryList([]);
    }
  };

  useEffect(() => {
    getProducts();
    getClients();
    getInventory();
  }, []);

  const getProducts = async () => {
    setLoading(true);
    const server_response = await ajaxProduct.fetchProductList();
    setLoading(false);

    if (server_response.status === "OK") {
      let pdt = server_response.details;
      pdt.unshift({ id: "", product_name: "All products" });
      setProductList(server_response.details);
    }
  };

  const getClients = async () => {
    setLoading(true);
    const server_response = await ajaxClient.fetchProjectClientList();
    setLoading(false);

    if (server_response.status === "OK") {
      let pdt = server_response.details;
      pdt.unshift({ client_id: "", name: "All Clients" });
      setclientList(server_response.details);
    }
  };
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const getInventory = async () => {
    setLoading(true);
    const server_response = await ajaxProduct.fetchInventory();
    setLoading(false);

    if (server_response.status === "OK") {
      setListInventory(server_response.details);
    }
  };

  const exportToExcel = () => {
    // Prepare data for export
    if (Array.isArray(listInventory) || Array.isArray(inventoryList)) {
      const data = (listInventory || inventoryList).map((item, index) => ({
        Product: item.product ? item.product : "N/A",
        Client: item.client ? item.client : "N/A",
        "Quantity In": item.quantity_in.quantity_in,
        "Quantity Out": item.quantity_out.quantity_out,
        Date: item.date_added.short_date,
      }));

      // Create a worksheet from your table data
      const ws = XLSX.utils.json_to_sheet(data);

      // Create a workbook with the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Inventory Data");

      // Generate a file name
      const fileName = "inventory_report.xlsx";

      // Save the file
      XLSX.writeFile(wb, fileName);
    } else {
      toast.error("Cannot export empty table data to excel");
    }
  };

  // Function to export data to PDF
  const exportToPDF = () => {
    const table = document.querySelector(".table"); // Select the table element
    const pdf = new jsPDF("p", "pt", "a4");
    const companyName = "BERICOT AFRICA LIMITED ";
    const companyAddress = "KAMPALA UGANDA \n TEL:+256701666160";
    const currentDate = `Date of Printing: ${new Date().toLocaleDateString()}`;
    const headerDescription = "Inventory  Report "; // The line to show what the PDF is about

    // Get the PDF page width for centering the text
    const pageWidth = pdf.internal.pageSize.getWidth();

    // Add company name (equivalent to <h1>)
    pdf.setFontSize(14); // Font size for <h1>
    const companyNameWidth = pdf.getTextWidth(companyName);
    pdf.text(companyName, (pageWidth - companyNameWidth) / 2, 50); // Centered <h1>

    // Add company address (equivalent to <h2>)
    pdf.setFontSize(12); // Font size for <h2>
    const companyAddressWidth = pdf.getTextWidth(companyAddress.split("\n")[0]);
    pdf.text(
      companyAddress.split("\n")[0],
      (pageWidth - companyAddressWidth) / 2,
      80
    ); // Centered <h2>

    const companyAddressCityWidth = pdf.getTextWidth(
      companyAddress.split("\n")[1]
    );
    pdf.text(
      companyAddress.split("\n")[1],
      (pageWidth - companyAddressCityWidth) / 2,
      100
    ); // Centered <h3>

    // Add printing date (equivalent to <h3>)
    pdf.setFontSize(9); // Font size for <h3>
    const dateWidth = pdf.getTextWidth(currentDate);
    pdf.text(currentDate, (pageWidth - dateWidth) / 2, 130); // Centered <h3>

    // Add header description (equivalent to a line explaining the document)
    pdf.setFontSize(12); // Font size for description
    const headerDescriptionWidth = pdf.getTextWidth(headerDescription);
    pdf.text(headerDescription, (pageWidth - headerDescriptionWidth) / 2, 160); // Centered description");

    // Define columns for the table (add more if needed)
    const columns = [
      "no",
      "Product",
      "Client",
      "Quantity In",
      "Quantity Out",
      "Date",
    ];

    // Extract data from the table and format it as an array of arrays
    const data = Array.from(table.querySelectorAll("tr")).map((row) => {
      return Array.from(row.querySelectorAll("td")).map(
        (cell) => cell.textContent
      );
    });

    // Remove the header row
    data.shift();

    // Create the PDF document and add the table
    pdf.autoTable({
      startY: 180,
      head: [columns],
      body: data,
    });

    // Save the PDF
    pdf.save(`inventory_data_${new Date().toLocaleDateString()}.pdf`);
  };

  return (
    <AppContainer title="Inventory Reports">
      <Toaster />
      <div className="col-lg-12">
        <div className="card custom-card" style={{ borderRadius: "10px" }}>
          <div className="card-body map-card">
            <TableHeader
            // title="Inventory List"
            />
            <div class="">
              <a
                onClick={toggleDropdown}
                href="#"
                className="btn ripple btn-secondary navresponsive-toggler mb-0">
                <i className="fe fe-filter mr-1"></i> Filter{" "}
                <i
                  className={`fas fa-caret-${
                    isDropdownVisible ? "up" : "down"
                  } ml-1`}></i>
              </a>
              <button
                style={{ float: "right" }}
                onClick={exportToExcel}
                className="btn btn-primary mb-3">
                Export to Excel
              </button>
              <button
                style={{ float: "right" }}
                onClick={exportToPDF}
                className="btn btn-primary mb-3 mr-2">
                Export to PDF
              </button>
            </div>
            <br />
            {isDropdownVisible && (
              <div class="responsive-background" data-select2-id="9">
                <div
                  class="collapse navbar-collapse"
                  id="navbarSupportedContent"
                  data-select2-id="navbarSupportedContent">
                  <div className="row">
                    <div className="col-md-12 col-xl-12 mb-0">
                      <h5>Search</h5>
                      <div className="row">
                        <div className="col-md-12 col-xl-12 mb-3">
                          <b>By Date:</b>
                        </div>

                        <div className="col-md-12 col-xl-6 mb-0">
                          <div className="form-group">
                            {" "}
                            <label className="form-label">From:</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div
                                  className="input-group-text"
                                  style={{ height: "100%" }}>
                                  <i className="fe fe-calendar"></i>{" "}
                                </div>
                              </div>
                              <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-xl-6 mb-0">
                          <div className="form-group">
                            {" "}
                            <label className="form-label">To:</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <div
                                  className="input-group-text"
                                  style={{ height: "100%" }}>
                                  <i className="fe fe-calendar"></i>{" "}
                                </div>
                              </div>
                              <input
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group col-xl-6 mt-2">
                          <b>By Product:</b>
                          <br />
                          <Select
                            onChange={(e) => setProduct(e.id)}
                            getOptionLabel={(option) => option.product_name}
                            getOptionValue={(option) => option.id}
                            isSearchable
                            options={
                              Array.isArray(productList) ? productList : []
                            }
                            value={
                              Array.isArray(productList) &&
                              productList.find((value) => value.id === product)
                            }
                          />
                        </div>

                        <div className="form-group col-xl-6 mt-2">
                          <b>By Client:</b>
                          <br />
                          <Select
                            onChange={(e) => setClient(e.client_id)}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.client_id}
                            isSearchable
                            options={
                              Array.isArray(clientList) ? clientList : []
                            }
                            value={
                              Array.isArray(clientList) &&
                              clientList.find(
                                (value) => value.client_id === client
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-xl-12 mb-5">
                      <div
                        className="form-group "
                        style={{ marginTop: "32px" }}>
                        {" "}
                        <a
                          href="#"
                          onClick={searchInventory}
                          className="btn bg-primary btn-block color-white"
                          style={{
                            color: "white",
                            display: "block",
                            width: "100%",
                          }}>
                          Search Inventory
                        </a>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="border-top mt-2"></div>
            <div className="table-responsive">
              {loading && <Loader />}
              <table
                className="table table-hover text-nowrap mg-b-0"
                style={{ marginBottom: 350 }}>
                <thead>
                  <tr>
                    <th scope="col">#NO</th>
                    <th scope="col">Product</th>
                    <th scope="col">Client</th>
                    <th scope="col">Quantity In</th>
                    <th scope="col"> Quantity Out</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {inventoryList && Array.isArray(inventoryList) ? (
                    inventoryList.length > 0 ? (
                      inventoryList.map((item, key) => (
                        // {
                        //   id: "223",
                        //   product: "Poles - 9 Meters",
                        //   quantity_in: {
                        //     quantity_in: 100,
                        //     quantity_in_c: "100",
                        //     quantity_in_p: "100",
                        //   },
                        //   quantity_out: "",
                        //   description: "wooden",
                        //   delivery_note: "1032",
                        //   unit_cost: "",
                        //   total: "",
                        //   date_added: {
                        //     long_date: "07 Mar 2024 at 12:00 am",
                        //     short_date: "07 Mar 2024",
                        //     when: "07 Mar 2024 at 12:00 am",
                        //     time: "12:00 am",
                        //     date: "07 Mar 2024",
                        //     weekday: "Thursday",
                        //     db: "2024-03-07",
                        //   },
                        //   client: "NETIS UGANDA LIMITED",
                        //   added_by: "Orikiriza Alex",
                        // },
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{item.product}</td>
                          <td>{item.client}</td>
                          <td>{item.quantity_in.quantity_in_c}</td>
                          <td>{item.quantity_out.quantity_out_c}</td>
                          <td>{item.date_added.short_date}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7">No inventory found.</td>
                      </tr>
                    )
                  ) : (
                    Array.isArray(listInventory) &&
                    listInventory.map((item, key) => (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>{item.product}</td>
                        <td>{item.client}</td>
                        <td>{item.quantity_in.quantity_in_c}</td>
                        <td>{item.quantity_out.quantity_out_c}</td>
                        <td>{item.date_added.short_date}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default InventoryReports;
