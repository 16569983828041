import React, { useContext, useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import Select from "react-select";
import { Link, useParams } from "react-router-dom";
import ajaxUser from "../util/remote/ajaxUser";
import useStateCallback from "../util/customHooks/useStateCallback";
import ChangePassword from "../Components/Users/ChangePassword";
import { Toaster, toast } from "react-hot-toast";
import RoleContext from "../Context/RoleContext";
import TableHeader from "../Components/Common/TableHeader";
import Loader from "../Components/Common/Loader";
import CreateUserAllocation from "../Components/Users/CreateUserAllocation";
import ajaxPayRate from "../util/remote/ajaxPayRate";
import ajaxProduct from "../util/remote/ajaxProduct";
import CreateUserProductAllocation from "../Components/Users/CreateUserProductAllocation";
import { RenderSecure } from "../util/script/RenderSecure";
import CreateDeposit from "../Components/Users/CreateDeposit";
import ResetUserPassword from "../Components/Users/ResetUserPassword";
import DisableUser from "../Components/Users/DisableUser";
import ReturnUserItem from "../Components/Users/ReturnUserItem";
import EnableUser from "../Components/Users/EnableUser";

const UserProfile = (props) => {
  const [userProfile, setUserProfile] = useState(false);
  const { roleList } = useContext(RoleContext);
  const [modal, setModal] = useStateCallback(false);
  const { id } = useParams();
  const [userTeams, setUserTeams] = useState(false);
  const [userPayments, setPayments] = useState(false);
  const [userProducts, setUserProducts] = useState(false);
  const [active, setActive] = useState(false);
  const handleActive = () => setActive(true);
  const handleInActive = () => setActive(false);

  const [userID, setUserID] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [district, setDistrict] = useState("");
  const [NIN, setNIN] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [contact, setContact] = useState("");
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  useEffect(() => {
    getUserProfile(id);
    getUserTeams(id);
    getUserPayments(id);
    getUserProducts(id);
  }, [id]);

  const setUserUpdate = () => {
    handleActive();
    setFirstName(userProfile.first_name);
    setLastName(userProfile.last_name);
    setUsername(userProfile.username);
    setDistrict(userProfile.district);
    setEmail(userProfile.email);
    setNIN(userProfile.nin);
    setRole(userProfile.role.role_id);
    setUserID(userProfile.id);
    setContact(userProfile.contact);
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    const server_response = await ajaxUser.updateUser(
      id,
      first_name,
      last_name,
      username,
      email,
      NIN,
      district,
      role,
      contact
    );
    if (server_response.status === "OK") {
      toast.success(server_response.message);
      getUserProfile(id);
    }
  };

  const getUserProfile = async (id) => {
    setLoading(true);
    const server_response = await ajaxUser.fetchUserProfile(id);
    setLoading(false);
    if (server_response.status === "OK") {
      setUserProfile(server_response.details);
    } else {
      setUserProfile("404");
    }
  };

  const getUserProducts = async (id) => {
    setLoading1(true);
    const server_response = await ajaxProduct.fetchUserProductAllocationList(
      id
    );
    setLoading1(false);
    if (server_response.status === "OK") {
      setUserProducts(server_response.details);
    } else {
      setUserProducts("404");
    }
  };

  const getUserPayments = async (id) => {
    setLoading2(true);
    const server_response = await ajaxPayRate.fetchPayments(id);
    setLoading2(false);
    if (server_response.status === "OK") {
      setPayments(server_response.details);
    } else {
      setPayments("404");
    }
  };

  const getUserTeams = async () => {
    setLoading3(true);
    const server_response = await ajaxUser.fetchUserTeams(id);
    setLoading3(false);
    if (server_response.status === "OK") {
      setUserTeams(server_response.details);
    } else {
      setUserTeams("404");
    }
  };

  const calculateTotal = (column) => {
    let total = 0;
    if (Array.isArray(userPayments) && userPayments.length > 0) {
      userPayments.forEach((item) => {
        total += parseFloat(item[column]);
      });
    }
    return total;
  };

  const totalDebit = calculateTotal("amount");
  const totalCredit = calculateTotal("amount_out");

  const handleModal2 = () => {
    setModal(false, () =>
      setModal(
        <CreateUserAllocation user_id={id} f={getUserTeams} isOpen={true} />
      )
    );
  };
  const handleModal3 = () => {
    setModal(false, () =>
      setModal(
        <CreateUserProductAllocation
          user_id={id}
          f={getUserProducts}
          isOpen={true}
        />
      )
    );
  };
  const handleModal4 = () => {
    setModal(false, () =>
      setModal(
        <CreateDeposit
          employee={id}
          // f={getUserPayments}
          // g={getUserProfile}
          isOpen={true}
        />
      )
    );
  };
  const handleModal5 = () => {
    setModal(false, () =>
      setModal(<ResetUserPassword userID={id} isOpen={true} />)
    );
  };
  const handleModal6 = () => {
    setModal(false, () =>
      setModal(<DisableUser userID={id} g={getUserProfile} isOpen={true} />)
    );
  };
  const handleModal7 = (e, item) => {
    setModal(false, () =>
      setModal(
        <ReturnUserItem
          id={item.allocation_id}
          user_id={id}
          f={getUserProducts}
          isOpen={true}
        />
      )
    );
  };
  const handleModal8 = () => {
    setModal(false, () =>
      setModal(<EnableUser userID={id} g={getUserProfile} isOpen={true} />)
    );
  };

  return (
    <AppContainer title={"User Profile"}>
      <Toaster position="top-center" reverseOrder={false} />
      {modal}

      <div className="col-12 col-xl-12">
        <div
          className="box user-pro-list overflow-hidden mb-30"
          style={{
            marginBottom: "30px",
            backgroundColor: "white",
            padding: "25px",
            boxShadow: "10px",
            borderRadius: "10px",
          }}>
          {userProfile && (
            <div className="box-body" style={{ position: "relative" }}>
              <div className="user-pic text-center">
                <div
                  className="main-avatar"
                  style={{
                    margin: "0 auto",
                    fontSize: "50px",
                    height: "112px",
                    width: "112px",
                    backgroundColor: "#8760fb",
                    borderRadius: "50%",
                  }}>
                  {userProfile.short_name}
                </div>
                <div
                  className="pro-user mt-3"
                  style={{ marginTop: "1rem !important" }}>
                  <h5
                    className="pro-user-username text-dark mb-2 fs-15 mt-42 color-span"
                    style={{ lineHeight: "1.5" }}>
                    {userProfile.first_name} {userProfile.last_name}
                  </h5>
                  <h6 className="pro-user-desc text-muted fs-14">
                    {userProfile.role.role_name}
                  </h6>
                </div>
              </div>
            </div>
          )}

          <div
            className="box-footer pt-41"
            style={{ paddingTop: "41px !important" }}>
            <div className="btn-list text-center">
              {active ? (
                <a
                  href="#"
                  onClick={handleInActive}
                  className="btn ripple btn-danger btn-sm mr-2">
                  <i className="fe fe-x"></i>Back
                </a>
              ) : (
                <a
                  href="#"
                  onClick={setUserUpdate}
                  className="btn ripple btn-warning btn-sm mr-2">
                  <i className="far fa-edit mr-1"></i>Update Details
                </a>
              )}

              <a
                href="#"
                onClick={handleModal4}
                className="btn ripple btn-info btn-sm">
                <i className="fe fe-dollar-sign mr-1"></i>Make Payment Deposit
              </a>

              <RenderSecure code="ADD-USER">
                <a
                  href="#"
                  onClick={handleModal5}
                  className="btn ripple btn-secondary btn-sm">
                  <i className="fas fa-user-lock mr-1"></i>Password Reset
                </a>
                {userProfile.is_active === "1" ? (
                  <a
                    href="#"
                    onClick={handleModal6}
                    className="btn ripple btn-danger btn-sm">
                    <i className="fas fa-user-minus mr-1"></i>Disable User
                  </a>
                ) : (
                  <a
                    href="#"
                    onClick={handleModal8}
                    className="btn ripple btn-success btn-sm">
                    <i className="fas fa-user-plus mr-1"></i>Enable User
                  </a>
                )}
              </RenderSecure>
            </div>
          </div>
        </div>

        {active ? (
          <div
            className="box left-dot mb-30"
            style={{
              marginBottom: "30px",
              backgroundColor: "white",
              padding: "25px",
              boxShadow: "10px",
              borderRadius: "10px",
            }}>
            <div className="box-header  border-0 pd-0">
              <div className="box-title fs-20 font-w600">
                {" "}
                Update User Information
              </div>
            </div>
            <br />
            <div className="box-body pt-20 user-profile">
              <form onSubmit={handleUpdate}>
                <div className="form-group">
                  <div className="row row-sm">
                    <div className="col-sm-6">
                      <label htmlFor="">
                        First Name:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={first_name}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="">
                        Last Name:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={last_name}
                        onChange={(e) => setLastName(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row row-sm">
                    <div className="col-sm-6">
                      <label htmlFor="">
                        Role:<span className="tx-danger">*</span>
                      </label>
                      <Select
                        onChange={(e) => setRole(e.role_id)}
                        getOptionLabel={(option) => option.role_name}
                        getOptionValue={(option) => option.role_id}
                        isSearchable
                        options={roleList}
                        defaultValue={
                          Array.isArray(roleList) &&
                          roleList.find((value) => value.role_id === role)
                        }
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="">
                        Username:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row row-sm">
                    <div className="col-sm-6">
                      <label htmlFor="">
                        E-mail:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="">
                        NIN:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={NIN}
                        onChange={(e) => setNIN(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row row-sm">
                    <div className="col-sm-6">
                      <label htmlFor="">
                        District:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={district}
                        onChange={(e) => setDistrict(e.target.value)}
                        className="form-control"
                      />
                    </div>

                    <div className="col-sm-6">
                      <label htmlFor="">
                        Contact:<span className="tx-danger">*</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={contact}
                        onChange={(e) => setContact(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <button className="btn ripple btn-main-primary btn-block">
                  Update User Profile
                </button>
              </form>
            </div>
          </div>
        ) : (
          <div
            className="box left-dot mb-30"
            style={{
              marginBottom: "30px",
              backgroundColor: "white",
              padding: "25px",
              boxShadow: "10px",
              borderRadius: "10px",
            }}>
            <div className="box-header  border-0 pd-0">
              <div className="box-title fs-20 font-w600">User Information</div>
            </div>
            <div className="box-body pt-20 user-profile">
              <div className="table-responsive">
                <table className="table mb-0 mw-100 color-span">
                  {userProfile && (
                    <tbody>
                      <tr>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="w-50">First Name </span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="">
                            {userProfile.first_name}
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="w-50">Last Name</span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="">{userProfile.last_name}</span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="w-50">Username</span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="">{userProfile.username}</span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="w-50">E-mail Address</span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="">{userProfile.email}</span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="w-50">Role</span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="">
                            {userProfile.role.role_name}
                          </span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="w-50">NIN</span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="">{userProfile.nin}</span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="w-50">District</span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="">{userProfile.district}</span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="w-50">Contact</span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="">{userProfile.contact}</span>{" "}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-0">
                          {" "}
                          <span className="w-50">Balance Due:</span>{" "}
                        </td>
                        <td>:</td>
                        <td className="py-2 px-0">
                          {userProfile.balance.balance_c <= "0" ? (
                            <span className="" style={{ color: "green" }}>
                              {userProfile.balance?.balance_c}
                            </span>
                          ) : (
                            <span className="" style={{ color: "red" }}>
                              {userProfile.balance?.balance_c}
                            </span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
                {loading && <Loader />}
              </div>
            </div>
          </div>
        )}

        <div className="card custom-card" style={{ borderRadius: "10px" }}>
          <div className="card-body map-card">
            <TableHeader
              title="My Teams"
              subtitle="List of all the assigned teams"
              viewButton={
                <RenderSecure code="ASSIGN-TEAM">
                  <a
                    href="#"
                    onClick={handleModal2}
                    className="btn btn-primary btn-sm"
                    style={{ float: "right" }}>
                    Assign Team
                  </a>
                </RenderSecure>
              }
            />
            <div className="border-top mt-3"></div>
            <div className="table-responsive">
              <table className="table table-hover text-nowrap mg-b-0">
                <thead>
                  <tr>
                    <th scope="col">No.</th>
                    <th scope="col"> Team Name</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(userTeams) && userTeams.length > 0 ? (
                    userTeams.map((item, key) => (
                      <tr key={key}>
                        <th scope="row">{key + 1}</th>
                        <td>
                          {" "}
                          <span>
                            <Link
                              to={`../teams/team-profile/${item.team.team_id}`}>
                              {" "}
                              {item.team.team_name}
                            </Link>
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{ textAlign: "center" }}>
                        No teams assigned to the user yet.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {loading3 && <Loader />}
            </div>
          </div>
        </div>

        <div className="card custom-card" style={{ borderRadius: "10px" }}>
          <div className="card-body map-card">
            <TableHeader
              title="Payments"
              subtitle="List of all the payments made to user"
            />
            <div className="border-top mt-3"></div>
            <div className="table-responsive">
              <table className="table table-hover text-nowrap mg-b-0">
                <thead>
                  <tr>
                    <th scope="col">Payment Date</th>
                    <th scope="col">Debit</th>
                    <th scope="col"> Credit</th>

                    <th scope="col">Deposited By</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(userPayments) && userPayments.length > 0 ? (
                    userPayments.map((item, key) => (
                      <tr key={key}>
                        <td>{item.created_at.long_date}</td>
                        <td> {item.amount_out} </td>
                        <td> {item.amount} </td>

                        <td> {item.added_by} </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        No payments made yet to user.
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td>
                      <strong>{totalCredit}</strong>
                    </td>
                    <td>
                      <strong>{totalDebit}</strong>
                    </td>

                    <td></td>
                  </tr>
                </tbody>
              </table>
              {loading2 && <Loader />}
            </div>
          </div>
        </div>

        <div className="card custom-card" style={{ borderRadius: "10px" }}>
          <div className="card-body map-card">
            <TableHeader
              title="Personal Protection Equipment"
              subtitle="List of all the protection equipment allocated to user"
              viewButton={
                <RenderSecure code="ASSIGN-TOOL">
                  <a
                    href="#"
                    onClick={handleModal3}
                    className="btn btn-primary btn-sm"
                    style={{ float: "right" }}>
                    Allocate PPE
                  </a>
                </RenderSecure>
              }
            />
            <div className="border-top mt-3"></div>
            <div className="table-responsive">
              <table className="table table-hover text-nowrap mg-b-0">
                <thead>
                  <tr>
                    <th scope="col">PPE Name</th>
                    <th scope="col"> Quantity Allocated</th>
                    <th scope="col"> Quantity Returned</th>
                    <th scope="col"> Date Returned</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(userProducts) && userProducts.length > 0 ? (
                    userProducts.map((item, key) => (
                      <tr key={key}>
                        <td>{item.product.product_name}</td>
                        <td>{item.quantity}</td>

                        <td>
                          {item.quantity_returned
                            ? item.quantity_returned
                            : "Still in use"}
                        </td>

                        <td>
                          {item.date_returned
                            ? item.date_returned
                            : "Still in use"}
                        </td>
                        {item.quantity_returned === null ? (
                          <td>
                            <Link
                              className="btn btn-sm btn-info mr-2"
                              href="#"
                              onClick={(e) => handleModal7(e, item)}>
                              Return PPE
                            </Link>
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" style={{ textAlign: "center" }}>
                        No PPE allocated to user.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {loading1 && <Loader />}
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default UserProfile;
