import React, { useEffect, useState } from "react";
import AppContainer from "../../Components/Structure/AppContainer";
import TableHeader from "../../Components/Common/TableHeader";
import Select from "react-select";
import { Tab, Nav, Row, Col } from "react-bootstrap";
import ajaxReports from "../../util/remote/ajaxReports";
import { Toaster, toast } from "react-hot-toast";
import ajaxUser from "../../util/remote/ajaxUser";
import ajaxTask from "../../util/remote/ajaxTask";
import ajaxPayRate from "../../util/remote/ajaxPayRate";
import Loader from "../../Components/Common/Loader";
import * as XLSX from "xlsx"; // Import xlsx library
import jsPDF from "jspdf";
import "jspdf-autotable";

function PaymentReports() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [user, setUser] = useState("");
  const [paymentsSearch, setPaymentsSearch] = useState(false);
  const [paymentList, setListPayment] = useState(false);
  const [taskPayments, setTaskPayments] = useState(false);
  const [deposits, setDeposits] = useState(false);
  const [paymentDepositsSearch, setPaymentDepositsSearch] = useState(false);
  const [userList, setUserList] = useState(false);
  const [taskList, setTaskList] = useState(false);
  const [task, setTask] = useState(false);
  const [teamID, setTeamID] = useState("");
  const [payments, setPayments] = useState(false);
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getUsers();
    // getTasks()
    getPayments();
    getTaskPayments();
    getDeposits();
  }, []);

  const getPayments = async () => {
    const server_response = await ajaxPayRate.fetchAllPayments();

    if (server_response.status === "OK") {
      setPayments(server_response.details);
    } else {
      setPayments("404");
    }
  };
  const getTaskPayments = async () => {
    const server_response = await ajaxPayRate.fetchTaskPayments();

    if (server_response.status === "OK") {
      setTaskPayments(server_response.details);
    } else {
      setTaskPayments("404");
    }
  };
  const getDeposits = async () => {
    const server_response = await ajaxPayRate.fetchDeposits();

    if (server_response.status === "OK") {
      setDeposits(server_response.details);
    } else {
      setDeposits("404");
    }
  };

  const searchPaymentDeposits = async (e) => {
    const server_response = await ajaxReports.getPaymentDepositList(
      user,
      startDate,
      endDate
    );
    if (server_response.status === "OK") {
      if (server_response.details.length === 0) {
        // set the state to an empty array to show an empty table
        setPaymentDepositsSearch([]);
      } else {
        setPaymentDepositsSearch(server_response.details);
      }
    } else {
      setPaymentDepositsSearch([]);
    }
  };

  const getUsers = async () => {
    const server_response = await ajaxUser.getUserList();
    if (server_response.status === "OK") {
      let pdt = server_response.details;
      pdt.unshift({ id: "", first_name: "All Users", last_name: "" });
      setUserList(server_response.details);
    }
  };

  // const getTasks = async() =>{
  //   const server_response = await ajaxTask.fetchTasksPerTeam(teamID);

  //   if(server_response.status==="OK"){
  //     setTaskList(server_response.details)
  //   }

  // }

  const searchPaymentTaskPayments = async () => {
    const server_response = await ajaxReports.getPaymentTasksList(
      user,
      startDate,
      endDate
    );
    if (server_response.status === "OK") {
      if (server_response.details.length === 0) {
        // set the state to an empty array to show an empty table
        setPaymentsSearch([]);
      } else {
        setPaymentsSearch(server_response.details);
      }
    } else {
      setPaymentsSearch([]);
    }
  };

  const resetValues = () => {
    setPaymentsSearch(false);
    setEndDate("");
    setStartDate("");
    setUser("");
    setTask("");
  };
  const resetValues2 = () => {
    setPaymentDepositsSearch(false);
    setEndDate("");
    setStartDate("");
    setUser("");
  };

  const [ariaExpanded, setAriaExpanded] = useState(false);

  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const exportToExcelPayment = () => {
    // Prepare data for export
    if (Array.isArray(paymentDepositsSearch) || Array.isArray(deposits)) {
      const data = (paymentDepositsSearch || deposits).map((item, index) => ({
        Employee: `${item.employee.first_name} ${item.employee.last_name}`,
        "Amount Out": item.amount_out,
        Date: item.created_at?.short_date,
      }));
      // Create a worksheet from your table data
      const ws = XLSX.utils.json_to_sheet(data);

      // Create a workbook with the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Payment Data");

      // Generate a file name
      const fileName = "payment_data.xlsx";

      // Save the file
      XLSX.writeFile(wb, fileName);
    } else {
      toast.error("Cannot export empty table to excel");
    }
  };

  // Function to export data to PDF
  const exportToPDFPayment = () => {
    if (Array.isArray(paymentDepositsSearch) || Array.isArray(deposits)) {
      const table = document.querySelector(".table"); // Select the table element
      const pdf = new jsPDF("p", "pt", "a4");

      // Define columns for the table (add more if needed)
      const columns = ["#NO", "Employee", "Amount Out", "Date"];

      // Extract data from the table and format it as an array of arrays
      const data = Array.from(table.querySelectorAll("tr")).map((row) => {
        return Array.from(row.querySelectorAll("td")).map(
          (cell) => cell.textContent
        );
      });

      // Remove the header row
      data.shift();

      // Create the PDF document and add the table
      pdf.autoTable({
        head: [columns],
        body: data,
      });

      // Save the PDF
      pdf.save("payment_data.pdf");
    } else {
      toast.error("Cannot export empty table to pdf");
    }
  };

  const exportToExcelPaymentByTask = () => {
    // Prepare data for export
    if (Array.isArray(paymentsSearch) || Array.isArray(taskPayments)) {
      const data = (paymentsSearch || taskPayments).map((item, index) => ({
        Employee: `${item.employee.first_name} ${item.employee.last_name}`,
        Amount: item.amount,
        Date: item.created_at.short_date,
      }));
      // Create a worksheet from your table data
      const ws = XLSX.utils.json_to_sheet(data);

      // Create a workbook with the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Payment By Task Data");

      // Generate a file name
      const fileName = "payment_by_task_data.xlsx";

      // Save the file
      XLSX.writeFile(wb, fileName);
    } else {
      toast.error("Cannot export empty table to excel");
    }
  };

  // Function to export data to PDF
  const exportToPDFPaymentByTask = () => {
    if (Array.isArray(paymentsSearch) || Array.isArray(taskPayments)) {
      const table = document.querySelector(".table1"); // Select the table element
      const pdf = new jsPDF("p", "pt", "a4");

      // Define columns for the table (add more if needed)
      const columns = ["#NO", "Employee", "Task", "Amount", "Date"];

      // Extract data from the table and format it as an array of arrays
      const data = Array.from(table.querySelectorAll("tr")).map((row) => {
        return Array.from(row.querySelectorAll("td")).map(
          (cell) => cell.textContent
        );
      });
      // Remove the header row
      data.shift();

      // Create the PDF document and add the table
      pdf.autoTable({
        head: [columns],
        body: data,
      });

      // Save the PDF
      pdf.save("payment_data.pdf");
    } else {
      toast.error("Cannot export empty table to pdf");
    }
  };

  const calculateTotal = (column) => {
    let total = 0;

    if (Array.isArray(paymentsSearch) && paymentsSearch.length > 0) {
      paymentsSearch.forEach((item) => {
        total += parseFloat(item[column]);
      });
    } else {
      // Add total from taskPayments array
      if (Array.isArray(taskPayments) && taskPayments.length > 0) {
        taskPayments.forEach((item) => {
          total += parseFloat(item[column]);
        });
      }
    }
    return total;
  };

  const calculateTotal2 = (column) => {
    let total = 0;

    if (
      Array.isArray(paymentDepositsSearch) &&
      paymentDepositsSearch.length > 0
    ) {
      paymentDepositsSearch.forEach((item) => {
        total += parseFloat(item[column]);
      });
    } else {
      if (Array.isArray(deposits) && deposits.length > 0) {
        deposits.forEach((item) => {
          total += parseFloat(item[column]);
        });
      }
    }
    return total;
  };

  const totalCredit = calculateTotal("amount");
  const totalDebit = calculateTotal2("amount_out");
  return (
    <AppContainer title="Payment Reports">
      <Toaster />
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={12}>
            <Nav variant="pills" className="flex-row mb-4">
              <Nav.Item>
                <Nav.Link size="sm" eventKey="first">
                  Debit Report{" "}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link size="sm" eventKey="second">
                  Credit Report{" "}
                </Nav.Link>
              </Nav.Item>

              {/* <RenderSecure code="ADD-TASK"> */}

              <Nav.Item></Nav.Item>
              {/* </RenderSecure> */}
            </Nav>
          </Col>

          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div
                  className="card custom-card"
                  style={{ borderRadius: "10px" }}>
                  <div className="card-body map-card">
                    {/* <TableHeader
                            title="Generate Inventory Report" 
                             
                        /> */}
                    <div class="">
                      <a
                        onClick={toggleDropdown}
                        href="#"
                        className="btn ripple btn-secondary btn-sm navresponsive-toggler mb-0">
                        <i className="fe fe-filter mr-1"></i> Filter{" "}
                        <i
                          className={`fas fa-caret-${
                            isDropdownVisible ? "up" : "down"
                          } ml-1`}></i>
                      </a>
                      <button
                        style={{ float: "right" }}
                        onClick={exportToExcelPayment}
                        className="btn btn-primary mb-3 ">
                        Export to Excel
                      </button>
                      <button
                        style={{ float: "right" }}
                        onClick={exportToPDFPayment}
                        className="btn btn-secondary mb-3 mr-3">
                        Export to PDF
                      </button>
                    </div>
                    <br />
                    <br />
                    {isDropdownVisible && (
                      <div class="responsive-background" data-select2-id="9">
                        <div
                          class="collapse navbar-collapse"
                          id="navbarSupportedContent"
                          data-select2-id="navbarSupportedContent">
                          <div className="row">
                            <div className="col-md-12 col-xl-12 mb-0">
                              <h5>Search</h5>
                              <div className="row">
                                <div className="col-md-12 col-xl-12 mb-3">
                                  <b>By Date:</b>
                                </div>

                                <div className="col-md-12 col-xl-6 mb-0">
                                  <div className="form-group">
                                    {" "}
                                    <label className="form-label">From:</label>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <div
                                          className="input-group-text"
                                          style={{ height: "100%" }}>
                                          <i className="fe fe-calendar"></i>{" "}
                                        </div>
                                      </div>
                                      <input
                                        type="date"
                                        value={startDate}
                                        onChange={(e) =>
                                          setStartDate(e.target.value)
                                        }
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 col-xl-6 mb-0">
                                  <div className="form-group">
                                    {" "}
                                    <label className="form-label">To:</label>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <div
                                          className="input-group-text"
                                          style={{ height: "100%" }}>
                                          <i className="fe fe-calendar"></i>{" "}
                                        </div>
                                      </div>
                                      <input
                                        type="date"
                                        value={endDate}
                                        onChange={(e) =>
                                          setEndDate(e.target.value)
                                        }
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group col-xl-12 mt-2">
                                  <b>By User:</b>
                                  <br />
                                  <Select
                                    onChange={(e) => setUser(e.id)}
                                    getOptionLabel={(option) =>
                                      option.first_name + " " + option.last_name
                                    }
                                    getOptionValue={(option) => option.id}
                                    isSearchable
                                    options={
                                      Array.isArray(userList) ? userList : []
                                    }
                                    value={
                                      Array.isArray(userList) &&
                                      userList.find(
                                        (value) => value.id === user
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 col-xl-12 mb-5">
                              <div
                                className="form-group "
                                style={{ marginTop: "32px" }}>
                                {" "}
                                <a
                                  onClick={(e) => searchPaymentDeposits()}
                                  className="btn bg-primary btn-block color-white"
                                  style={{
                                    color: "white",
                                    display: "block",
                                    width: "100%",
                                  }}>
                                  Search
                                </a>{" "}
                              </div>
                              <div
                                className="form-group"
                                style={{ marginTop: "32px" }}>
                                {" "}
                                <a
                                  href="#"
                                  onClick={resetValues2}
                                  className="btn bg-light btn-block color-white"
                                  style={{
                                    color: "black",
                                    display: "block",
                                    width: "100%",
                                  }}>
                                  Reset
                                </a>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="border-top mt-5"></div>
                    <div className="table-responsive">
                      <table
                        className="table table-hover text-nowrap mg-b-0"
                        style={{ marginBottom: 350 }}>
                        <thead>
                          <tr>
                            <th scope="col">#NO</th>
                            <th scope="col">User</th>
                            <th scope="col">Deposit Amount</th>
                            <th scope="col"> Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paymentDepositsSearch &&
                          Array.isArray(paymentDepositsSearch) ? (
                            paymentDepositsSearch.length > 0 ? (
                              paymentDepositsSearch.map((item, key) => (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>
                                    {item.employee.first_name +
                                      " " +
                                      item.employee.last_name}
                                  </td>
                                  <td>{item.amount_out}</td>
                                  <td>{item.created_at.short_date}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="7">No deposits found.</td>
                              </tr>
                            )
                          ) : (
                            Array.isArray(deposits) &&
                            deposits.map((item, key) => (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>
                                  {item.employee.first_name +
                                    " " +
                                    item.employee.last_name}
                                </td>

                                <td>{item.amount_out}</td>
                                <td>{item.created_at.short_date}</td>
                              </tr>
                            ))
                          )}
                          <tr>
                            <td colSpan="2">
                              <strong>Total</strong>
                            </td>
                            <td>
                              <strong>{totalDebit}</strong>
                            </td>

                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Tab.Pane>

              <Tab.Pane eventKey="second">
                <div
                  className="card custom-card"
                  style={{ borderRadius: "10px" }}>
                  <div className="card-body map-card">
                    {/* <TableHeader
                            title="Generate Payment By Task Report" 
                             
                        /> */}
                    <div class="">
                      <a
                        onClick={toggleDropdown}
                        href="#"
                        className="btn ripple btn-sm btn-secondary navresponsive-toggler mb-0">
                        <i className="fe fe-filter mr-1"></i> Filter{" "}
                        <i
                          className={`fas fa-caret-${
                            isDropdownVisible ? "up" : "down"
                          } ml-1`}></i>
                      </a>
                      <button
                        style={{ float: "right" }}
                        onClick={exportToExcelPaymentByTask}
                        className="btn btn-primary mb-3 ">
                        Export to Excel
                      </button>
                      <button
                        style={{ float: "right" }}
                        onClick={exportToPDFPaymentByTask}
                        className="btn btn-secondary mb-3 mr-3">
                        Export to PDF
                      </button>
                    </div>
                    <br />
                    <br />
                    {isDropdownVisible && (
                      <div class="responsive-background" data-select2-id="9">
                        <div
                          class="collapse navbar-collapse"
                          id="navbarSupportedContent"
                          data-select2-id="navbarSupportedContent">
                          <div className="row"></div>
                          <div className="row">
                            <div className="col-md-12 col-xl-12 mb-0">
                              <h5>Search</h5>
                              <div className="row">
                                <div className="col-md-12 col-xl-12 mb-3">
                                  <b>By Date:</b>
                                </div>

                                <div className="col-md-12 col-xl-6 mb-0">
                                  <div className="form-group">
                                    {" "}
                                    <label className="form-label">From:</label>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <div
                                          className="input-group-text"
                                          style={{ height: "100%" }}>
                                          <i className="fe fe-calendar"></i>{" "}
                                        </div>
                                      </div>
                                      <input
                                        type="date"
                                        value={startDate}
                                        onChange={(e) =>
                                          setStartDate(e.target.value)
                                        }
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 col-xl-6 mb-0">
                                  <div className="form-group">
                                    {" "}
                                    <label className="form-label">To:</label>
                                    <div className="input-group">
                                      <div className="input-group-prepend">
                                        <div
                                          className="input-group-text"
                                          style={{ height: "100%" }}>
                                          <i className="fe fe-calendar"></i>{" "}
                                        </div>
                                      </div>
                                      <input
                                        type="date"
                                        value={endDate}
                                        onChange={(e) =>
                                          setEndDate(e.target.value)
                                        }
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="form-group col-xl-6 mt-2">
                                  <b>By User:</b>
                                  <br />
                                  <Select
                                    onChange={(e) => setUser(e.id)}
                                    getOptionLabel={(option) =>
                                      option.first_name + " " + option.last_name
                                    }
                                    getOptionValue={(option) => option.id}
                                    isSearchable
                                    options={
                                      Array.isArray(userList) ? userList : []
                                    }
                                    value={
                                      Array.isArray(userList) &&
                                      userList.find(
                                        (value) => value.id === user
                                      )
                                    }
                                  />
                                </div>

                                {/* <div className='form-group col-xl-6 mt-2'>
                                    <b>By Task:</b><br/>
                                    <Select
                                      onChange={(e)=>setTask(e.task.id)}
                                      getOptionLabel ={(option)=>option.task.task_name}
                                      getOptionValue ={(option)=>option.id}
                                      isSearchable
                                      options={Array.isArray(taskList) ? taskList:[]}                       
                                      value={Array.isArray(taskList) && taskList.find(( value ) => value.task.id===task)}
                                  />
                                  </div> */}
                              </div>
                            </div>

                            <div className="col-md-12 col-xl-12 mb-5">
                              <div
                                className="form-group "
                                style={{ marginTop: "32px" }}>
                                {" "}
                                <a
                                  href="#"
                                  onClick={searchPaymentTaskPayments}
                                  className="btn bg-primary btn-block color-white"
                                  style={{
                                    color: "white",
                                    display: "block",
                                    width: "100%",
                                  }}>
                                  Search Payments
                                </a>{" "}
                              </div>
                              <div
                                className="form-group"
                                style={{ marginTop: "32px" }}>
                                {" "}
                                <a
                                  href="#"
                                  onClick={resetValues}
                                  className="btn bg-light btn-block color-white"
                                  style={{
                                    color: "black",
                                    display: "block",
                                    width: "100%",
                                  }}>
                                  Reset
                                </a>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="border-top mt-5"></div>
                    <div className="table-responsive">
                      <table
                        className="table table1 table-hover text-nowrap mg-b-0"
                        style={{ marginBottom: 350 }}>
                        <thead>
                          <tr>
                            <th scope="col">#NO</th>
                            <th scope="col">User</th>
                            <th scope="col">Amount</th>
                            <th scope="col"> Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paymentsSearch && Array.isArray(paymentsSearch) ? (
                            paymentsSearch.length > 0 ? (
                              paymentsSearch.map((item, key) => (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>
                                    {item.employee.first_name +
                                      " " +
                                      item.employee.last_name}
                                  </td>
                                  <td>{item.amount}</td>
                                  <td>{item.created_at.short_date}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="7">No payments found.</td>
                              </tr>
                            )
                          ) : (
                            Array.isArray(taskPayments) &&
                            taskPayments.map((item, key) => (
                              <tr key={key}>
                                <td>{key + 1}</td>
                                <td>
                                  {item.employee.first_name +
                                    " " +
                                    item.employee.last_name}
                                </td>

                                <td>{item.amount}</td>
                                <td>{item.created_at.short_date}</td>
                              </tr>
                            ))
                          )}

                          <tr>
                            <td colSpan="2">
                              <strong>Total</strong>
                            </td>
                            <td>
                              <strong>{totalCredit}</strong>
                            </td>

                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                      {/* {!payments && <Loader/>} */}
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>

      <div className="col-lg-12"></div>
    </AppContainer>
  );
}

export default PaymentReports;
