import React, { useEffect } from "react";
import AppContainer from "../Structure/AppContainer";
import { useState } from "react";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";

import useStateCallback from "../../util/customHooks/useStateCallback";
import Loader from "../Common/Loader";
import CreateInvoicableCenter from "./CreateInvoicableCenter";
import InvoicablesUpdater from "./InvoicablesUpdater";

function InvoicableCenter() {
  const [loading, setLoading] = useState(false);
  const [cost_Allocations, setCost_allocations] = useState("");
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState("");
  const [search_p, setSearch_p] = useState("");

  const getInvoicables = async () => {
    var data = {
      page: page,
      search: search_p,
    };

    const server_response = await ajaxAccounting.getInvoicables(data);
    if (server_response.status === "OK") {
      setMeta(server_response.details.meta.list_of_pages);
      setCost_allocations(server_response.details.list);
    } else {
      setCost_allocations("404");
      toast.error(server_response.message);
    }
  };
  useEffect(() => {
    getInvoicables();
  }, [page, search_p]);

  const [modal, setModal] = useStateCallback(false);

  const handleModal = () => {
    setModal(false, () =>
      setModal(
        <CreateInvoicableCenter isOpen={true} function={getInvoicables} />
      )
    );
  };
  const [updates, setUpdates] = useStateCallback(false);

  const Update_cost_item = (e, data) => {
    setUpdates(false, () =>
      setUpdates(
        <InvoicablesUpdater
          data={data}
          isOpen={true}
          function={getInvoicables}
        />
      )
    );
  };
  const setNextPageNumber = () => {
    if (meta.length === page) {
    } else {
      setPage(page + 1);
    }
  };

  const setPreviousPageNumber = () => {
    if (page === 1) {
    } else {
      setPage(page - 1);
    }
  };
  const setPageNumber = (e, item) => {
    e.preventDefault();
    setPage(item);
  };
  const delete_cost_item = async (e, item_id) => {
    e.preventDefault();
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (isConfirmed) {
      var dataa = {
        item_id: item_id,
      };
      const server_response = await ajaxAccounting.deactivateInvoicable(dataa);

      if (server_response.status === "OK") {
        getInvoicables();
        toast.success(server_response.message);
      } else {
        toast.error(server_response.message);
      }
    }
  };

  return (
    <AppContainer title="accounts/Invoicables">
      <div className="row">
        <Toaster />
        {modal}
        {updates}
        <div
          className="col-lg-12 col-md-12"
          style={{ marginRight: "-5px", marginLeft: "-5px" }}>
          <div
            className="col-lg-12 col-md-12 col-sm-12"
            style={{ paddingRight: "5px", paddingLeft: "5px" }}>
            <div className="card mb-1" style={{ borderRadius: ".55rem" }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 col-xl-12 mb-0">
                    <div className="row">
                      <div className="form-group col-lg-6 col-md-6 mt-1">
                        <b>Search Invoicables:</b>
                        <br />
                        <input
                          type="text"
                          value={search_p}
                          onChange={(e) => setSearch_p(e.target.value)}
                          className="form-control"
                          placeholder="search invoicables"
                        />
                      </div>

                      <div className="form-group col-lg-3 col-md-3 mt-4">
                        <div className="input-group-append">
                          <button
                            onClick={() => setSearch_p("")}
                            style={{ borderRadius: "10px" }}
                            className="btn ripple btn-primary btn-with-icon">
                            <i class="fa-solid fa-arrow-rotate-left"></i>
                            Reset
                          </button>
                        </div>
                      </div>
                      <div className="form-group col-lg-3 col-md-3 mt-4">
                        <button
                          onClick={handleModal}
                          style={{
                            borderRadius: "10px",
                          }}
                          disabled={loading}
                          className="btn ripple btn-primary btn-with-icon btn-sm">
                          <i className="fe fe-plus"></i> Add Invoicable
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12">
          {/* row */}
          <div className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="card-title mb-4"> Invoicables </h6>
              </div>
              <div className="table-responsive">
                <table
                  className="table table-striped table-bordered"
                  style={{ border: "orange 3px groove" }}>
                  <thead>
                    <th>#</th>
                    <th>Item Name</th>
                    <th>Unit</th>
                    <th style={{ width: "300px" }}>Action center </th>
                  </thead>
                  <tbody>
                    {Array.isArray(cost_Allocations) &&
                      cost_Allocations.map((item, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.unit}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                width: "300px",
                              }}
                              class="btn btn-list">
                              <button
                                style={{
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  height: "25px",
                                  margin: "5px",
                                  color: "white",
                                }}
                                onClick={(e) => Update_cost_item(e, item)}
                                className="btn ripple btn-warning  btn-outline-success btn-with-icon"
                                to="#">
                                <i class="fa-solid fa-trash-can-arrow-up"></i>
                                &nbsp;
                                <span className="sidemenu-label">Update</span>
                              </button>
                              <button
                                style={{
                                  borderRadius: "10px",
                                  fontSize: "14px",
                                  height: "25px",
                                  margin: "5px",
                                  color: "white",
                                  background:
                                    "linear-gradient(to right, #FF7F50, #FF6347)",
                                }}
                                onClick={(e) => delete_cost_item(e, item.id)}
                                className="btn ripple btn-outline-success btn-with-icon"
                                to="#">
                                <i class="fa-solid fa-pen-to-square"></i>
                                &nbsp;
                                <span className="sidemenu-label">
                                  Deactivate
                                </span>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}

                    {loading && <Loader />}
                    {cost_Allocations === "404" && (
                      <>
                        <tr>
                          <td colSpan={5} className="text-info">
                            <p className="text-center text-info">
                              No Invoicables item found in the system
                            </p>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                <nav className="mt-3" style={{ marginLeft: "30px" }}>
                  <ul className="pagination ">
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={setPreviousPageNumber}>
                        Prev
                      </button>
                    </li>

                    {Array.isArray(meta) &&
                      meta.map((item) =>
                        page === item ? (
                          <li className="page-item active">
                            <a className="page-link" href="javascript:void(0);">
                              {item}
                            </a>
                          </li>
                        ) : (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#"
                              onClick={(e) => setPageNumber(e, item)}>
                              {item}
                            </a>
                          </li>
                        )
                      )}

                    <li className="page-item">
                      <button className="page-link" onClick={setNextPageNumber}>
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        {/* end row  */}
      </div>
    </AppContainer>
  );
}

export default InvoicableCenter;
