import { useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";
import ajaxClient from "../../util/remote/ajaxClient";

const CreateLedger = (props) => {
  const [loading, setLoading] = useState(false);
  const [legder, setLedger] = useState("");
  const [ledgerType, setLedgerType] = useState("");
  const [clientID, setClientID] = useState("");
  const [unit, setUnit] = useState("");

  const [clients, setClients] = useState("");
  const getClients = async () => {
    const server_response = await ajaxClient.fetchProjectClientList_min_data();
    if (server_response.status === "OK") {
      setClients(server_response.details);
    } else {
      setClients("404");
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  const handleAdd = async (e, controls) => {
    e.preventDefault();
    if (legder.trim() === "" || ledgerType.trim() === "") {
      toast.error("Please complete all fields.");
    } else {
      setLoading(true);

      var data = {
        ledgerType: ledgerType,
        legder: legder,
        clientID: clientID,
        unit: unit,
      };
      const server_response = await ajaxAccounting.createLedger(data);

      setLoading(false);
      if (server_response.status === "OK") {
        controls.close();
        toast.success(server_response.message);
        resetForm();
        props.function();
      } else {
        toast.error(server_response.message);
      }
    }
  };

  // fetching ledge types
  const [ledgerTypes, setLedgerTypes] = useState("");
  const getLedgerTypes = async () => {
    const server_response = await ajaxAccounting.getLedgerTypes();
    if (server_response.status === "OK") {
      setLedgerTypes(server_response.details);
    } else {
      setLedgerTypes("404");
    }
  };

  useEffect(() => {
    getLedgerTypes();
  }, []);

  const resetForm = () => {
    setLedger("");
    setLedgerType("");
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            disabled={loading}
            className={`btn ripple btn-success`}
            onClick={(e) => handleAdd(e, controls)}>
            Save Ledger
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Add New Ledger"
      id="model-new-ledger"
      size="lg"
      footer={RenderFooter}>
      <div className="row">
        <div className="mb-4 col-md-6">
          <label htmlFor="">
            Ledger Name:<span className="tx-danger">*</span>
          </label>
          <input
            type="text"
            value={legder}
            onChange={(e) => setLedger(e.target.value)}
            className="form-control"
            placeholder="enter Ledger name"
          />
        </div>
        <div className="mb-4 col-md-6">
          <label htmlFor="">
            select Ledger Group:<span className="tx-danger">*</span>
          </label>
          <Select
            onChange={(e) => setLedgerType(e.id)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isSearchable
            options={Array.isArray(ledgerTypes) ? ledgerTypes : []}
            value={
              Array.isArray(ledgerTypes) &&
              ledgerTypes.find((value) => value.id === ledgerType)
            }
          />
        </div>
        <div className="form-group col-md-6 col-lg-6 mt-1">
          <b>Select Client:</b>
          <br />
          <Select
            onChange={(e) => setClientID(e.id)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isSearchable
            options={clients}
            value={
              clientID
                ? Array.isArray(clients) &&
                  clients.find((value) => value.id === clientID)
                : ""
            }
          />
        </div>
        <div className="mb-4 col-md-6">
          <label htmlFor="">
            Unit:<span className="tx-danger"></span>
          </label>
          <input
            type="text"
            value={unit}
            onChange={(e) => setUnit(e.target.value)}
            className="form-control"
            placeholder="enter Ledger name"
          />
        </div>
      </div>
    </SystemModal>
  );
};

export default CreateLedger;
