import React, { useEffect } from "react";
import AppContainer from "../Structure/AppContainer";
import { useState } from "react";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";

function LedgerTypes() {
  const [name, setName] = useState("");
  const [entry, setEntry] = useState("");
  const [main_Account_ID, setMain_Account_ID] = useState("");
  const [loading, setLoading] = useState(false);

  const [mainACs, setMainAcs] = useState("");

  const getMainAccounts = async () => {
    const server_response = await ajaxAccounting.getMainAccounts();
    if (server_response.status === "OK") {
      setMainAcs(server_response.details);
    } else {
      setMainAcs("404");
    }
  };

  const [ledgerTypes, setLedgerTypes] = useState("");
  const getLedgerTypes = async () => {
    const server_response = await ajaxAccounting.getLedgerTypes();
    if (server_response.status === "OK") {
      setLedgerTypes(server_response.details);
    } else {
      setLedgerTypes("404");
    }
  };

  useEffect(() => {
    getMainAccounts();
    getLedgerTypes();
  }, []);

  const handleSubmission = async (e) => {
    e.preventDefault();
    // Form validation
    if (
      name.trim() === "" ||
      entry.trim() === "" ||
      main_Account_ID.trim() === ""
    ) {
      toast.error("Please complete all fields.");
    } else {
      setLoading(true);
      try {
        var data = {
          ledgerTypes: name,
          entry_type: entry,
          main_Account: main_Account_ID,
        };
        const server_response = await ajaxAccounting.createLedgerType(data);
        setLoading(false);
        if (server_response.status === "OK") {
          toast.success(server_response.message);
          getLedgerTypes();

          setName("");
          setEntry("");
          setMain_Account_ID("");
        } else {
          toast.error(server_response.message);
        }
      } catch (error) {
        setLoading(false);
        toast.error("An error occurred. Please try again later.");
      }
    }
  };

  return (
    <AppContainer title="Ledger Types List">
      <div className="row">
        <Toaster />
        {/* row */}
        <div className="col-lg-12 col-md-12">
          <div className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="card-title mb-4">Add Ledger Group</h6>
              </div>
              <form onSubmit={(e) => handleSubmission(e)} method="post">
                <div className="row">
                  <div className="mb-4 col-md-4">
                    <label htmlFor="">
                      Account Name:<span className="tx-danger">*</span>
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      placeholder="enter Account group name"
                    />
                  </div>

                  <div className="mb-4 col-md-4">
                    <label htmlFor="">
                      Entry Type:<span className="tx-danger">*</span>
                    </label>
                    <select
                      value={entry}
                      onChange={(e) => setEntry(e.target.value)}
                      class="form-control">
                      <option selected disabled value="">
                        --select--entryType--
                      </option>
                      <option value="credit">credit</option>
                      <option value="Debit">Debit</option>
                    </select>
                  </div>

                  <div className="mb-4 col-md-4">
                    <label htmlFor="">
                      select Main Group:<span className="tx-danger">*</span>
                    </label>
                    <Select
                      onChange={(e) => setMain_Account_ID(e.id)}
                      getOptionLabel={(option) => option.account_name}
                      getOptionValue={(option) => option.id}
                      isSearchable
                      options={Array.isArray(mainACs) ? mainACs : []}
                      value={
                        main_Account_ID
                          ? Array.isArray(mainACs) &&
                            mainACs.find(
                              (value) => value.id === main_Account_ID
                            )
                          : ""
                      }
                    />
                  </div>

                  <div className="mb-4 col-md-12"></div>
                  <div className="mb-4 col-md-12">
                    {loading && (
                      <button
                        disabled
                        style={{ width: "100%" }}
                        className="btn btn-success">
                        Adding...
                      </button>
                    )}

                    {!loading && (
                      <button
                        type="submit"
                        disabled={loading}
                        style={{ width: "100%" }}
                        className="btn btn-success">
                        Add Ledger type
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* end row  */}

        {/* row */}
        <div className="col-lg-12 col-md-12">
          <div className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="card-title mb-4"> Ledger Groups </h6>
              </div>
              <div className="table-responsive">
                <table
                  className="table table-striped table-bordered"
                  style={{ border: "orange 3px groove" }}>
                  <thead>
                    <th>#</th>
                    <th>
                      Ledger Group <br /> Name
                    </th>
                    <th>Entry Type</th>
                    <th>
                      Main Acconut <br /> Group
                    </th>
                  </thead>
                  <tbody>
                    {/* "id": "1", "name": "test account", "entry_type": "credit",
                    "main_groupID": "1", "main_account": "Balancesheet" */}

                    {Array.isArray(ledgerTypes) &&
                      ledgerTypes.map((item, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.entry_type}</td>
                          <td>{item.main_account}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        {/* end row  */}
      </div>
    </AppContainer>
  );
}

export default LedgerTypes;
