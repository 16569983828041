import { useContext, useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxClient from "../../util/remote/ajaxClient";

const ClientMoreDetails = (props) => {
  const [loading, setLoading] = useState(false);
  const [p_o_b, setP_O_B] = useState("");
  const [location, setLocation] = useState("");
  const [clientID, setClientID] = useState(props.data.client_id);

  const resetForm = () => {
    setP_O_B("");
    setLocation("");
  };

  const handleAdd = async (e, controls) => {
    e.preventDefault();
    if (!p_o_b.trim() || !location.trim()) {
      toast.error("Please complete all required fields.");
    } else {
      const data = {
        p_o_b: p_o_b,
        clientID: clientID,
        location: location,
      };
      setLoading(true);

      const server_response = await ajaxClient.CreateUserMoreDetails(data);

      setLoading(false);
      if (server_response.status === "OK") {
        resetForm();
        controls.close();
        toast.success(server_response.message);
      } else {
        toast.error(server_response.message);
      }
    }
  };

  const RenderFooter = (controls) =>
    loading ? (
      <Loader />
    ) : (
      <>
        <button
          className="btn ripple btn-dark"
          type="button"
          onClick={controls.close}>
          Close
        </button>
        <button
          type="button"
          disabled={loading}
          className="btn ripple btn-success"
          onClick={(e) => handleAdd(e, controls)}>
          Save changes
        </button>
      </>
    );

  return (
    <SystemModal
      title="Add Transaction on a Voucher"
      id="model-new-ledger"
      size="lg"
      footer={RenderFooter}>
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div
                className="card custom-card"
                style={{ borderRadius: "10px" }}>
                <div className="card-body">
                  <div>
                    <h6 className="card-title mb-4">Add Client Details</h6>
                  </div>

                  <form onSubmit={(e) => handleAdd(e)} method="post">
                    <div className="row">
                      <div className="mb-4 col-md-6">
                        <label>
                          P.O. Box Address{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          value={p_o_b}
                          onChange={(e) => setP_O_B(e.target.value)}
                          className="form-control"
                          placeholder="e.g. P.O. Box 111 Kayunga"
                        />
                      </div>
                      <div className="mb-4 col-md-12">
                        <label>
                          Location <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                          className="form-control"
                          placeholder="e.g. Plot 16B Kayunga Road"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SystemModal>
  );
};

export default ClientMoreDetails;
