import React, { useContext, useEffect, useState } from "react";
import DataTable from "datatables.net";
import AppContainer from "../Components/Structure/AppContainer";
import Table from "../Components/Common/Table";
import ModalComponent from "../Components/Common/Modal";
import $ from "jquery";
import PermissionContext from "../Context/PermissionContext";
import RoleContext from "../Context/RoleContext";
import UserContext from "../Context/UserContext";
import Alert from "../Components/Common/Alert";
import ajaxPermission from "../util/remote/ajaxPermission";
import ajaxRolePermissionDistribution from "../util/remote/ajaxRolePermissionDistribution";
import CreatePermission from "../Components/Permission/CreatePermission";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import ajaxPermissionDistribution from "../util/remote/ajaxPermissionDistribution";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AuthContext from "../Context/AuthContext";
import Loader from "../Components/Common/Loader";

export default function Permissions(props) {
  const { users, userId, permissionLists, getUserAccess } =
    useContext(AuthContext);

  const { roleList, getRoleList } = useContext(RoleContext);
  const { userList, getUserList } = useContext(UserContext);

  const { permissionList, getPermissionList } = useContext(PermissionContext);

  const [loading, setLoading] = useState(true);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [selectedRows, setSelectedRows] = React.useState([]);

  const [info, setInfo] = useState("");
  const [role, setRole] = useState("");
  const [user, setUser] = useState("");

  const [check, setCheck] = useState(false);
  const [checkvalues, setCheckValues] = useState([]);

  const [permission_id, setPermissionID] = useState("");

  const [permission_code, setPermissionCode] = useState("");
  const [permission_description, setPermissionDescription] = useState("");

  const updatePermission = (e, params) => {
    setShow1(true);
    setPermissionID(params.row.permission_id);
    setPermissionCode(params.row.permission_code);
    setPermissionDescription(params.row.permission_description);
  };

  const columns = [
    { field: "permission_id", headerName: "ID", width: 50 },
    { field: "permission_code", headerName: "Code", width: 250 },
    { field: "permission_description", headerName: "Description", width: 350 },
  ];

  const handlePermissionUpdate = async (e) => {
    e.preventDefault();
    if (
      permission_id > 0 &&
      permission_description.length > 0 &&
      permission_code.length > 0
    ) {
      const server_response = await ajaxPermission.updatePermission(
        permission_id,
        permission_code,
        permission_description
      );

      if (server_response.status === "OK") {
        setPermissionDescription("");
        setPermissionCode("");
        setPermissionID("");
        setInfo(<Alert type="success" message={server_response.message} />);
        getPermissionList();
      }
    } else {
      setInfo(
        <Alert type="danger" message="Complete all fields and try again" />
      );
    }
  };

  const handleRolePermission = async (e) => {
    e.preventDefault();
    if (role > 0 && checkvalues.length > 0) {
      checkvalues.forEach(async (data) => {
        const server_response =
          await ajaxRolePermissionDistribution.createRolePermissionDistribution(
            data,
            role,
            1
          );

        if (server_response.status === "OK") {
          setRole("");
          setInfo(<Alert type="success" message={server_response.message} />);
        }
      });
    } else {
      setInfo(
        <Alert type="danger" message="Complete all fields and try again" />
      );
    }
  };

  const handleUserPermission = async (e) => {
    e.preventDefault();
    if (user > 0 && checkvalues.length > 0) {
      checkvalues.forEach(async (data) => {
        const server_response =
          await ajaxPermissionDistribution.createPermissionDistribution(
            data,
            user,
            1
          );

        if (server_response.status === "OK") {
          setUser("");
          setInfo(<Alert type="success" message={server_response.message} />);
        }
      });
    } else {
      setInfo(
        <Alert type="danger" message="Complete all fields and try again" />
      );
    }
  };

  return (
    <AppContainer title="Permissions">
      <div className="white_card card_height_100 mb_30 shadow">
        {check ? (
          <div className="row mt-3">
            {info}

            <div className="col-md-6">
              <form onSubmit={(e) => handleRolePermission(e)}>
                <div className="input-group mb-3">
                  <select
                    style={{ float: "right" }}
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    className="form-select mr-3"
                    id="inputGroupSelect01">
                    <option selected="">By Role...</option>
                    {Array.isArray(roleList) &&
                      roleList.map((option) => (
                        <option value={option.role_id}>
                          {option.role_name}
                        </option>
                      ))}
                  </select>
                  <button
                    style={{ float: "right" }}
                    type="submit"
                    className="btn btn-outline-success">
                    Save
                  </button>
                </div>
              </form>
            </div>

            <div className="col-md-6 d-block">
              <form action="" onSubmit={(e) => handleUserPermission(e)}>
                <div className="input-group mb-3">
                  <select
                    style={{ float: "right" }}
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                    className="form-select ml-3"
                    id="inputGroupSelect01">
                    <option selected="">By User...</option>
                    {Array.isArray(userList) &&
                      userList.map((option) => (
                        <option value={option.id}>{option.first_name}</option>
                      ))}
                  </select>
                  <button
                    style={{ float: "right" }}
                    type="submit"
                    className="btn btn-outline-success">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="white_card_header">
          <div className="box_header "></div>
        </div>
        <div className="white_card_body">
          <div className="QA_section">
            <div className="white_box_tittle list_header">
              <h4>Permissions List</h4>

              <div className="box_right d-flex lms_block">
                <div className="add_button ms-2">
                  <div className="col-md-12 mb-3"></div>
                </div>
              </div>
            </div>
            <div className="QA_table mb_30">
              <div style={{ height: 630, width: "102%" }}>
                <DataGrid
                  rows={permissionList}
                  columns={columns}
                  getRowId={(row) => row.permission_id}
                  // pageSize={5}
                  initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                  }}
                  pageSizeOptions={[10, 15, 20, 25]}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  disableSelectionOnClick
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={(data) => {
                    if (data.length === 0) {
                      setCheck(false);
                    } else if (data) {
                      setCheck(true);
                      setCheckValues(data);
                    }
                  }}
                  disableColumnFilter
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                    },
                    filterPanel: {
                      disableAddFilterButton: true,
                      disableRemoveAllButton: true,
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* } */}
    </AppContainer>
  );
}
