import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import PageHeader from "./PageHeader";

import AccountingNav from "./AccountingNav";
import { RenderSecure } from "../../util/script/RenderSecure";

export default function AppContainer(props) {
  return (
    <div className="page" style={{ overflowY: "auto" }}>
      <Sidebar />

      <div className="main-content side-content pt-0">
        <Header />

        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="row">
                <div className="col-lg-12">
                  <PageHeader title={props.title} />
                </div>
                {/* accounting nav */}
                <RenderSecure code="VIEW-ACCOUNTS">
                  <div className="col-lg-12">
                    <AccountingNav />
                  </div>
                </RenderSecure>

                {/* end account nav  */}
              </div>

              <div className="col-lg-12">{props.children}</div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
