import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import ajaxProject from "../../util/remote/ajaxProject";
import Loader from "../../Components/Common/Loader";
import toast, { Toaster } from "react-hot-toast";
//new
import ajaxTeam from "../../util/remote/ajaxTeam";
import Select from "react-select";
import UserContext from "../../Context/UserContext";
import ajaxProduct from "../../util/remote/ajaxProduct";

function PolePlanting(props) {
  const [status, setStatus] = useState("");
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [pole, setPole] = useState(false);
  const [picture, setPicture] = useState("");
  const [description, setDescription] = useState("");
  /////new
  const [teamList, setTeamList] = useState(false);
  const [teamId, setTeamId] = useState("");
  const { userList } = useContext(UserContext);
  const [user, setUser] = useState("");

  const { id } = useParams();
  const [product, setProduct] = useState("");
  const [productList, setProductList] = useState(false);

  const getProducts = async () => {
    const server_response = await ajaxProduct.fetchProductList();

    if (server_response.status === "OK") {
      setProductList(server_response.details);
    }
  };

  const handlePitDetails = async (e) => {
    e.preventDefault();
    if (id > 0 && status.length > 0) {
      if (status === "INSTALLED" && date.length < 1) {
        toast.error("Select the installation date to proceed!");
      } else {
        setLoading(true);
        const server_response = await ajaxProject.updatePoleStatus(
          id,
          status,
          date,
          teamId,
          user,
          picture,
          description,
          product
        );
        setLoading(false);
        if (server_response.status === "OK") {
          setStatus("");
          setDate("");
          toast.success(server_response.message);
          props.navi(`/installations/create/${id}#planting`);
        }
      }
    } else {
      toast.error(
        "Please fill in all required fields except the images which !"
      );
    }
  };

  const changePicture = (e) => {
    e.preventDefault();

    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const newItem = { file: e.target.result };

      setPicture(e.target.result);
    };
  };

  ///new
  useEffect(() => {
    getTeams();
  }, []);
  useEffect(() => {
    getProducts();
  }, []);

  const getTeams = async () => {
    const server_response = await ajaxTeam.getTeamList();

    if (server_response.status === "OK") {
      setTeamList(server_response.details);
    }
  };

  useEffect(() => {
    getPole();
  }, [id]);

  const getPole = async () => {
    setLoading(true);
    const server_response = await ajaxProject.getPoleInfo(id);
    setLoading(false);

    if (server_response.status === "OK") {
      setPole(server_response.details);
      setDate(server_response.details.install_date);
      setStatus(server_response.details.status);
      setPicture(server_response.details.image2);
    } else {
      setPole(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Toaster />
      {pole && (
        <form onSubmit={(e) => handlePitDetails(e)}>
          <br />
          <Link
            onClick={() => props.navi(-1)}
            className="btn btn-primary btn-sm mb-2">
            Back To Previous Page
          </Link>
          <h6>
            <small className="text-muted">Pole ID : </small>
            {pole.pole_id}
          </h6>

          <br />
          <div className="form-group mt-3">
            <div className="mb-4 col-md-12">
              <label>
                <b>Pole Planting Status</b>
              </label>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                required
                className="form-control">
                <option value="">--- select status ---</option>
                <option value="NOT INSTALLED">NOT INSTALLED</option>
                <option value="INSTALLED">INSTALLED</option>
                <option value="ROW">ROW</option>
                <option value="FROZEN">FROZEN</option>
                <option value="ELIMINATED">ELIMINATED</option>
                <option value="ROCK">ROCK</option>
                <option value="SWAMP">SWAMP</option>
                <option value="POLE RELOCATION">POLE RELOCATION</option>
              </select>
            </div>
            <div className="mb-4 col-md-12">
              <label htmlFor="">Product Type</label>
              <Select
                onChange={(e) => setProduct(e.id)}
                getOptionLabel={(option) => option.product_name}
                getOptionValue={(option) => option.id}
                isSearchable
                options={Array.isArray(productList) ? productList : []}
                value={
                  Array.isArray(productList) &&
                  productList.find((value) => value.id === product)
                }
              />
            </div>

            <div className="mb-4 col-md-12">
              <label>
                <b>Date</b>
              </label>
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                className="form-control"
              />
            </div>
          </div>
          {/* ////new */}
          <div className="mb-4 col-md-12">
            <label htmlFor="">
              <b>Installed By:</b>
            </label>
            <Select
              onChange={(e) => setTeamId(e.team_id)}
              getOptionLabel={(option) => option.team_name}
              getOptionValue={(option) => option.team_id}
              isSearchable
              options={Array.isArray(teamList) ? teamList : []}
              value={
                Array.isArray(teamList) &&
                teamList.find((value) => value.team_id === teamId)
              }
            />
          </div>
          <div className="row">
            <div className="mb-4 col-6">
              <label>
                <b>Select image </b>
              </label>
              <input
                type="file"
                onChange={(e) => changePicture(e)}
                multiple
                className="form-control"
              />
            </div>
            <div className="mb-4 col-6">
              <label>
                <b>Image Preview </b>
              </label>
              <img
                alt="Planting Image"
                style={{ height: "25%" }}
                class="img-thumbnail"
                src={picture}
              />
            </div>
          </div>
          <div className="mb-4 col-md-12">
            <label>
              <b>Image Description</b>
            </label>
            <textarea
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiple
              className="form-control"
            />
          </div>
          <div className="mb-4 col-md-12">
            <label htmlFor="">
              <b>Approved By:</b>
            </label>
            <Select
              onChange={(e) => setUser(e.id)}
              getOptionLabel={(option) =>
                `${option.first_name} ${option.last_name}`
              }
              getOptionValue={(option) =>
                `${option.first_name} ${option.last_name}`
              }
              isSearchable
              options={userList}
              value={
                Array.isArray(userList) &&
                userList.find((value) => value.id === user)
              }
            />
          </div>
          <div className="mt-2 mb-2">
            <button type="submit" className="btn btn-success w-100">
              Update
            </button>
          </div>
        </form>
      )}
      {!pole && <h5>Pole not found</h5>}
    </>
  );
}

export default PolePlanting;
