import { useContext, useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";
import AuthContext from "../../Context/AuthContext";

const AddTransactionOnTransaction = (props) => {
  var datar = props.data;
  const [cost_items, setCost_items] = useState(props.cost_items);
  const [projects, setProjects] = useState(props.projects);
  const [ledgers, setLedgers] = useState(props.ledgers);

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("create_Budget_expense");
  const [amount, setAmount] = useState("");
  const [projectID, setProjectID] = useState("");
  const [LedgerID_credited, setLedgerID_credited] = useState("");
  const [LedgerID_debited, setLedgerID_debited] = useState("");
  const [description, setDescription] = useState("");
  const [cost_item, setCost_item] = useState("");
  const [date, setDate] = useState("");
  const [reciever, setReciever] = useState("");
  const [employee, setEmployee] = useState("");
  const [trans_type, setTrans_type] = useState(props.type);
  const [Supplier_ivoice_reff, setSupplier_ivoice_reff] = useState("");
  const [voucher_no, setVoucher_no] = useState("");

  const { user, userId } = useContext(AuthContext);

  // Get today's date in the format YYYY-MM-DD
  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setDate(today);
  }, []);

  const resetForm = () => {
    setAmount("");
    setProjectID("");
    setLedgerID_credited("");
    setLedgerID_debited("");
    setDescription("");
    setCost_item("");
    setReciever("");
    setEmployee("");
    setSupplier_ivoice_reff("");
    setVoucher_no("");
  };
  const handleAdd = async (e, controls) => {
    e.preventDefault();
    if (
      reciever.trim() === "" ||
      amount.trim() === "" ||
      date.trim() === "" ||
      description.trim() === ""
    ) {
      toast.error("Please complete the form.");
    } else {
      if (datar.ledgerID_credited.id === LedgerID_debited) {
        toast.error(
          "the selected ledger for debiting should not be " +
            datar.ledgerID_credited.Ledger_name +
            " because it's the one being credited"
        );
      } else {
        var data = {
          folioID: datar.folioID,
          amount: amount,
          projectID: datar.projectID.id,
          LedgerID_debited: LedgerID_debited,
          LedgerID_credited: datar.ledgerID_credited.id,
          cost_item: cost_item,
          date: date,
          reciever: reciever,
          employee: employee,
          created_by: userId,
          trans_type: trans_type,
          description: description,
          Supplier_ivoice_reff: Supplier_ivoice_reff,
          voucher_no: voucher_no,
        };
        setLoading(true);

        const server_response = await ajaxAccounting.CreatePaymentVoucher(data);

        setLoading(false);
        if (server_response.status === "OK") {
          resetForm();
          controls.close();
          toast.success(server_response.message);
          props.function(); // Call the passed function
        } else {
          toast.error(server_response.message);
        }
      }
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            disabled={loading}
            className={`btn ripple btn-success`}
            onClick={(e) => handleAdd(e, controls)}>
            Save changes
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Add Transction on a Voucher"
      id="model-new-ledger"
      size="lg"
      footer={RenderFooter}>
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div
                className="card custom-card"
                style={{ borderRadius: "10px" }}>
                <div className="card-body">
                  <div>
                    <h6 className="card-title mb-4">Add Payment Voucher</h6>
                  </div>

                  <form onSubmit={(e) => handleAdd(e)} method="post">
                    <div className="row">
                      <div className="mb-4 col-md-6">
                        <label htmlFor="">
                          select project
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          onChange={(e) => setProjectID(e.id)}
                          getOptionLabel={(option) => option.project_name}
                          getOptionValue={(option) => option.id}
                          isSearchable
                          options={projects}
                          value={
                            projectID
                              ? Array.isArray(projects) &&
                                projects.find((value) => value.id === projectID)
                              : ""
                          }
                        />
                      </div>
                      <div className="mb-4 col-md-6">
                        <label htmlFor="">
                          Select Account To Debit
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          onChange={(e) => setLedgerID_debited(e.id)}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isSearchable
                          options={Array.isArray(ledgers) ? ledgers : []}
                          value={
                            LedgerID_debited
                              ? Array.isArray(ledgers) &&
                                ledgers.find(
                                  (value) => value.id === LedgerID_debited
                                )
                              : ""
                          }
                        />
                      </div>

                      <div className="mb-4 col-md-6">
                        <label htmlFor="">cost allocation center</label>
                        <Select
                          onChange={(e) => setCost_item(e.id)}
                          getOptionLabel={(option) => option.item}
                          getOptionValue={(option) => option.id}
                          isSearchable
                          options={
                            Array.isArray(cost_items) ? cost_items : [""]
                          }
                          value={
                            cost_item
                              ? Array.isArray(cost_items) &&
                                cost_items.find(
                                  (value) => value.id === cost_item
                                )
                              : ""
                          }
                        />
                      </div>
                      <div className="mb-4 col-md-6">
                        <label htmlFor="">
                          Date
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="date"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                          className="form-control"
                        />
                      </div>
                      <div className="mb-4 col-md-6">
                        <label htmlFor="">
                          Amount
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          className="form-control"
                        />
                      </div>

                      <div className="mb-4 col-md-6">
                        <label htmlFor="">
                          Reciever
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          value={reciever}
                          onChange={(e) => setReciever(e.target.value)}
                          className="form-control"
                        />
                      </div>

                      <div className="mb-4 col-md-12">
                        <label htmlFor="">
                          Description
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          className="form-control"></textarea>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-12">
                      <input
                        disabled={loading}
                        type="submit"
                        style={{ borderRadius: "10px" }}
                        className="btn btn-success col-lg-12"
                        value="Save Payment Voucher"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SystemModal>
  );
};

export default AddTransactionOnTransaction;
