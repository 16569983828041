import { ajax } from "jquery";
import React from "react";

export default function PageHeader(props) {
  return (
    <>
      <div className="page-header">
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="#">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {props.title}
            </li>
          </ol>
        </div>
      </div>
    </>
  );
}
