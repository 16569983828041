import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import SystemModal from "../../Components/Common/SystemModal";
import Loader from "../../Components/Common/Loader";
import ajaxDailyOutput from "../../util/remote/ajaxDailyOutput";

const AddTeamPhoto = (props) => {
  const [loading, setLoading] = useState(false);
  const [output_lat, setOutputLat] = useState("");
  const [output_long, setOutputLong] = useState("");
  const [team_photo, setTeamPhoto] = useState("");

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setOutputLat(position.coords.latitude);
      setOutputLong(position.coords.longitude);
    });
  };

  useEffect(() => {
    if (!output_lat || !output_long) {
      getLocation();
    }
  }, [output_lat, output_long]);

  const handleAddPhoto = async (e) => {
    e.preventDefault();

    setLoading(true);

    const server_response = await ajaxDailyOutput.addTeamPhoto(
      props.output_id,
      output_lat,
      output_long,
      team_photo
    );
    setLoading(false);
    if (server_response.status === "OK") {
      toast.success(server_response.message);
      props.f(props.team_id);
    } else {
      toast.error(server_response.message);
    }
  };

  const changeTeamPicture = (e) => {
    e.preventDefault();

    let files = e.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      const newItem = { file: e.target.result };

      setTeamPhoto(e.target.result);
    };
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleAddPhoto}>
            Save Photo
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Add Team Photo"
      id="model-new-photo"
      size="md"
      footer={RenderFooter}>
      <div className="mb-4 col-md-12">
        <label>
          <b>Team Photo </b>
        </label>
        <input
          type="file"
          onChange={(e) => changeTeamPicture(e)}
          multiple
          className="form-control"
        />
      </div>
      <div className="mb-4 col-6">
        <label htmlFor="">
          Output Latitude:<span className="tx-danger">*</span>
        </label>
        <input
          type="text"
          value={output_lat}
          onChange={(e) => setOutputLat(e.target.value)}
          className="form-control"
          readOnly
        />
      </div>
      <div className="mb-4 col-6">
        <label htmlFor="">
          Output Longitude:<span className="tx-danger">*</span>
        </label>
        <input
          type="text"
          value={output_long}
          onChange={(e) => setOutputLong(e.target.value)}
          className="form-control"
          readOnly
        />
      </div>
    </SystemModal>
  );
};

export default AddTeamPhoto;
