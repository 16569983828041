import React, { useEffect, useState } from "react";
import AppContainer from "../Structure/AppContainer";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";
import ajaxProject from "../../util/remote/ajaxProject";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";

function ProjectTrialBalanceReport(props) {
  const [loading, setLoading] = useState(false);
  const [start_date, setStart_date] = useState("");
  const [to_date, setTo_date] = useState("");
  const [projectID, setProjectID] = useState("");
  const [projects, setProjects] = useState([]);
  const [trans_type, setTrans_type] = useState("payment_voucher");
  const [project_details, setProject_details] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [totals, setTotals] = useState({});

  const getProjects = async () => {
    const server_response = await ajaxProject.getProjectsMinnimalDataCliented();
    if (server_response.status === "OK") {
      setProjects(server_response.details);
    } else {
      setProjects([]);
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  const handleAdd = async (e) => {
    e.preventDefault();
    if (
      start_date.trim() === "" ||
      to_date.trim() === "" ||
      projectID.trim() === ""
    ) {
      toast.error("Please complete all fields.");
    } else {
      setLoading(true);

      var data = {
        to_date: to_date,
        start_date: start_date,
        projectID: projectID,
      };
      const server_response = await ajaxAccounting.getProjectTrialBalanceReport(
        data
      );

      setLoading(false);
      if (server_response.status === "OK") {
        setProject_details(server_response.details.project);
        setTransactions(server_response.details.data_list);
        setTotals(server_response.details.trail_bal);
      } else {
        setProject_details("");
        setTransactions([]);
        setTotals({});
      }
    }
  };

  return (
    <div>
      <AppContainer title="Accounts/Ledger_report">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="card custom-card main-content-body-profile">
              <div className="card-body">
                <Toaster />
                <form onSubmit={handleAdd}>
                  <div className="row">
                    <div className="col-md-3">
                      <b>Select Project:</b>
                      <Select
                        onChange={(e) => setProjectID(e.id)}
                        getOptionLabel={(option) => option.project_name}
                        getOptionValue={(option) => option.id}
                        isSearchable
                        options={projects}
                        value={
                          Array.isArray(projects) &&
                          projects.find((value) => value.id === projectID)
                        }
                      />
                    </div>
                    <div className="col-md-3">
                      <b>From:</b>
                      <input
                        type="date"
                        value={start_date}
                        onChange={(e) => setStart_date(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-3">
                      <b>To:</b>
                      <input
                        type="date"
                        value={to_date}
                        onChange={(e) => setTo_date(e.target.value)}
                        className="form-control"
                      />
                    </div>
                    <div className="col-md-3 mt-4">
                      <button
                        type="submit"
                        disabled={loading}
                        className="btn btn-primary">
                        Get Report
                      </button>
                    </div>
                  </div>
                </form>

                <div className="col-lg-12">
                  {project_details && (
                    <>
                      <h1 className="text-center mt-4">
                        {project_details.project_name}
                      </h1>
                      <h4 className="text-center">
                        {" "}
                        Project Trial balance Report
                      </h4>
                      <div className="row mt-4">
                        <table className="table table-striped table-bordered">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Ledger Name</th>
                              <th>Legder Group</th>
                              <th>Total debit</th>
                              <th>Totral Credit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactions.map((ledger, i) => (
                              // },
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>
                                  <Link
                                    className="nav-link"
                                    target="_blank"
                                    to={`/accounts/getLedger_trial_report/${ledger.ledgerID_debited}/${project_details.id}/${start_date}/${to_date}`}>
                                    <i class="fa-regular fa-eye" /> &nbsp;
                                    <span className="sidemenu-label">
                                      {ledger.ledger_name}
                                    </span>
                                  </Link>
                                </td>
                                <td>{ledger.legder_groups}</td>
                                <td>{ledger.balance.balance_c}</td>
                                <td>0</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {/* Display Final Totals */}
                      <hr style={{ borderBottom: "2px dotted black" }} />
                      <div className="col-lg-12">
                        <table className="table table-striped table-bordered">
                          <tr>
                            <td>
                              <b>Total Allocated:</b>
                              {totals.subtotal_allocated}
                            </td>
                            <td></td>
                            <td>
                              <b>Total Balance:</b> {totals.trial_bal_c}
                            </td>
                          </tr>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    </div>
  );
}

export default ProjectTrialBalanceReport;
