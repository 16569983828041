import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async loginUser(username, password) {
    let data = {
      username: username,
      password: password,
    };
    let response = await apiCall("login", data);
    return response;
  },

  async fetchUserList(data) {
    let response = await apiCall("user/list", data);
    return response;
  },
  async fetchStoreManagers(data) {
    let response = await apiCall("user/getStoreManagers", data);
    return response;
  },

  async getUserPermissionCodes(userId) {
    let data = {
      user_id: userId,
    };

    let response = await apiCall("user/permission/codes", data);
    return response;
  },

  async createUser(
    role_id,
    first_name,
    last_name,
    username,
    password,
    email,
    nin,
    district,
    contact
  ) {
    let data = {
      first_name: first_name,
      last_name: last_name,
      username: username,
      password: password,
      email: email,
      nin: nin,
      role_id: role_id,
      district: district,
      contact: contact,
    };
    let response = await apiCall("user/create", data);
    return response;
  },
  async createUserAllocation(user_id, team_id) {
    let data = {
      user_id: user_id,
      team_id: team_id,
    };

    let response = await apiCall("user_allocation/create", data);
    return response;
  },
  async removeUser(user_id, team_id) {
    let data = {
      user_id: user_id,
      team_id: team_id,
    };

    let response = await apiCall("user_allocation/delete", data);
    return response;
  },

  async updateUser(
    user_id,
    first_name,
    last_name,
    username,
    email,
    nin,
    district,
    role_id,
    contact
  ) {
    let data = {
      user_id: user_id,
      first_name: first_name,
      last_name: last_name,
      username: username,
      email: email,
      nin: nin,
      district: district,
      role_id: role_id,
      contact: contact,
    };
    let response = await apiCall("user/update", data);
    return response;
  },

  async updateUserProfile(
    user_id,
    first_name,
    last_name,
    username,
    email,
    nin,
    district,
    contact
  ) {
    let data = {
      user_id: user_id,
      first_name: first_name,
      last_name: last_name,
      username: username,
      email: email,
      nin: nin,
      district: district,
      contact: contact,
    };
    let response = await apiCall("user/profile/update", data);
    return response;
  },

  async updatePassword(user_id, old_password, new_password) {
    let data = {
      user_id: user_id,
      old_password: old_password,
      new_password: new_password,
    };
    let response = await apiCall("password/update", data);
    return response;
  },

  async getUserList(data) {
    let response = await apiCall("user/list", data);
    return response;
  },

  async fetchUserNumber(data) {
    let response = await apiCall("user/count", data);
    return response;
  },

  async fetchSupervisorsNumber(data) {
    let response = await apiCall("user/count/supervisors", data);
    return response;
  },

  async fetchProjectManagersNumber(data) {
    let response = await apiCall("user/count/projectmanagers", data);
    return response;
  },

  async fetchDevelopersNumber(data) {
    let response = await apiCall("user/count/developers", data);
    return response;
  },

  async fetchTeamMembers(team_id) {
    let data = {
      team_id: team_id,
    };
    let response = await apiCall("user_allocation/list", data);

    return response;
  },

  async fetchUserTeams(user_id) {
    let data = {
      user_id: user_id,
    };
    let response = await apiCall("user_allocation/list/teams", data);

    return response;
  },

  async fetchSalesRepsNumber(data) {
    let response = await apiCall("user/count/salesreps", data);
    return response;
  },

  async fetchCustomerCareNumber(data) {
    let response = await apiCall("user/count/customercare", data);
    return response;
  },

  async fetchUserProfile(id) {
    let data = {
      id: id,
    };
    let response = await apiCall("user/profile", data);
    return response;
  },

  async fetchSingleUser(id) {
    let data = {
      user_id: id,
    };
    let response = await apiCall("user/list/single", data);

    return response;
  },

  async fetchSalesReps(data) {
    let response = await apiCall("user/list/salesrep", data);

    return response;
  },

  async fetchHses(data) {
    let response = await apiCall("user/list/hse", data);

    return response;
  },

  async fetchProjectManagers(data) {
    let response = await apiCall("user/list/projectmanagers", data);

    return response;
  },

  async fetchDevelopers(data) {
    let response = await apiCall("user/list/developer", data);

    return response;
  },
  async searchUsers(search) {
    let data = {
      search: search,
    };
    let response = await apiCall("user/search", data);

    return response;
  },
  async updatePasswordByAdmin(user_id) {
    let data = {
      user_id: user_id,
    };
    let response = await apiCall("password/update/admin", data);
    return response;
  },
  async disableUser(user_id) {
    let data = {
      user_id: user_id,
    };
    let response = await apiCall("user/disable", data);
    return response;
  },
  async enableUser(user_id) {
    let data = {
      user_id: user_id,
    };
    let response = await apiCall("user/enable", data);
    return response;
  },
  async getUsersMinimal_data() {
    let response = await apiCall("user/list_users_min_data");
    return response;
  },
};
