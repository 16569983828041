import { useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";

const BudgetExpenditureUpdater = (props) => {
  var data = props.data;
  const [loading, setLoading] = useState(false);

  // arrays
  const [projects, setProjects] = useState(props.projects);
  const [ledgers, setLedgers] = useState(props.ledgers);
  const [amount, setAmount] = useState(data.amount.amount);
  const [projectID, setProjectID] = useState(data.project_id);
  const [transID, setTransID] = useState(data.id);
  const [LedgerID, setLedgerID] = useState(data.ledger_id);
  const [description, setDescription] = useState(data.description);
  const [cost_item, setCost_item] = useState("");
  const [date, setDate] = useState(data.transaction_date);
  const [created_by, setCreated_by] = useState(data.created_by);

  const handleAdd = async (e, controls) => {
    e.preventDefault();
    if (projectID == "" || LedgerID == "" || amount == "" || date == "") {
      toast.error("Please complete all fields.");
    } else {
      setLoading(true);
      var datas = {
        transID: transID,
        amount: amount,
        projectID: projectID,
        LedgerID: LedgerID,
        cost_item: cost_item,
        date: date,
        description: description,
        created_by: created_by,
      };
      const server_response = await ajaxAccounting.UpdateBudgetExpenditure(
        datas
      );
      setLoading(false);
      if (server_response.status === "OK") {
        toast.success(server_response.message);
        controls.close();

        props.function();
        resetForm();
      } else {
        toast.error(server_response.message);
      }
    }
  };
  const resetForm = () => {
    setProjectID("");
    setAmount("");
    setDate("");
    setCost_item("");
    setLedgerID("");
    setDescription("");
  };

  data = { data };
  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            disabled={loading}
            className={`btn ripple btn-success`}
            onClick={(e) => handleAdd(e, controls)}>
            Save changes
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Update Transaction "
      id="model-new-ledger"
      size="lg"
      footer={RenderFooter}>
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div
                className="card custom-card"
                style={{ borderRadius: "10px" }}>
                <div className="card-body">
                  <div>
                    <h6 className="card-title mb-4">Add Budget Expense</h6>
                  </div>

                  <form onSubmit={(e) => handleAdd(e)} method="post">
                    <div className="row">
                      <div className="mb-4 col-md-6">
                        <label htmlFor="">
                          select project
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          onChange={(e) => setProjectID(e.id)}
                          getOptionLabel={(option) => option.project_name}
                          getOptionValue={(option) => option.id}
                          isSearchable
                          options={projects}
                          value={
                            projectID
                              ? Array.isArray(projects) &&
                                projects.find((value) => value.id === projectID)
                              : projectID
                          }
                        />
                      </div>
                      <div className="mb-4 col-md-6">
                        <label htmlFor="">
                          select Ledger
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <Select
                          onChange={(e) => setLedgerID(e.id)}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isSearchable
                          options={Array.isArray(ledgers) && ledgers}
                          value={
                            LedgerID
                              ? Array.isArray(ledgers) &&
                                ledgers.find((value) => value.id === LedgerID)
                              : ""
                          }
                        />
                      </div>

                      <div className="mb-4 col-md-6">
                        <label htmlFor="">
                          Amount
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                          className="form-control"
                        />
                      </div>
                      <div className="mb-4 col-md-6">
                        <label htmlFor="">
                          Description
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="text"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          className="form-control"
                        />
                      </div>
                      <div className="mb-4 col-md-6">
                        <label htmlFor="">
                          Date
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          type="date"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SystemModal>
  );
};

export default BudgetExpenditureUpdater;
