import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ajaxProject from "../../util/remote/ajaxProject";
import Loader from "../../Components/Common/Loader";

function View(props) {
  const [loading, setLoading] = useState(false);
  const [loadingx, setLoadingx] = useState(false);
  const [pole, setPole] = useState(false);
  const [cross, setCross] = useState(false);
  const [joint, setJoint] = useState(false);
  const [otb, setOtb] = useState(false);
  const [hard, serHard] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getPole();
  }, [id]);

  useEffect(() => {
    getPoleCrosses();
    getPoleJoints();
    getPoleOtbs();
    getPoleHardware();
  }, [pole]);

  const getPole = async () => {
    setLoading(true);
    const server_response = await ajaxProject.getPoleInfo(id);
    setLoading(false);

    if (server_response.status === "OK") {
      setPole(server_response.details);
    } else {
      setPole(false);
    }
  };

  const getPoleCrosses = async () => {
    if (pole) {
      setLoadingx(true);
      const server_response = await ajaxProject.getPoleCrosses(pole.pole_id);
      setLoadingx(false);

      if (server_response.status === "OK") {
        setCross(server_response.details);
      } else {
        setCross(false);
      }
    }
  };

  const getPoleJoints = async () => {
    if (pole) {
      setLoadingx(true);
      const server_response = await ajaxProject.getPoleJoints(pole.pole_id);
      setLoadingx(false);

      if (server_response.status === "OK") {
        setJoint(server_response.details);
      } else {
        setJoint(false);
      }
    }
  };

  const getPoleOtbs = async () => {
    if (pole) {
      setLoadingx(true);
      const server_response = await ajaxProject.getPoleOtbs(pole.pole_id);
      setLoadingx(false);

      if (server_response.status === "OK") {
        setOtb(server_response.details);
      } else {
        setOtb(false);
      }
    }
  };

  const getPoleHardware = async () => {
    if (pole) {
      setLoadingx(true);
      const server_response = await ajaxProject.getPoleHardware(pole.pole_id);
      setLoadingx(false);

      if (server_response.status === "OK") {
        serHard(server_response.details);
      } else {
        serHard(false);
      }
    }
  };
  return (
    <div className="row">
      <div className="col-lg-12 mt-4 ml-2">
        <Link
          to="#"
          onClick={() => navigate(-1)}
          className="btn btn-primary btn-sm mb-2">
          Back To Installations List
        </Link>
        {loading && <Loader />}
        {pole && (
          <>
            <div className="card custom-card">
              <div className="card-body table-responsive">
                <h4 className="card-title">Pole Information</h4>
                <table className="table">
                  <tr>
                    <td className="text-muted">POLE ID</td>
                    <td>: {pole.pole_id}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">ID</td>
                    <td>: {pole.idd}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">FID</td>
                    <td>: {pole.fid}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">TOWN</td>
                    <td>: {pole.id_town}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">NEIGHBORHOOD</td>
                    <td>: {pole.id_neighborhood}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">WKT GEOM</td>
                    <td>: {pole.wkt_geom}</td>
                  </tr>
                </table>
              </div>
            </div>

            <div id="pit" className="card custom-card">
              <div className="card-body table-responsive">
                <h4 className="card-title">
                  Pole Pit
                  <a
                    style={{ fontSize: 16, marginLeft: 20 }}
                    href="#"
                    className="text-success"
                    onClick={(e) => props.navi(`pits`)}>
                    <i className="far fa-edit"> Update</i>
                  </a>
                </h4>

                <table className="table">
                  <tr>
                    <td className="text-muted">PIT ID</td>
                    <td>: {pole.pit_id}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">PIT STATUS</td>
                    <td>: {pole.pit_status}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">PIT INSTALL DATE</td>
                    <td>: {pole.pit_install_date}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">INSTALLED BY</td>
                    <td>: {pole.pit_installed_by}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">APPROVED BY</td>
                    <td>: {pole.pit_approved_by}</td>
                  </tr>
                </table>
              </div>
            </div>

            <div id="planting" className="card custom-card">
              <div className="card-body table-responsive">
                <h4 className="card-title">
                  Pole Planting
                  <a
                    style={{ fontSize: 16, marginLeft: 20 }}
                    href="#"
                    className="text-success"
                    onClick={(e) => props.navi(`plant`)}>
                    <i className="far fa-edit"> Update</i>
                  </a>
                </h4>

                <table className="table">
                  <tr>
                    <td className="text-muted">STATUS</td>
                    <td>: {pole.status}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">INSTALL DATE</td>
                    <td>: {pole.install_date}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">INSTALLED BY</td>
                    <td>: {pole.installed_by}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">APPROVED BY</td>
                    <td>: {pole.approved_by}</td>
                  </tr>
                </table>
              </div>
            </div>

            <div id="plumbing" className="card custom-card">
              <div className="card-body table-responsive">
                <h4 className="card-title">
                  Pole Plumbing
                  <a
                    style={{ fontSize: 16, marginLeft: 20 }}
                    href="#"
                    className="text-success"
                    onClick={(e) => props.navi(`plumb`)}>
                    <i className="far fa-edit"> Update</i>
                  </a>
                </h4>

                <table className="table">
                  <tr>
                    <td className="text-muted">STATUS</td>
                    <td>: {pole.plumb_status}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">INSTALL DATE</td>
                    <td>: {pole.plumb_install_date}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">INSTALLED BY</td>
                    <td>: {pole.plumb_installed_by}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">APPROVED BY</td>
                    <td>: {pole.plumb_approved_by}</td>
                  </tr>
                </table>
              </div>
            </div>

            {loadingx && <Loader />}

            {/* /////////crosss */}
            {cross &&
              Array.isArray(cross) &&
              cross.map((item, key) => (
                <div id="crosses" className="card custom-card">
                  <div className="card-body table-responsive">
                    <h4 className="card-title">
                      Cross
                      <a
                        style={{ fontSize: 16, marginLeft: 20 }}
                        href="#"
                        className="text-success"
                        onClick={() => props.navi(`cross/${item.id}`)}>
                        <i className="far fa-edit"> Update</i>
                      </a>
                    </h4>

                    <table key={key} className="table">
                      <tr>
                        <td className="text-muted">CROSS</td>
                        <td>: {item.name}</td>
                      </tr>
                      <tr>
                        <td className="text-muted">INITIAL LENGTH</td>
                        <td>: {item.initial_length}</td>
                      </tr>
                      <tr>
                        <td className="text-muted">CURRENT LENGTH</td>
                        <td>: {item.current_length}</td>
                      </tr>
                      <tr>
                        <td className="text-muted">STATUS</td>
                        <td>: {item.status}</td>
                      </tr>
                      <tr>
                        <td className="text-muted">INSTALL DATE</td>
                        <td>: {item.install_date}</td>
                      </tr>
                      <tr>
                        <td className="text-muted">INSTALLED BY</td>
                        <td>: {item.installed_by}</td>
                      </tr>
                      <tr>
                        <td className="text-muted">APPROVED BY</td>
                        <td>: {item.approved_by}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              ))}

            {/* /////////joints */}
            {joint &&
              Array.isArray(joint) &&
              joint.map((item, key) => (
                <div id="joints" className="card custom-card">
                  <div className="card-body table-responsive">
                    <h4 className="card-title">
                      Joint
                      <a
                        style={{ fontSize: 16, marginLeft: 20 }}
                        href="#"
                        className="text-success"
                        onClick={() => props.navi(`joint/${item.id}`)}>
                        <i className="far fa-edit"> Update</i>
                      </a>
                    </h4>

                    <table key={key} className="table">
                      <tr>
                        <td className="text-muted">JOINT</td>
                        <td>: {item.name}</td>
                      </tr>
                      <tr>
                        <td className="text-muted">STATUS</td>
                        <td>: {item.status}</td>
                      </tr>
                      <tr>
                        <td className="text-muted">INSTALL DATE</td>
                        <td>: {item.install_date}</td>
                      </tr>
                      <tr>
                        <td className="text-muted">INSTALLED BY</td>
                        <td>: {item.installed_by}</td>
                      </tr>
                      <tr>
                        <td className="text-muted">APPROVED BY</td>
                        <td>: {item.approved_by}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              ))}

            {/* /////////otbs */}
            {otb &&
              Array.isArray(otb) &&
              otb.map((item, key) => (
                <>
                  <div id="otbs" className="card custom-card">
                    <div className="card-body table-responsive">
                      <h4 className="card-title">
                        OTBS
                        <a
                          style={{ fontSize: 16, marginLeft: 20 }}
                          href="#"
                          className="text-success"
                          onClick={() => props.navi(`otb/${item.id}`)}>
                          <i className="far fa-edit"> Update</i>
                        </a>
                      </h4>

                      <table key={key} className="table">
                        <tr>
                          <td className="text-muted">OTB</td>
                          <td>: {item.name}</td>
                        </tr>
                        <tr>
                          <td className="text-muted">STATUS</td>
                          <td>: {item.status}</td>
                        </tr>
                        <tr>
                          <td className="text-muted">INSTALL DATE</td>
                          <td>: {item.install_date}</td>
                        </tr>
                        <tr>
                          <td className="text-muted">INSTALLED BY</td>
                          <td>: {item.installed_by}</td>
                        </tr>
                        <tr>
                          <td className="text-muted">APPROVED BY</td>
                          <td>: {item.approved_by}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div id="images" className="card custom-card">
                    <div className="card-body table-responsive">
                      <h4 className="card-title"> OTB Images</h4>
                      <hr />
                      <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <h4>Labeling Image </h4>
                          <img
                            alt="Labeling Image"
                            style={{ width: "100%" }}
                            class="img-thumbnail"
                            src={item.labeling_image}
                          />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <h4>Splicing Image </h4>
                          <img
                            alt="Splicing Image"
                            style={{ width: "100%" }}
                            class="img-thumbnail"
                            src={item.splicing_image}
                          />
                        </div>

                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <h4>Closed Image </h4>
                          <img
                            alt="Closed Image"
                            style={{ width: "100%" }}
                            class="img-thumbnail"
                            src={item.closed_image}
                          />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                          <h4>On Tree Image </h4>
                          <img
                            alt="On Tree Image"
                            style={{ width: "100%" }}
                            class="img-thumbnail"
                            src={item.tree_image}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}

            {/* /////////hardware */}
            {hard &&
              Array.isArray(hard) &&
              hard.map((item, key) => (
                <div id="hardware" className="card custom-card">
                  <div className="card-body table-responsive">
                    <h4 className="card-title">
                      Hardware
                      <a
                        style={{ fontSize: 16, marginLeft: 20 }}
                        href="#"
                        className="text-success"
                        onClick={() => props.navi(`hardware/${item.id}`)}>
                        <i className="far fa-edit"> Update</i>
                      </a>
                    </h4>

                    <table key={key} className="table">
                      <tr>
                        <td className="text-muted">ESTIMATED HARDWARE</td>
                        <td>: {item.estimated_hardware}</td>
                      </tr>
                      <tr>
                        <td className="text-muted">FINAL USED</td>
                        <td>: {item.final_hardware_used}</td>
                      </tr>
                      <tr>
                        <td className="text-muted">INSTALL DATE</td>
                        <td>: {item.install_date}</td>
                      </tr>
                      <tr>
                        <td className="text-muted">INSTALLED BY</td>
                        <td>: {item.installed_by}</td>
                      </tr>
                      <tr>
                        <td className="text-muted">APPROVED BY</td>
                        <td>: {item.approved_by}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              ))}

            <div id="images" className="card custom-card">
              <div className="card-body table-responsive">
                <h4 className="card-title">Images</h4>
                <hr />
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <h4>Pole Pit Image </h4>
                    <img
                      alt="Pole Pit"
                      style={{ width: "100%" }}
                      class="img-thumbnail"
                      src={pole.image1}
                    />
                    <h6 className="text-muted">{pole.image1_desc}</h6>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <h4>Pole Planting Image </h4>
                    <img
                      alt="Pole Plant"
                      style={{ width: "100%" }}
                      class="img-thumbnail"
                      src={pole.image2}
                    />
                    <h6 className="text-muted">{pole.image2_desc}</h6>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <h4>Pole Plumbing Image </h4>
                    <img
                      alt="Pole Plumb"
                      style={{ width: "100%" }}
                      class="img-thumbnail"
                      src={pole.image3}
                    />
                    <h6 className="text-muted">{pole.image3_desc}</h6>
                  </div>
                  {cross &&
                    Array.isArray(cross) &&
                    cross.map((item, key) => (
                      <div className="col-lg-3 col-md-6 col-sm-12">
                        <h4>Cross Image </h4>
                        <img
                          alt="Cross Image"
                          style={{ width: "100%" }}
                          class="img-thumbnail"
                          src={item.image}
                        />
                        <h6 className="text-muted">{pole.image4_desc}</h6>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div id="complete" className="card custom-card">
              <div className="card-body table-responsive">
                <h4 className="card-title">
                  Complete Installation
                  <a
                    style={{ fontSize: 16, marginLeft: 20 }}
                    href="#"
                    className="text-success"
                    onClick={(e) => props.navi(`complete`)}>
                    <i className="far fa-edit"> Complete</i>
                  </a>
                </h4>

                <table className="table">
                  <tr>
                    <td className="text-muted">VERIFIED</td>
                    <td>: {pole.verified}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">APPROVED</td>
                    <td>: {pole.approved}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">VERIFICATION DATE</td>
                    <td>: {pole.verified_date}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">VERIFICATION COMMENT</td>
                    <td>: {pole.verified_comment}</td>
                  </tr>
                  <tr>
                    <td className="text-muted">VERIFIED BY</td>
                    <td>: {pole.verified_by}</td>
                  </tr>
                </table>
              </div>
            </div>
          </>
        )}
        {/* {!pole && <h5>Pole not found</h5>} */}
      </div>
    </div>
  );
}

export default View;
