import React, { useEffect, useState } from "react";
import AppContainer from "../Structure/AppContainer";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";
import ajaxProject from "../../util/remote/ajaxProject";
import toast, { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";

function LedgerTrialBalanceReport(props) {
  const { ledgerID, projectID, start_date, end_date } = useParams();

  const [project_details, setProject_details] = useState("");
  const [ledgerID_debited, setLedgerID_debited] = useState("");
  const [transactions, setTransactions] = useState("");
  const [total, setTotal] = useState("");
  const [dataStatus, setDataStatus] = useState("");

  const getPaymentVoucher = async () => {
    var data = {
      LedgerID: ledgerID,
      projectID: projectID,
      start_date: start_date,
      to_date: end_date,
    };
    const server_response = await ajaxAccounting.getLedgerTrialStatement(data);
    if (server_response.status === "OK") {
      setTransactions(server_response.details.data_list);
      setProject_details(server_response.details.project);
      setLedgerID_debited(server_response.details.ledgerID_debited);
      setTotal(server_response.details.ledger_tot);
    } else {
      setDataStatus("404");
    }
  };

  useEffect(() => {
    getPaymentVoucher();
  }, []);
  return (
    <div>
      <AppContainer title="Budget Expenses">
        {/* Row */}
        <div className="row">
          <div className="col-lg-12">
            <div className="card custom-card">
              <div className="card-body">
                <div>
                  <h3 className="mb-1 text-center">
                    Ledger Transaction Statement
                  </h3>
                  <h5 className="text-muted text-center">
                    Perion : From{" "}
                    <span style={{ color: "blue" }}>{start_date}</span> &nbsp;To{" "}
                    <span style={{ color: "blue" }}>{end_date}</span>
                  </h5>
                </div>

                <div className="table-responsive">
                  <table className="table table-striped mt-2 table-bordered">
                    <tr>
                      <td>Ledger Name</td>
                      <td>
                        <b>{ledgerID_debited}</b>
                      </td>
                      <td>Total Debits</td>
                      <td>
                        <b>{total.ledger_tot_c}</b>
                      </td>
                      <td>Total Credits</td>
                      <td>0</td>
                    </tr>
                  </table>
                </div>
                <div className="table-responsive">
                  <table className="table table-striped mt-5">
                    <thead>
                      <tr style={{ backgroundColor: "grey", height: "30px" }}>
                        <th>NO.</th>
                        <th>reference NO.</th>
                        <th>Ledger Credited</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Balance</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(transactions) &&
                        transactions.map((item, key) => (
                          <>
                            <tr key={key}>
                              <th>{key + 1}</th>
                              <td>{item.reference}</td>
                              <td>{item.ledgerID_credited}</td>
                              <td>{item.amount.amount_c}</td>
                              <td>{item.date}</td>
                              <td>{item.balance.balance_c}</td>
                              <td>{item.details}</td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Row */}
      </AppContainer>
    </div>
  );
}

export default LedgerTrialBalanceReport;
