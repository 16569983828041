import { useContext, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import ajaxPayRate from "../../util/remote/ajaxPayRate";
import UserContext from "../../Context/UserContext";
import SystemModal from "../../Components/Common/SystemModal";
import Loader from "../../Components/Common/Loader";
import Select from "react-select";
import ajaxTask from "../../util/remote/ajaxTask";
import ajaxUser from "../../util/remote/ajaxUser";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import ajaxProject from "../../util/remote/ajaxProject";
import AuthContext from "../../Context/AuthContext";
const CreateTaskPayment = (props) => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [user, setUser] = useState("");
  const [task, setTask] = useState("");
  const [team, setTeam] = useState("");
  const [output_date, setOutputDate] = useState("");
  const { userList } = useContext(UserContext);
  const [taskList, setTaskList] = useState(false);
  const [userTeams, setUserTeams] = useState(false);
  const [projectID, setProjectID] = useState("");
  const [LedgerID_credited, setLedgerID_credited] = useState("");
  const [LedgerID_debited, setLedgerID_debited] = useState("");
  const [description, setDescription] = useState("");
  const { userId } = useContext(AuthContext);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setOutputDate(today);
  }, []);

  const handleAdd = async (e) => {
    e.preventDefault();
    if (
      user &&
      amount &&
      output_date &&
      team &&
      task &&
      projectID &&
      LedgerID_credited &&
      LedgerID_debited
    ) {
      setLoading(true);
      let data = {
        employee: user,
        amount: amount,
        output_date: output_date,
        team: team,
        task: task,
        LedgerID_debited: LedgerID_debited,
        LedgerID_credited: LedgerID_credited,
        description: description,
        projectID: projectID,
        created_by: userId,
      };
      const server_response = await ajaxAccounting.createcreditTransaction(
        data
      );
      setLoading(false);
      if (server_response.status === "OK") {
        setAmount("");
        setOutputDate("");
        toast.success(server_response.message);
        props.f();
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const getTasks = async () => {
    const server_response = await ajaxTask.fetchTasks("");
    if (server_response.status === "OK") {
      setTaskList(server_response.details);
    }
  };

  const getUserTeams = async () => {
    const server_response = await ajaxUser.fetchUserTeams(user);
    if (server_response.status === "OK") {
      setUserTeams(server_response.details);
    } else {
      setUserTeams("404");
    }
  };

  useEffect(() => {
    getTasks();
  }, []);
  useEffect(() => {
    getDebitledgers();
    getCreditlegders();
    getProjects();
  }, []);

  const [Debitledgers, setDebitledgers] = useState([]);
  const [creditLedger, setCreditLedger] = useState([]);
  const [projects, setProjects] = useState([]);

  const getDebitledgers = async () => {
    const server_response =
      await ajaxAccounting.getALlLedgersMinData_non_cash_or_bank();
    setDebitledgers(
      server_response.status === "OK" ? server_response.details : []
    );
  };

  const getCreditlegders = async () => {
    const server_response =
      await ajaxAccounting.getALlLedgersMinData_cash_or_bank();
    setCreditLedger(
      server_response.status === "OK" ? server_response.details : []
    );
  };

  const getProjects = async () => {
    const server_response = await ajaxProject.getProjectsMinnimalDataCliented();
    setProjects(server_response.status === "OK" ? server_response.details : []);
  };
  useEffect(() => {
    getUserTeams();
  }, [user]);

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleAdd}>
            Register Credit
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Credit"
      id="model-new-taskpayment"
      size="lg"
      footer={RenderFooter}>
      <div className="row">
        <div className="mb-4 col-md-6">
          <label>
            Select Project <span style={{ color: "red" }}>*</span>
          </label>
          <Select
            onChange={(e) => setProjectID(e.id)}
            getOptionLabel={(option) => option.project_name}
            getOptionValue={(option) => option.id}
            isSearchable
            options={projects}
            value={projects.find((proj) => proj.id === projectID) || null}
          />
        </div>
        <div className="mb-4 col-md-6">
          <label>
            Select Account To Debit <span style={{ color: "red" }}>*</span>
          </label>
          <Select
            onChange={(e) => setLedgerID_debited(e.id)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isSearchable
            options={Debitledgers}
            value={
              Debitledgers.find((ledger) => ledger.id === LedgerID_debited) ||
              null
            }
          />
        </div>
        <div className="mb-4 col-md-6">
          <label>
            Select Account To Credit <span style={{ color: "red" }}>*</span>
          </label>
          <Select
            onChange={(e) => setLedgerID_credited(e.id)}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isSearchable
            options={creditLedger}
            value={
              creditLedger.find((ledger) => ledger.id === LedgerID_credited) ||
              null
            }
          />
        </div>
        <div className="mb-4 col-md-6">
          <label htmlFor="">Select Employee</label>
          <Select
            onChange={(e) => setUser(e.id)}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name}`
            }
            getOptionValue={(option) =>
              `${option.first_name} ${option.last_name}`
            }
            isSearchable
            options={Array.isArray(userList) ? userList : []}
            value={
              Array.isArray(userList) &&
              userList.find((value) => value.id === user)
            }
          />
        </div>
        <div className="mb-4 col-md-6">
          <label htmlFor="">Select Team</label>
          <Select
            onChange={(e) => setTeam(e.team.team_id)}
            getOptionLabel={(option) => option.team.team_name}
            getOptionValue={(option) => option.team.team_id}
            isSearchable
            options={Array.isArray(userTeams) ? userTeams : []}
            value={
              Array.isArray(userTeams) &&
              userTeams.find((value) => value.team.team_id === team)
            }
          />
        </div>
        <div className="mb-4 col-md-6">
          <label htmlFor="">Select Task</label>
          <Select
            onChange={(e) => setTask(e.id)}
            getOptionLabel={(option) =>
              `${option.task_name} (${option.project.project_name})`
            }
            getOptionValue={(option) => option.id}
            isSearchable
            options={Array.isArray(taskList) ? taskList : []}
            value={
              Array.isArray(taskList) &&
              taskList.find((value) => value.id === task)
            }
          />
        </div>
        <div className="mb-4 col-md-6">
          <label htmlFor="">Amount</label>
          <input
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
            type="number"
            className="form-control"
          />
        </div>
        <div className="mb-4 col-md-6">
          <label htmlFor="">Date</label>
          <input
            onChange={(e) => setOutputDate(e.target.value)}
            value={output_date}
            type="date"
            className="form-control"
          />
        </div>{" "}
        <div className="mb-4 col-md-12">
          <label>
            Description <span style={{ color: "red" }}>*</span>
          </label>
          <input
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            type="text"
            className="form-control"
          />
        </div>
      </div>
    </SystemModal>
  );
};

export default CreateTaskPayment;
