import React from "react";
import { Link } from "react-router-dom";

function AccountingNav() {
  return (
    <div>
      <div className="row mt-1 mb-1">
        <Link
          style={{
            borderRadius: "10px",
            fontSize: "14px",
            height: "35px",
            margin: "5px",
            color: "white",
            background: "linear-gradient(to right, #FFAF7B, #D76D77)", // Light peach to red gradient
          }}
          className="btn ripple btn-with-icon btn-outline-success"
          to="/accounts/payment/Vouchers">
          <i className="fa-solid fa-credit-card"></i> &nbsp;
          <span className="sidemenu-label">Payment Voucher</span>
        </Link>

        <Link
          style={{
            borderRadius: "10px",
            fontSize: "14px",
            height: "35px",
            margin: "5px",
            color: "white",
            background: "linear-gradient(to right, #4BC0C8, #C779D0)", // Teal to purple gradient
          }}
          className="btn ripple btn-with-icon btn-outline-success"
          to="/accounts/payment/reciepts">
          <i className="fa-solid fa-file-invoice"></i> &nbsp;
          <span className="sidemenu-label">Receipts</span>
        </Link>

        <Link
          style={{
            borderRadius: "10px",
            fontSize: "14px",
            height: "35px",
            margin: "5px",
            color: "white",
            background: "linear-gradient(to right, #FFB75E, #ED8F03)", // Sunset gradient
          }}
          className="btn ripple btn-outline-success btn-with-icon"
          to="/accounts/journal">
          <i className="fa-solid fa-file-invoice"></i> &nbsp;
          <span className="sidemenu-label">Journal None Inventory</span>
        </Link>

        <Link
          style={{
            borderRadius: "10px",
            fontSize: "14px",
            height: "35px",
            margin: "5px",
            color: "white",
            background: "linear-gradient(to right, #2C3E50, #4CA1AF)", // Dark blue to light blue gradient
          }}
          className="btn ripple btn-outline-success btn-with-icon"
          to="/accounts/journal/inventory">
          <i className="fa-solid fa-file-invoice"></i> &nbsp;
          <span className="sidemenu-label">Inventory Journal </span>
        </Link>

        <Link
          style={{
            borderRadius: "15px",
            height: "35px",
            margin: "5px",
            color: "white",
            background: "linear-gradient(to right, #485563, #29323C)", // Charcoal gradient
          }}
          className="btn ripple btn-outline-success btn-with-icon"
          to="/accounts/contra">
          <i className="fa-solid fa-file-invoice"></i> &nbsp;
          <span className="sidemenu-label">Contra</span>
        </Link>

        <Link
          style={{
            borderRadius: "10px",
            fontSize: "14px",
            height: "35px",
            margin: "5px",
            color: "white",
            background: "linear-gradient(to right, #FDC830, #F37335)", // Orange gradient
          }}
          className="btn ripple btn-outline-success btn-with-icon"
          to="/accounts/budget_expenses">
          <i className="fe fe-users"></i> &nbsp;
          <span className="sidemenu-label">Budget Expenses</span>
        </Link>

        <Link
          style={{
            borderRadius: "10px",
            fontSize: "14px",
            height: "35px",
            margin: "5px",
            color: "white",
            background: "linear-gradient(to right, #355C7D, #6C5B7B)", // Deep purple gradient
          }}
          className="btn ripple btn-outline-success btn-with-icon"
          to="/accounts/project_budget_report">
          <i className="fa-solid fa-file-invoice-dollar"></i> &nbsp;
          <span className="sidemenu-label">Budget Expenses Report</span>
        </Link>

        <Link
          style={{
            borderRadius: "10px",
            fontSize: "14px",
            height: "35px",
            margin: "5px",
            color: "white",
            background: "linear-gradient(to right, #4e73df, #1cc88a)", // Blue to green gradient
          }}
          className="btn ripple btn-outline-success btn-with-icon"
          to="/accounts/trial_balance">
          <i className="fa-solid fa-scale-balanced"></i>&nbsp;
          <span className="sidemenu-label">Trial Balance</span>
        </Link>

        <Link
          style={{
            borderRadius: "10px",
            fontSize: "14px",
            height: "35px",
            margin: "5px",
            color: "white",
            background: "linear-gradient(to right, #B24592, #F15F79)", // Pink gradient
          }}
          className="btn ripple btn-outline-success btn-with-icon"
          to="/accounts/ledger_types">
          <i className="fe fe-settings"></i> &nbsp;
          <span className="sidemenu-label">Ledger Types</span>
        </Link>

        <Link
          style={{
            borderRadius: "10px",
            fontSize: "14px",
            height: "35px",
            margin: "5px",
            color: "white",
            background: "linear-gradient(to right, #5DDFC4, #3FB06C)", // Mint green gradient
          }}
          className="btn ripple btn-outline-success btn-with-icon"
          to="/accounts/ledgers">
          <i className="fa-solid fa-book"></i>&nbsp;
          <span className="sidemenu-label">Ledgers</span>
        </Link>

        <Link
          style={{
            borderRadius: "10px",
            fontSize: "14px",
            height: "35px",
            margin: "5px",
            color: "white",
            background: "linear-gradient(to right, #1B84FD, #2C3D88)", // Blue gradient
          }}
          className="btn ripple btn-outline-success btn-with-icon"
          to="/accounts/cost_allocation_center">
          <i className="fa-solid fa-sitemap"></i>&nbsp;
          <span className="sidemenu-label">Cost Allocation Center</span>
        </Link>

        {/* <Link
          style={{
            borderRadius: "10px",
            fontSize: "14px",
            height: "35px",
            margin: "5px",
            color: "white",
            background: "linear-gradient(to right, #34e89e, #0f3443)", // Green to dark gradient
          }}
          className="btn ripple btn-outline-success btn-with-icon"
          to="/accounts/invoicables">
          <i className="fa-solid fa-bookmark"></i>&nbsp;
          <span className="sidemenu-label">Invoicables</span>
        </Link> */}

        <Link
          style={{
            borderRadius: "10px",
            fontSize: "14px",
            height: "35px",
            margin: "5px",
            color: "white",
            background: "linear-gradient(to right, #F7971E, #FFD200)", // Yellow-orange gradient
          }}
          className="btn ripple btn-outline-success btn-with-icon"
          to="/accounts/Invoices_works">
          <i className="fa-solid fa-book-atlas"></i>&nbsp;
          <span className="sidemenu-label">Invoices None Inventory</span>
        </Link>

        <Link
          style={{
            borderRadius: "10px",
            fontSize: "14px",
            height: "35px",
            margin: "5px",
            color: "white",
            background: "linear-gradient(to right, #4b6cb7, #182848)", // Elegant blue gradient
          }}
          className="btn ripple btn-outline-success btn-with-icon"
          to="/accounts/Invoices_works/Inventory">
          <i className="fa-solid fa-book-atlas"></i>&nbsp;
          <span className="sidemenu-label"> Inventory Invoices</span>
        </Link>
      </div>
    </div>
  );
}

export default AccountingNav;
