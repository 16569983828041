import { useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast, Toaster } from "react-hot-toast";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";

const CreateInvoicableCenter = (props) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [unit, setUnit] = useState("");
  const handleAdd = async (e, controls) => {
    e.preventDefault();
    if (name.trim() === "" || unit.trim() === "") {
      toast.error("Please complete all fields.");
    } else {
      setLoading(true);

      var data = {
        name: name,
        unit: unit,
      };
      const server_response = await ajaxAccounting.createInvoicables(data);

      setLoading(false);
      if (server_response.status === "OK") {
        toast.success(server_response.message);
        resetForm();
        controls.close();
        props.function();
      } else {
        toast.error(server_response.message);
      }
    }
  };

  const resetForm = () => {
    setName("");
    setUnit("");
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            disabled={loading}
            className={`btn ripple btn-success`}
            onClick={(e) => handleAdd(e, controls)}>
            Save item
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Add Invoicable item"
      id="model-new-ledger"
      size="md"
      footer={RenderFooter}>
      <Toaster />
      <div className="mb-4 col-md-12">
        <label htmlFor="">
          Name:<span className="tx-danger">*</span>
        </label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="form-control"
          placeholder="item Name"
        />
      </div>
      <div className="mb-4 col-md-12">
        <label htmlFor="">
          Unit:<span className="tx-danger">*</span>
        </label>
        <input
          type="text"
          value={unit}
          onChange={(e) => setUnit(e.target.value)}
          className="form-control"
          placeholder="item unit"
        />
      </div>
    </SystemModal>
  );
};

export default CreateInvoicableCenter;
