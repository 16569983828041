import React, { useContext } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import { Link } from "react-router-dom";
import ClientContext from "../Context/ClientContext";
import { RenderSecure } from "../util/script/RenderSecure";
import Loader from "../Components/Common/Loader";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Toaster } from "react-hot-toast";
import TableHeader from "../Components/Common/TableHeader";
import jsPDF from "jspdf";
import "jspdf-autotable";
import useStateCallback from "../util/customHooks/useStateCallback";
import ClientMoreDetails from "../Components/Client/ClientMoreDetaisl";

const ClientsPage = (props) => {
  const { clientList } = useContext(ClientContext);

  const exportToPDF = () => {
    const table = document.querySelector(".table"); // Select the table element
    const pdf = new jsPDF("p", "pt", "a4");
    const companyName = "BERICOT AFRICA LIMITED ";
    const companyAddress = "KAMPALA UGANDA \n TEL:+256701666160";
    const currentDate = `Date of Printing: ${new Date().toLocaleDateString()}`;
    const headerDescription = "clients_report "; // The line to show what the PDF is about

    // Get the PDF page width for centering the text
    const pageWidth = pdf.internal.pageSize.getWidth();

    // Add company name (equivalent to <h1>)
    pdf.setFontSize(14); // Font size for <h1>
    const companyNameWidth = pdf.getTextWidth(companyName);
    pdf.text(companyName, (pageWidth - companyNameWidth) / 2, 50); // Centered <h1>

    // Add company address (equivalent to <h2>)
    pdf.setFontSize(12); // Font size for <h2>
    const companyAddressWidth = pdf.getTextWidth(companyAddress.split("\n")[0]);
    pdf.text(
      companyAddress.split("\n")[0],
      (pageWidth - companyAddressWidth) / 2,
      80
    ); // Centered <h2>

    const companyAddressCityWidth = pdf.getTextWidth(
      companyAddress.split("\n")[1]
    );
    pdf.text(
      companyAddress.split("\n")[1],
      (pageWidth - companyAddressCityWidth) / 2,
      100
    ); // Centered <h3>

    // Add printing date (equivalent to <h3>)
    pdf.setFontSize(9); // Font size for <h3>
    const dateWidth = pdf.getTextWidth(currentDate);
    pdf.text(currentDate, (pageWidth - dateWidth) / 2, 130); // Centered <h3>

    // Add header description (equivalent to a line explaining the document)
    pdf.setFontSize(12); // Font size for description
    const headerDescriptionWidth = pdf.getTextWidth(headerDescription);
    pdf.text(headerDescription, (pageWidth - headerDescriptionWidth) / 2, 160); // Centered description

    // Define columns for the table (add more if needed)
    const columns = ["Client", "District", "Date Registered"];

    // Extract data from the table and format it as an array of arrays
    const data = Array.from(table.querySelectorAll("tr")).map((row) => {
      return Array.from(row.querySelectorAll("td")).map(
        (cell) => cell.textContent
      );
    });

    // Remove the header row
    data.shift();

    // Create the PDF document and add the table
    pdf.autoTable({
      startY: 180,
      head: [columns],
      body: data,
    });

    // Save the PDF
    pdf.save(`clients_data_${new Date().toLocaleDateString()}.pdf`);
  };

  const [ClientUpdate, setClientUpdate] = useStateCallback(false);
  const handleVoucherAddingOnVoucher = (e, data) => {
    e.preventDefault();
    setClientUpdate(false, () =>
      setClientUpdate(<ClientMoreDetails isOpen={true} data={data} />)
    );
  };

  return (
    <AppContainer title={"Clients Dashboard"}>
      {ClientUpdate}
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={12}>
            <Nav variant="pills" className="flex-row mb-4">
              <Nav.Item>
                <Nav.Link eventKey="first">Tabular View</Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link eventKey="second">Map View</Nav.Link>
              </Nav.Item> */}
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Toaster position="top-center" reverseOrder={false} />

                <div className="row">
                  <RenderSecure code="ADD-TEAM">
                    <div className="col-12 col-xl-12 col-md-12 col-sm-12">
                      <h6 className="card-title mb-4 d-flex justify-content-end">
                        <Link to={`/add-client`}>
                          <button className="btn ripple btn-primary btn-with-icon btn-sm">
                            <i className="fe fe-plus"></i> Register New Client
                          </button>
                        </Link>
                      </h6>
                    </div>
                  </RenderSecure>
                  <div className="col-lg-12">
                    <div
                      className="card custom-card"
                      style={{ marginTop: "25px", borderRadius: "10px" }}>
                      <div className="card-body map-card">
                        <TableHeader
                          title="Clients List"
                          subtitle="List of all the clients sorted according to the recently added"
                          viewButton={
                            <a
                              href="#"
                              onClick={exportToPDF}
                              className="btn btn-secondary btn-sm mr-2"
                              style={{ float: "right" }}>
                              Export to PDF
                            </a>
                          }
                        />
                        <div className="border-top mt-3"></div>
                        <div className="table-responsive">
                          <table className="table table-hover text-nowrap mg-b-0">
                            <thead>
                              <tr>
                                <th scope="col" className="wd-10p">
                                  No.
                                </th>
                                <th scope="col">Client</th>
                                <th scope="col">District</th>
                                <th scope="col">Date Registered</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Array.isArray(clientList) &&
                              clientList.length > 0 ? (
                                clientList.map((item, key) => (
                                  <tr key={key}>
                                    <th scope="row">{key + 1}</th>
                                    <td>
                                      <Link
                                        to={`client-profile/${item.client_id}`}>
                                        {item.name}
                                      </Link>
                                    </td>
                                    <td>{item.district}</td>
                                    <td>{item.created_at.short_date}</td>
                                    <td>
                                      <button
                                        onClick={(e) =>
                                          handleVoucherAddingOnVoucher(e, item)
                                        }
                                        style={{
                                          borderRadius: "5px",
                                          margin: "2px",
                                        }}
                                        className="btn ripple btn-warning btn-sm">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                        &nbsp; more Client details
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan="6"
                                    style={{ textAlign: "center" }}>
                                    No clients registered yet.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                          {!clientList && <Loader />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>

              {/* <Tab.Pane eventKey="second">
                <ClientsMap/>
              </Tab.Pane> */}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </AppContainer>
  );
};

export default ClientsPage;
