import { useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxProject from "../../util/remote/ajaxProject";

const UpdateCable = (props) => {
  const [fid, setFid] = useState(props.fid);
  const [city, setCity] = useState(props.city);
  const [name, setName] = useState(props.name);
  const [template, setTemplate] = useState(props.template);
  const [gis_length, setGisLength] = useState(props.gis_length);
  const [real_length, setRealLength] = useState(props.real_length);
  const [trunk, setTrunk] = useState(props.trunk);
  const [role, setRole] = useState(props.role);
  const [cores, setCores] = useState(props.cores);
  const [contractor, setContractor] = useState(props.contractor);

  const [loading, setLoading] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (
      props.batch.length > 0 &&
      props.id * 1 > 0 &&
      fid.length > 0 &&
      name.length > 0 &&
      trunk.length > 0 &&
      contractor.length > 0
    ) {
      setLoading(true);
      const server_response = await ajaxProject.updateCable(
        props.cable_id,
        props.id,
        props.batch,
        fid,
        city,
        name,
        template,
        gis_length,
        real_length,
        role,
        cores,
        trunk,
        contractor
      );
      setLoading(false);
      if (server_response.status === "OK") {
        toast.success(server_response.message);
        props.f();
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleUpdate}>
            Update Cable Details
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Update Cable Details"
      id="model-update-cable"
      size="md"
      footer={RenderFooter}>
      <div className="mb-4">
        <label htmlFor="">Fid</label>
        <input
          type="text"
          value={fid}
          placeholder={"fid"}
          onChange={(e) => setFid(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">City</label>
        <input
          type="text"
          value={city}
          placeholder={"city"}
          onChange={(e) => setCity(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Name</label>
        <input
          type="text"
          value={name}
          placeholder={"name"}
          onChange={(e) => setName(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Template</label>
        <input
          type="text"
          value={template}
          placeholder={"template"}
          onChange={(e) => setTemplate(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Gis Length</label>
        <input
          type="text"
          value={gis_length}
          placeholder={"gis_length"}
          onChange={(e) => setGisLength(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Real Length</label>
        <input
          type="text"
          value={real_length}
          placeholder={"real_length"}
          onChange={(e) => setRealLength(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Trunk</label>
        <input
          type="text"
          value={trunk}
          placeholder={"trunk"}
          onChange={(e) => setTrunk(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Role</label>
        <input
          type="text"
          value={role}
          placeholder={"role"}
          onChange={(e) => setRole(e.target.value)}
          className="form-control"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="">Cores</label>
        <input
          type="text"
          value={cores}
          placeholder={"cores"}
          onChange={(e) => setCores(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Contractor</label>
        <input
          type="text"
          value={contractor}
          placeholder={"contractor"}
          onChange={(e) => setContractor(e.target.value)}
          className="form-control"
        />
      </div>
    </SystemModal>
  );
};

export default UpdateCable;
