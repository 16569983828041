import React, { useEffect } from "react";
import AppContainer from "../Structure/AppContainer";
import { useState } from "react";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import toast, { Toaster } from "react-hot-toast";
import Select from "react-select";
import CreateLedger from "./CreateLedger";
import useStateCallback from "../../util/customHooks/useStateCallback";
import Loader from "../Common/Loader";

function Ledgers() {
  const [loading, setLoading] = useState(false);
  const [ledgers, setLedgers] = useState("");
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState("");
  const [search_p, setSearch_p] = useState("");

  const getLedgers = async () => {
    var data = {
      page: page,
      search: search_p,
    };

    const server_response = await ajaxAccounting.getLedgers(data);
    if (server_response.status === "OK") {
      setMeta(server_response.details.meta.list_of_pages);
      setLedgers(server_response.details.list);
    } else {
      setLedgers("404");
      toast.error(server_response.message);
    }
  };
  useEffect(() => {
    getLedgers();
  }, [page, search_p]);

  const [modal, setModal] = useStateCallback(false);

  const handleModal = () => {
    setModal(false, () =>
      setModal(<CreateLedger isOpen={true} function={getLedgers} />)
    );
  };
  const setNextPageNumber = () => {
    if (meta.length === page) {
    } else {
      setPage(page + 1);
    }
  };

  const setPreviousPageNumber = () => {
    if (page === 1) {
    } else {
      setPage(page - 1);
    }
  };
  const setPageNumber = (e, item) => {
    setPage(item);
  };
  return (
    <AppContainer title="Ledgers List">
      <div className="row">
        <Toaster />
        {modal}

        <div
          className="col-lg-12 col-md-12"
          style={{ marginRight: "-5px", marginLeft: "-5px" }}>
          <div
            className="col-lg-12 col-md-12 col-sm-12"
            style={{ paddingRight: "5px", paddingLeft: "5px" }}>
            <div className="card mb-1" style={{ borderRadius: ".55rem" }}>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12 col-lg-12 mb-0">
                    <div className="row">
                      <div className="form-group col-lg-6 col-md-6 mt-1">
                        <b>Search Ledger:</b>
                        <br />
                        <input
                          type="text"
                          value={search_p}
                          onChange={(e) => setSearch_p(e.target.value)}
                          className="form-control"
                          placeholder="search ledger"
                        />
                      </div>
                      <div className="form-group col-lg-3 col-md-3 mt-4">
                        <div className="input-group-append">
                          <button
                            onClick={() => setSearch_p("")}
                            style={{ borderRadius: "20px" }}
                            className="btn ripple btn-primary btn-with-icon">
                            <i class="fa-solid fa-arrow-rotate-left"></i>
                            Reset
                          </button>
                        </div>
                      </div>

                      <div className="form-group col-lg-3 col-md-3 mt-4">
                        <button
                          onClick={handleModal}
                          style={{
                            borderRadius: "10px",
                          }}
                          className="btn ripple btn-primary btn-with-icon btn-sm">
                          <i className="fe fe-plus"></i> Add Ledger
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-md-12">
          {/* row */}
          <div className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="card-title mb-4"> Ledger Groups </h6>
              </div>
              <div className="table-responsive">
                <table
                  className="table table-striped table-bordered"
                  style={{ border: "orange 3px groove" }}>
                  <thead>
                    <th>#</th>
                    <th>Ledger Name</th>
                    <th>
                      Ledger Group <br /> Name
                    </th>
                    <th>Entry Type</th>
                    <th>
                      Main Acconut <br /> Group
                    </th>
                    <th>Client</th>
                    <th>Unit</th>
                  </thead>
                  <tbody>
                    {Array.isArray(ledgers) &&
                      ledgers.map((item, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.account_group_name}</td>
                          <td>{item.entry_type}</td>
                          <td>{item.main_account}</td>
                          <td>{item.client_name}</td>
                          <td>{item.unit}</td>
                        </tr>
                      ))}

                    {loading && <Loader />}
                    {ledgers && (
                      <>
                        <tr>
                          <td colSpan={5} className="text-info">
                            {" "}
                            No ledgers found in the system
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </table>
                <nav className="mt-3" style={{ marginLeft: "30px" }}>
                  <ul className="pagination ">
                    <li className="page-item">
                      <button
                        className="page-link"
                        onClick={setPreviousPageNumber}>
                        Prev
                      </button>
                    </li>

                    {Array.isArray(meta) &&
                      meta.map((item) =>
                        page === item ? (
                          <li className="page-item active">
                            <a className="page-link" href="javascript:void(0);">
                              {item}
                            </a>
                          </li>
                        ) : (
                          <li className="page-item">
                            <a
                              className="page-link"
                              href="#"
                              onClick={(e) => setPageNumber(e, item)}>
                              {item}
                            </a>
                          </li>
                        )
                      )}

                    <li className="page-item">
                      <button className="page-link" onClick={setNextPageNumber}>
                        Next
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        {/* end row  */}
      </div>
    </AppContainer>
  );
}

export default Ledgers;
