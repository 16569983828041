import React, { useContext, useEffect, useState } from "react";
import AppContainer from "../Structure/AppContainer";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";
import ajaxProject from "../../util/remote/ajaxProject";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../Common/Loader";
import useStateCallback from "../../util/customHooks/useStateCallback";
import AddTransactionOnTransaction from "./AddTransactionOnTransaction";
import TransctionPrinting from "./TransctionPrinting";
import TransactionUpdater from "./TransactionUpdater";
import TransactionCatUpdater from "./TransactionCatUpdater";
import ajaxUser from "../../util/remote/ajaxUser";
import JournalsInventoryCreate from "./JournalsInventoryCreate";
import InventoryJournalPrintable from "./InventoryJournalPrintable";

function JournalsInventory(props) {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("create_Budget_expense");

  const [trans_type, setTrans_type] = useState("journal");

  // Get today's date in the format YYYY-MM-DD
  const [employees, setEmployees] = useState("");
  const getEmployees = async () => {
    const server_response = await ajaxUser.getUsersMinimal_data();
    if (server_response.status === "OK") {
      setEmployees(server_response.details);
    } else {
      setEmployees("404");
    }
  };

  const [Debitledgers, setDebitledgers] = useState("");
  const getDebitlegders = async () => {
    const server_response =
      await ajaxAccounting.getALlLedgersMinData_non_cash_or_bank();
    if (server_response.status === "OK") {
      setDebitledgers(server_response.details);
    } else {
      setDebitledgers("404");
    }
  };

  // ledgers fetching +++++++++++++++
  useEffect(() => {
    getDebitlegders();
    getEmployees();
  }, []);

  const [projects, setProjects] = useState("");
  const getProjects = async () => {
    const server_response = await ajaxProject.getProjectsMinnimalDataCliented();
    if (server_response.status === "OK") {
      setProjects(server_response.details);
    } else {
      setProjects("404");
    }
  };
  const [cost_allocations, setCost_allocations] = useState("");
  const getCost_Allocations = async () => {
    const server_response =
      await ajaxAccounting.getAllcostAllocations_min_data();
    if (server_response.status === "OK") {
      setCost_allocations(server_response.details);
    } else {
      setCost_allocations("404");
    }
  };
  useEffect(() => {
    getProjects();
    getCost_Allocations();
  }, []);
  // end posting works

  // fetch data of PaymentVoucher

  const [projectIDD, setProjectIDD] = useState("");

  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState("");

  const [PaymentVoucher, setPAymentVoucher] = useState("");
  const getPaymentVoucher = async () => {
    var data = {
      projectID: projectIDD,
      trans_type: trans_type,
      page: page,
    };
    setMeta("");
    setPAymentVoucher("");
    const server_response = await ajaxAccounting.getPaymentVoucher(data);
    if (server_response.status === "OK") {
      setMeta(server_response.details.meta.list_of_pages);
      setPAymentVoucher(server_response.details.list);
    } else {
      setPAymentVoucher("404");
    }
  };

  useEffect(() => {
    getPaymentVoucher();
  }, [projectIDD, page]);

  // pagination handlers
  const setNextPageNumber = () => {
    if (meta.length === page) {
    } else {
      setPage(page + 1);
    }
  };

  const setPreviousPageNumber = () => {
    if (page === 1) {
    } else {
      setPage(page - 1);
    }
  };
  const setPageNumber = (e, item) => {
    setPage(item);
  };

  // cost allocation updating
  const [voucherCat, setVoucherCat] = useStateCallback(false);
  const handvoucherCAtItem = (e, reference, item) => {
    e.preventDefault();
    setVoucherCat(false, () =>
      setVoucherCat(
        <TransactionCatUpdater
          isOpen={true}
          function={getPaymentVoucher}
          cost_allocations={cost_allocations}
          reference={reference}
          item={item}
        />
      )
    );
  };
  // cost allocation updating
  const [AddOnvoucher, setAddOnvoucher] = useStateCallback(false);
  const handleVoucherAddingOnVoucher = (e, data) => {
    e.preventDefault();
    setAddOnvoucher(false, () =>
      setAddOnvoucher(
        <AddTransactionOnTransaction
          isOpen={true}
          data={data}
          cost_items={cost_allocations}
          ledgers={Debitledgers}
          type={trans_type}
          function={getPaymentVoucher}
          projects={projects}
        />
      )
    );
  };
  const [printing, setPrinting] = useStateCallback(false);

  const handlePrinting = (e, id) => {
    e.preventDefault();
    setPrinting(false, () =>
      setPrinting(
        <InventoryJournalPrintable isOpen={true} id={id} type={trans_type} />
      )
    );
  };
  const [UpdateVoucher, setUpdateVoucher] = useStateCallback(false);

  const handleVoucherUpdate = (e, data) => {
    e.preventDefault();
    setUpdateVoucher(false, () =>
      setUpdateVoucher(
        <TransactionUpdater
          isOpen={true}
          data={data}
          employees={employees}
          function={getPaymentVoucher}
        />
      )
    );
  };
  return (
    <div>
      <AppContainer title="Accounting/journals/Inventory">
        <div className="row">
          {voucherCat}
          {printing}
          {UpdateVoucher}
          {AddOnvoucher}
          <Toaster />
          <div className="col-lg-12 col-md-12">
            <div className="card custom-card main-content-body-profile">
              {/* Tabs navigation */}
              <nav className="nav main-nav-line">
                <a
                  className={`nav-link ${
                    activeTab === "create_Budget_expense" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("create_Budget_expense")}
                  style={{ cursor: "pointer" }}>
                  Add Inventory Journal
                </a>
                <a
                  className={`nav-link ${
                    activeTab === "View_Budget_expense" ? "active" : ""
                  }`}
                  onClick={() => setActiveTab("View_Budget_expense")}
                  style={{ cursor: "pointer" }}>
                  View Inventory Journals
                </a>
              </nav>

              {/* Tab Content */}
              <div className="card-body tab-content h-100">
                {/* create_Budget_expense Tab */}
                {activeTab === "create_Budget_expense" && (
                  <div className="row">
                    <JournalsInventoryCreate funct={getPaymentVoucher} />
                  </div>
                )}
                {/* View_Budget_expense Tab */}
                {activeTab === "View_Budget_expense" && (
                  <div className="row">
                    <div
                      className="col-lg-12 col-md-12"
                      style={{ marginRight: "-5px", marginLeft: "-5px" }}>
                      <div
                        className="col-lg-12 col-md-12 col-sm-12"
                        style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                        <div
                          className="card mb-1"
                          style={{ borderRadius: ".55rem" }}>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-12 col-xl-12 mb-0">
                                <div className="row">
                                  <div className="form-group col-md-3 col-lg-3 mt-1">
                                    <b>Select Project:</b>
                                    <br />
                                    <Select
                                      onChange={(e) => setProjectIDD(e.id)}
                                      getOptionLabel={(option) =>
                                        option.project_name
                                      }
                                      getOptionValue={(option) => option.id}
                                      isSearchable
                                      options={projects}
                                      value={
                                        Array.isArray(projects) &&
                                        projects.find(
                                          (value) => value.id === projectIDD
                                        )
                                      }
                                    />
                                  </div>

                                  <div className="form-group col-lg-3 col-md-3 mt-4">
                                    <div className="input-group-append">
                                      <button
                                        // onClick={() => setSearch_p("")}
                                        style={{ borderRadius: "5px" }}
                                        className="btn ripple btn-primary btn-with-icon">
                                        <i class="fa-solid fa-arrow-rotate-left"></i>
                                        Reset filters
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      {/* row */}
                      <div className="card custom-card">
                        <div className="card-body">
                          <div>
                            <h6 className="card-title mb-4">Invoices View</h6>
                          </div>
                          <div className="table-responsive">
                            <table
                              className="table table-striped table-bordered"
                              style={{ border: "orange 3px groove" }}>
                              <thead>
                                <th>#</th>
                                <th>Project</th>
                                <th>ledger Debited</th>
                                <th>ledger Credited</th>
                                <th>Amount</th>
                                <th>details</th>
                                <th>
                                  cost Allocation <br />
                                  Item
                                </th>
                                <th>Date</th>
                                <th>Reciever</th>
                                <th>Created By</th>
                              </thead>
                              <tbody>
                                {Array.isArray(PaymentVoucher) &&
                                  PaymentVoucher.map((item, key) => (
                                    <tr key={key}>
                                      <td>{key + 1}</td>
                                      <td>{item.projectID.project_name}</td>
                                      <td>
                                        {item.ledgerID_debited.Ledger_name}
                                        <br />
                                        <span>
                                          <button
                                            onClick={(e) =>
                                              handleVoucherUpdate(e, item)
                                            }
                                            style={{
                                              borderRadius: "5px",
                                              margin: "2px",
                                            }}
                                            className="btn ripple btn-warning btn-sm">
                                            <i class="fa-solid fa-pen-to-square"></i>
                                            &nbsp; Update Transaction
                                          </button>
                                          <br />
                                          <button
                                            onClick={(e) =>
                                              handleVoucherAddingOnVoucher(
                                                e,
                                                item
                                              )
                                            }
                                            style={{
                                              borderRadius: "5px",
                                              margin: "2px",
                                            }}
                                            className="btn ripple btn-danger btn-sm">
                                            <i class="fa-solid fa-plus"></i>
                                            &nbsp; Add on A Voucher
                                          </button>
                                          <button
                                            onClick={(e) =>
                                              handlePrinting(e, item.id)
                                            }
                                            style={{
                                              borderRadius: "5px",
                                              margin: "2px",
                                            }}
                                            className="btn ripple btn-secondary btn-sm">
                                            <i class="fa-solid fa-print"></i>
                                            &nbsp; print
                                          </button>
                                        </span>
                                      </td>
                                      <td>
                                        {item.ledgerID_credited.Ledger_name}
                                      </td>
                                      <td>{item.amount.amount_p}</td>
                                      <td
                                        style={{
                                          width: "200px",
                                          wordWrap: "break-word",
                                          overflowWrap: "break-word",
                                        }}>
                                        {item.description}
                                      </td>
                                      <td>
                                        {item.catID ? (
                                          item.catID.item
                                        ) : (
                                          <>
                                            <button
                                              onClick={(e) =>
                                                handvoucherCAtItem(
                                                  e,
                                                  item.reference,
                                                  item
                                                )
                                              }
                                              style={{ borderRadius: "5px" }}
                                              className="btn ripple btn-primary btn-sm">
                                              <i class="fa-solid fa-plus"></i>
                                              Add CAC
                                            </button>
                                          </>
                                        )}{" "}
                                      </td>
                                      <td>{item.date.short_date}</td>{" "}
                                      <td>{item.reciever}</td>
                                      <td>{item.created_by.name}</td>
                                    </tr>
                                  ))}

                                {loading && <Loader />}
                                {PaymentVoucher === "404" && (
                                  <>
                                    <tr>
                                      <td colSpan={10}>
                                        <p className="text-center text-info">
                                          No data found in the system
                                        </p>
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                            <nav
                              className="mt-3"
                              style={{ marginLeft: "30px" }}>
                              <ul className="pagination ">
                                <li className="page-item">
                                  <button
                                    className="page-link"
                                    onClick={setPreviousPageNumber}>
                                    Prev
                                  </button>
                                </li>

                                {Array.isArray(meta) &&
                                  meta.map((item) =>
                                    page === item ? (
                                      <li className="page-item active">
                                        <a
                                          className="page-link"
                                          href="javascript:void(0);">
                                          {item}
                                        </a>
                                      </li>
                                    ) : (
                                      <li className="page-item">
                                        <a
                                          className="page-link"
                                          href="#"
                                          onClick={(e) =>
                                            setPageNumber(e, item)
                                          }>
                                          {item}
                                        </a>
                                      </li>
                                    )
                                  )}

                                <li className="page-item">
                                  <button
                                    className="page-link"
                                    onClick={setNextPageNumber}>
                                    Next
                                  </button>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* end row  */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </AppContainer>
    </div>
  );
}

export default JournalsInventory;
