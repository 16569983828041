import { useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast, Toaster } from "react-hot-toast";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";

const CreateCostALllocationCenter = (props) => {
  const [loading, setLoading] = useState(false);
  const [cost_item, setCast_item] = useState("");
  const handleAdd = async (e, controls) => {
    e.preventDefault();
    if (cost_item.trim() === "") {
      toast.error("Please complete all fields.");
    } else {
      setLoading(true);

      var data = {
        cost_item: cost_item,
      };
      const server_response = await ajaxAccounting.create_cost_allocationItem(
        data
      );

      setLoading(false);
      if (server_response.status === "OK") {
        toast.success(server_response.message);
        resetForm();
        controls.close();
        props.function();
      } else {
        toast.error(server_response.message);
      }
    }
  };

  const resetForm = () => {
    setCast_item("");
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            disabled={loading}
            className={`btn ripple btn-success`}
            onClick={(e) => handleAdd(e, controls)}>
            Save item
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Add Cost Allocation center"
      id="model-new-ledger"
      size="md"
      footer={RenderFooter}>
      <Toaster />
      <div className="mb-4 col-md-12">
        <label htmlFor="">
          Cost Allocation item:<span className="tx-danger">*</span>
        </label>
        <input
          type="text"
          value={cost_item}
          onChange={(e) => setCast_item(e.target.value)}
          className="form-control"
          placeholder="item Name"
        />
      </div>
    </SystemModal>
  );
};

export default CreateCostALllocationCenter;
