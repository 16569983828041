import apiCall from "./apiCall";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  async createPayRate(task_id, team_id, role_id, rate) {
    let data = {
      task_id: task_id,
      team_id: team_id,
      role_id: role_id,
      rate: rate,
    };
    let response = await apiCall("task_rate/create", data);
    return response;
  },
  async updatePayRate(task_rate_id, rate) {
    let data = {
      task_rate_id: task_rate_id,
      rate: rate,
    };
    let response = await apiCall("task_rate/update", data);
    return response;
  },
  async fetchPayRates(task_id) {
    let data = {
      task_id: task_id,
    };
    let response = await apiCall("task_rate/list", data);

    return response;
  },

  async createPayment(employee, role, roll_call, output, amount, output_date) {
    let data = {
      employee: employee,
      role: role,
      roll_call: roll_call,
      output: output,
      amount: amount,
      output_date: output_date,
    };
    let response = await apiCall("payment/create", data);

    return response;
  },
  async searchPayments(startDate, endDate) {
    let data = {
      from: startDate,
      to: endDate,
    };
    let response = await apiCall("payment/search", data);

    return response;
  },

  async fetchAllPayments(data) {
    let response = await apiCall("payment/list/all", data);
    return response;
  },
  async fetchTaskPayments(data) {
    let response = await apiCall("payment/list/task", data);
    return response;
  },
  async fetchDeposits(data) {
    let response = await apiCall("payment/list/deposits", data);
    return response;
  },
  async fetchPaymentsDashboard(limit) {
    let data = {
      limit: limit,
    };
    let response = await apiCall("payment/list/all", data);

    return response;
  },
  async fetchPayments(employee) {
    let data = {
      employee: employee,
    };
    let response = await apiCall("payment/list", data);

    return response;
  },
  async createDeposit(data) {
    let response = await apiCall("deposit/create", data);

    return response;
  },
  async createTaskPayment(data) {
    let response = await apiCall("task_payment/create", data);

    return response;
  },
};
