import { useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxProject from "../../util/remote/ajaxProject";

const UpdatePole = (props) => {
  const [wkt_geom, setWkt_geom] = useState(props.wkt_geom);
  const [fid, setFid] = useState(props.fid);
  const [idd, setIdd] = useState(props.idd);
  const [id_town, setId_town] = useState(props.id_town);
  const [id_neighborhood, setId_neighborhood] = useState(props.id_neighborhood);
  const [pole_id, setPole_id] = useState(props.pole_id);
  const [trunk, setTrunk] = useState(props.trunk);
  const [height, setHeight] = useState(props.height);
  const [contractor, setContractor] = useState(props.contractor);

  const [loading, setLoading] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (
      props.batch.length > 0 &&
      props.id * 1 > 0 &&
      wkt_geom.length > 0 &&
      fid.length > 0 &&
      idd.length > 0 &&
      id_town.length > 0 &&
      id_neighborhood.length > 0 &&
      pole_id.length > 0 &&
      trunk.length > 0 &&
      height.length > 0 &&
      contractor.length > 0
    ) {
      setLoading(true);
      const server_response = await ajaxProject.updatePole(
        props.poles_id,
        props.id,
        props.batch,
        wkt_geom,
        fid,
        idd,
        id_town,
        id_neighborhood,
        pole_id,
        trunk,
        height,
        contractor
      );
      setLoading(false);
      if (server_response.status === "OK") {
        toast.success(server_response.message);

        props.f();
      } else {
        toast.error(server_response.message);
      }
    } else {
      toast.error("Complete all fields and try again");
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            className={`btn ripple btn-success`}
            onClick={handleUpdate}>
            Update Pole Details
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Update Pole Details"
      id="model-update-pole"
      size="md"
      footer={RenderFooter}>
      <div className="mb-4">
        <label htmlFor="">Wkt Geom</label>
        <input
          type="text"
          value={wkt_geom}
          placeholder={"wkt_geom"}
          onChange={(e) => setWkt_geom(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Fid</label>
        <input
          type="text"
          value={fid}
          placeholder={"fid"}
          onChange={(e) => setFid(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Id</label>
        <input
          type="text"
          value={idd}
          placeholder={"id"}
          onChange={(e) => setIdd(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Id Town</label>
        <input
          type="text"
          value={id_town}
          placeholder={"id_town"}
          onChange={(e) => setId_town(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Id Neighborhood</label>
        <input
          type="text"
          value={id_neighborhood}
          placeholder={"id_neighborhood"}
          onChange={(e) => setId_neighborhood(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Pole ID</label>
        <input
          type="text"
          value={pole_id}
          placeholder={"pole_id"}
          onChange={(e) => setPole_id(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Trunk</label>
        <input
          type="text"
          value={trunk}
          placeholder={"trunk"}
          onChange={(e) => setTrunk(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Height</label>
        <input
          type="text"
          value={height}
          placeholder={"height"}
          onChange={(e) => setHeight(e.target.value)}
          className="form-control"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="">Contractor</label>
        <input
          type="text"
          value={contractor}
          placeholder={"contractor"}
          onChange={(e) => setContractor(e.target.value)}
          className="form-control"
        />
      </div>
    </SystemModal>
  );
};

export default UpdatePole;
