import apiCall from "./apiCall";

export default {
  async getMainAccounts(data) {
    let response = await apiCall("accounts/get_main_accounts", data);
    return response;
  },
  // ledger types works
  async createLedgerType(data) {
    let response = await apiCall("accounts/createLegderType", data);
    return response;
  },
  async getLedgerTypes(data) {
    let response = await apiCall("accounts/fetchLedger_types", data);
    return response;
  },

  // ledgers functions
  async getLedgers(data) {
    let response = await apiCall("accounts/get_ledgers", data);
    return response;
  },
  async createLedger(data) {
    let response = await apiCall("accounts/create_ledger", data);
    return response;
  },
  async getALlLedgersMinData(data) {
    let response = await apiCall("accounts/list_all_ledger_minimal_data", data);
    return response;
  },
  async getALlLedgersMinData_non_cash_or_bank(data) {
    let response = await apiCall(
      "accounts/list_ledgers_all_mini_data_non_cash_or_bank",
      data
    );
    return response;
  },
  async getALlLedgersMinData_cash_or_bank(data) {
    let response = await apiCall(
      "accounts/list_ledgers_all_mini_data_cash_or_bank",
      data
    );
    return response;
  },
  // cost allocation center
  async create_cost_allocationItem(data) {
    let response = await apiCall("accounts/create_cost_allocation_item", data);
    return response;
  },
  async list_cost_allocationItems(data) {
    let response = await apiCall("accounts/list_cost_allocation_items", data);
    return response;
  },
  async delete_cost_allocationItem(data) {
    let response = await apiCall(
      "accounts/delete_cost_allocation_center",
      data
    );
    return response;
  },
  async UpdateCostAllocationCenter(data) {
    let response = await apiCall(
      "accounts/update_cost_allocation_center",
      data
    );
    return response;
  },
  async getAllcostAllocations_min_data() {
    let response = await apiCall(
      "accounts/list_cost_all_allocation_items_min_data"
    );
    return response;
  },
  // budget expenditures
  async createBugdetExpense(data) {
    let response = await apiCall("accounts/create_budget_expenditure", data);
    return response;
  },
  async UpdateBudgetExpenditure(data) {
    let response = await apiCall("accounts/update_budget_expenditure", data);
    return response;
  },
  async getBudgetExpenses(data) {
    let response = await apiCall("accounts/list_budget_trnsactions", data);
    return response;
  },
  async setBudegetExpenditure_costAllocation(data) {
    let response = await apiCall("accounts/set_this_budget_expense_cat", data);
    return response;
  },
  // PaymentVoucher
  async CreatePaymentVoucher(data) {
    let response = await apiCall("accounts/create_Transctions", data);
    return response;
  },
  async getPaymentVoucher(data) {
    let response = await apiCall("accounts/list_Transctions", data);
    return response;
  },
  async setPaymentVoucher_costAllocation(data) {
    let response = await apiCall("accounts/set_payment_vouchers_cat", data);
    return response;
  },
  async Update_payment_voucher(data) {
    let response = await apiCall("accounts/update_payment_voucher", data);
    return response;
  },
  async getFolioVouchersList(data) {
    let response = await apiCall(
      "accounts/get_folio_vouchers_for_printing",
      data
    );
    return response;
  },
  async getprojectBudgectExpense(data) {
    let response = await apiCall(
      "accounts/get_budget_expenditure_report",
      data
    );
    return response;
  },
  async getLedgerTrialStatement(data) {
    let response = await apiCall("accounts/get_ledger_account_trial", data);
    return response;
  },
  async getProjectTrialBalanceReport(data) {
    let response = await apiCall("accounts/get_project_trial_balance", data);
    return response;
  },
  // invoices works
  async createInvoicables(data) {
    let response = await apiCall("accounts/create_invoicables", data);
    return response;
  },
  async getInvoicables(data) {
    let response = await apiCall("accounts/listInvoicables", data);
    return response;
  },
  async deactivateInvoicable(data) {
    let response = await apiCall("accounts/deactivate_invoicable_item", data);
    return response;
  },
  async updateInvoicableItem(data) {
    let response = await apiCall("accounts/update_invoicable_item", data);
    return response;
  },
  async getAllSalesInvoices() {
    let response = await apiCall("accounts/get_sales_ledgers");
    return response;
  },
  async getClientLedgers() {
    let response = await apiCall("accounts/list_clients_ledgers");
    return response;
  },
  async createInvoice(data) {
    let response = await apiCall("accounts/create_invoice_and_details", data);
    return response;
  },
  async fetchInvoices(data) {
    let response = await apiCall("accounts/get_invoices", data);
    return response;
  },
  async getInvoice_and_details(data) {
    let response = await apiCall("accounts/get_invoice_details", data);
    return response;
  },
  async createJournalInventory(data) {
    let response = await apiCall("accounts/create_journal_invetory", data);
    return response;
  },
  async createInventoryInvoice(data) {
    let response = await apiCall("accounts/create_invoice_invetory", data);
    return response;
  },
  async get_Inventory_invoice(data) {
    let response = await apiCall("accounts/get_iventory_invoice_details", data);
    return response;
  },
  async getInvoiceReferences(data) {
    let response = await apiCall(
      "accounts/get_invoice_reference_with_balance",
      data
    );
    return response;
  },
  async createDepositTransaction(data) {
    let response = await apiCall("accounts/create_deposite_transaction", data);
    return response;
  },
  async createcreditTransaction(data) {
    let response = await apiCall("accounts/create_credit_transaction", data);
    return response;
  },
};
