import { useEffect, useState } from "react";
import SystemModal from "../Common/SystemModal";
import Loader from "../Common/Loader";
import { toast } from "react-hot-toast";
import ajaxAccounting from "../../util/remote/ajaxAccounting";
import Select from "react-select";

const TransactionUpdater = (props) => {
  var data = props.data;
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(data.amount.amount);
  const [reciever, setReciever] = useState(data.reciever);
  const [date, setDate] = useState(data.date.db);
  const [description, setDescription] = useState(data.description);
  const [employees, setEmployees] = useState(props.employees);

  const handleAdd = async (e, controls) => {
    e.preventDefault();
    if (reciever === "" || amount === "" || date === "" || description === "") {
      toast.error("Please complete the form.");
    } else {
      setLoading(true);

      var data = {
        reference: props.data.reference,
        amount: amount,
        reciever: reciever,
        date: date,
        description: description,
      };
      const server_response = await ajaxAccounting.Update_payment_voucher(data);

      setLoading(false);
      if (server_response.status === "OK") {
        controls.close();
        toast.success(server_response.message);
        props.function(); // Call the passed function
      } else {
        toast.error(server_response.message);
      }
    }
  };

  const RenderFooter = (controls) => {
    if (loading) {
      return <Loader />;
    } else {
      return (
        <>
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={controls.close}>
            Close
          </button>
          <button
            type="button"
            disabled={loading}
            className={`btn ripple btn-success`}
            onClick={(e) => handleAdd(e, controls)}>
            Save changes
          </button>
        </>
      );
    }
  };

  return (
    <SystemModal
      title="Update Transaction "
      id="model-new-ledger"
      size="lg"
      footer={RenderFooter}>
      <div className="row">
        <div className="mb-4 col-md-12">
          <ul
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              borderBottom: "dotted 1px grey",
              backgroundColor: "transparent",
            }}>
            <li>
              <span>
                Account_debited: <u>{data.ledgerID_debited.Ledger_name}</u>
              </span>
            </li>
            <li>
              <span>
                Account_credited: <u>{data.ledgerID_credited.Ledger_name}</u>
              </span>
            </li>
          </ul>
        </div>

        <div className="mb-4 col-md-6">
          <label htmlFor="">
            Amount
            <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="mb-4 col-md-6">
          <label htmlFor="">
            Reciever
            <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            value={reciever}
            onChange={(e) => setReciever(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="mb-4 col-md-12">
          <label htmlFor="">
            Date
            <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="form-control"
          />
        </div>

        <div className="mb-4 col-md-12">
          <label htmlFor="">
            Description
            <span style={{ color: "red" }}>*</span>
          </label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="form-control"></textarea>
        </div>
      </div>
    </SystemModal>
  );
};

export default TransactionUpdater;
